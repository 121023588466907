import { InjectionToken, Provider } from '@angular/core';

export const SCHOOL_APP_PATH_TOKEN = new InjectionToken<string>('School App Path');

export function provideSchoolAppPath(schoolAppPath: string): Provider {
    return {
        provide: SCHOOL_APP_PATH_TOKEN,
        useValue: schoolAppPath,
    };
}
