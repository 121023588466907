<lib-branded-navigation #navigation>
    <!-- Without tabindex="" this requires two tabs to actually focus the correct element -->
    <ion-button
        desktopNavigationLink
        routerDirection="root"
        routerLink="/school-customers"
        routerLinkActive="selected"
        shape="round"
        tabindex=""
    >
        {{ 'shared.schools.schools' | translate }}
    </ion-button>
    <ion-button
        desktopNavigationLink
        routerDirection="root"
        routerLink="/school-users"
        routerLinkActive="selected"
        shape="round"
        tabindex=""
    >
        {{ 'internal.navigation.schoolUsers' | translate }}
    </ion-button>
    <ion-button
        desktopNavigationLink
        routerDirection="root"
        routerLink="/resources"
        routerLinkActive="selected"
        shape="round"
        tabindex=""
    >
        {{ 'shared.resources.resources' | translate }}
    </ion-button>
    <ion-button
        desktopNavigationLink
        routerDirection="root"
        routerLink="/skills"
        routerLinkActive="selected"
        shape="round"
        tabindex=""
    >
        {{ 'shared.navigation.mobile.skills' | translate }}
    </ion-button>
    <!-- Todo: Sequences -->

    <ion-tab-button mobileTabButton tab="school-customers">
        <ion-label>
            {{ 'shared.navigation.mobile.schools' | translate }}
        </ion-label>
        <ion-icon name="school-outline" />
    </ion-tab-button>
    <ion-tab-button mobileTabButton tab="school-users">
        <ion-label>
            {{ 'shared.navigation.mobile.users' | translate }}
        </ion-label>
        <ion-icon name="people-outline" />
    </ion-tab-button>
    <ion-tab-button mobileTabButton tab="dashboard">
        <picture>
            <source
                media="(prefers-color-scheme:dark)"
                srcset="/assets/logos/logo-icon-only-black.svg"
            />
            <img alt="Logo" class="logo-icon" src="/assets/logos/logo-icon-only-black.svg" />
        </picture>
    </ion-tab-button>
    <ion-tab-button mobileTabButton tab="resources">
        <ion-label>
            {{ 'shared.navigation.mobile.resources' | translate }}
        </ion-label>
        <ion-icon name="book-outline" />
    </ion-tab-button>
    <ion-tab-button mobileTabButton tab="skills">
        <ion-label>
            {{ 'shared.navigation.mobile.skills' | translate }}
        </ion-label>
        <ion-icon name="clipboard-outline" />
    </ion-tab-button>

    <ion-accordion-group sideMenuContent value="programs" class="accordion-icon-quarter-turn">
        <ion-item
            (click)="navigation.closeWhenSmall()"
            routerLink="/dashboard"
            routerLinkActive="selected"
            detail="false"
        >
            <ion-label> Dashboard </ion-label>
        </ion-item>
        <ion-accordion value="tools-settings" toggleIcon="chevron-forward">
            <ion-item slot="header">
                <ion-label> Tools / Settings </ion-label>
            </ion-item>
            <ion-list (click)="navigation.closeWhenSmall()" slot="content" lines="none">
                <ion-item routerLink="/users/staff" routerLinkActive="selected" detail="false">
                    <ion-label> {{ 'internal.navigation.staff' | translate }} </ion-label>
                </ion-item>
                <ion-item routerLink="/media-library" routerLinkActive="selected" detail="false">
                    <ion-label>
                        {{ 'internal.navigation.mediaLibrary' | translate }}
                    </ion-label>
                </ion-item>
                <ion-item routerLink="/assessments" routerLinkActive="selected" detail="false">
                    <ion-label>
                        {{ 'internal.navigation.assessments' | translate }}
                    </ion-label>
                </ion-item>
                <ion-item routerLink="/dashboard-editor" routerLinkActive="selected" detail="false">
                    <ion-label>
                        {{ 'internal.navigation.dashboardEditor' | translate }}
                    </ion-label>
                </ion-item>
                <ion-item routerLink="/schools/import" routerLinkActive="selected" detail="false">
                    <ion-label>
                        {{ 'internal.navigation.importSchools' | translate }}
                    </ion-label>
                </ion-item>
                <ion-item
                    routerLink="/settings/developer-tools"
                    routerLinkActive="selected"
                    detail="false"
                >
                    <ion-label>
                        {{ 'internal.navigation.developerTools' | translate }}
                    </ion-label>
                </ion-item>
            </ion-list>
        </ion-accordion>
        <ion-accordion value="programs" toggleIcon="chevron-forward">
            <ion-item slot="header">
                <ion-label> {{ 'shared.navigation.info.programs' | translate }} </ion-label>
            </ion-item>
            <ion-list (click)="navigation.closeWhenSmall()" slot="content" lines="none">
                <ion-item detail="false" routerLink="reading-program" routerLinkActive="selected">
                    <ion-label>
                        {{ 'school.navigation.readingProgram.readingProgram' | translate }}
                    </ion-label>
                </ion-item>
                <ion-item detail="false" routerLink="writing-program" routerLinkActive="selected">
                    <ion-label>
                        {{ 'school.navigation.writingProgram.writingProgram' | translate }}
                    </ion-label>
                </ion-item>
                <ion-item detail="false" routerLink="grammar-program" routerLinkActive="selected">
                    <ion-label>
                        {{ 'school.navigation.grammarProgram.grammarProgram' | translate }}
                    </ion-label>
                </ion-item>
                <ion-item
                    detail="false"
                    routerLink="vocabulary-program"
                    routerLinkActive="selected"
                >
                    <ion-label>
                        {{ 'school.navigation.vocabularyProgram.vocabularyProgram' | translate }}
                    </ion-label>
                </ion-item>
            </ion-list>
        </ion-accordion>
        <ion-accordion value="information" toggleIcon="chevron-forward">
            <ion-item slot="header">
                <ion-label>
                    {{ 'shared.navigation.info.information' | translate }}
                </ion-label>
            </ion-item>
            <ion-list (click)="navigation.closeWhenSmall()" slot="content" lines="none">
                <ion-item detail="false" routerLink="#" routerLinkActive="selected">
                    <ion-label> Terms of Use </ion-label>
                </ion-item>
                <ion-item detail="false" routerLink="#" routerLinkActive="selected">
                    <ion-label>
                        {{ 'shared.navigation.info.privacyPolicy' | translate }}
                    </ion-label>
                </ion-item>
            </ion-list>
        </ion-accordion>
    </ion-accordion-group>

    @if (accountAvatar$ | async; as avatar) {
        <!-- If ion-button is on the outside,
             then when the profile icon changes it doesn't update properly.
             keep this on the inside of the @if -->
        <ion-button accountMenuToggle>
            <ion-avatar>
                <img [src]="avatar.source" [alt]="avatar.alternateText" />
            </ion-avatar>
        </ion-button>
    } @else {
        <ion-button accountMenuToggle>
            <ion-avatar slot="icon-only">
                <ion-icon name="person-circle-outline" />
            </ion-avatar>
        </ion-button>
    }

    <ion-avatar accountMenuToolbarContent slot="start">
        @if (accountAvatar$ | async; as avatar) {
            <img [src]="avatar.source" [alt]="avatar.alternateText" />
        } @else {
            <ion-icon name="person-circle-outline" />
        }
    </ion-avatar>
    <ion-title accountMenuToolbarContent> {{ accountTitle$ | async }} </ion-title>

    <ion-menu-toggle accountMenuContent menu="account-menu">
        <ion-list class="ion-no-padding" lines="none">
            <ion-item detail="false" routerLink="/account/profile" routerLinkActive="selected">
                <ion-label>
                    {{ 'shared.navigation.account.profile' | translate }}
                </ion-label>
                <ion-icon slot="end" name="person-circle-outline" />
            </ion-item>
            @if (isLoggedIn$ | async) {
                <ion-item (click)="logOut()" button="" detail="false" lines="full">
                    <ion-label> {{ 'shared.navigation.account.logout' | translate }}</ion-label>
                    <ion-icon slot="end" name="log-out-outline" />
                </ion-item>
            } @else {
                <ion-item
                    button=""
                    detail="false"
                    lines="full"
                    routerLink="account/login"
                    routerLinkActive="selected"
                >
                    <ion-label> {{ 'shared.navigation.account.login' | translate }}</ion-label>
                    <ion-icon slot="end" src="assets/icons/login.svg" />
                </ion-item>
            }
        </ion-list>
    </ion-menu-toggle>
</lib-branded-navigation>
