import { DownloadsConfig } from '../../interfaces/programs.interfaces';

export const EXPOSITORY_6TH_GRADE_DOWNLOAD_CONFIG: DownloadsConfig = {
    resourceLink: [
        {
            text: 'Problem-Solution 6th Grade',
            url: '/resources/37a9fde7-ce90-4972-925f-ec69d5e3b7a4',
        },
        {
            text: 'Compare + Contrast 6th Grade',
            url: '/resources/e142c31f-998c-4e0c-97e6-e5175fb3a584',
        },
        {
            text: 'Cause + Effect 6th Grade',
            url: '/resources/33b8a706-8448-433d-aa09-8ff14e0f5010',
        },
        {
            text: 'Descriptive Writing 6th Grade',
            url: '/resources/caee5cd7-cefb-477a-be42-774dfce7f7d0',
        },
    ],
    headingConfig: {
        letter: 'E',
        level: 'Level E',
        grade: 'Grade 6',
        color: 'var(--program-grade-level-e)',
    },
    slideDeckHeading: 'Expository Slide Decks',
    slideDeckUrl: [
        {
            text: 'Cause_and_Effect_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level E/Cause_and_Effect_Slide_Decks.zip',
        },
        {
            text: 'Compare_and_Contrast_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level E/Compare_and_Contrast_Slide_Decks.zip',
        },
        {
            text: 'Descriptive_Writing_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level E/Descriptive_Writing_Slide_Decks.zip',
        },
        {
            text: 'Problem-Solution_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level E/Problem-Solution_Slide_Decks.zip',
        },
    ],
    digitFilesConfig: [
        {
            value: 'causeAndEffect',
            heading: 'Cause + Effect',
            content: [
                {
                    text: 'Cause + Effect Graphic Organizer 1',
                    url: 'https://docs.google.com/presentation/d/1H3AKbuaUpn1G9Z7t7xyxC39pWc7FavyXtiS0reSx4JY/copy',
                },
                {
                    text: 'Cause + Effect Graphic Organizer 2',
                    url: 'https://docs.google.com/presentation/d/1ARRoBdKyQF9cJD3C2riCCdac2vK-LCCY6fH9aq_Uf1Y/copy',
                },
                {
                    text: 'Investigation Cards',
                    url: 'https://docs.google.com/presentation/d/1d-CpX0QH_aewSGcuRn5BW7Ug6ejgA-O2Wc8YzsVkQDQ/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1Drzj25fJ1LwGa4l6iBMsxMP8R-1Np6Dih3I8Aac-ycI/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1e1cHCOzJ8BTKFvsE9VIcQFGNqMvGZ3hA91IBSwmimnU/copy',
                },
                {
                    text: 'Practice Writing a Summary',
                    url: 'https://docs.google.com/presentation/d/1dEx3Iu2ojcNGsPfJCrMV_ntV8Fov9Rhyh7-2cHQgBJ0/copy',
                },
                {
                    text: 'Claim Sorting Activity',
                    url: 'https://docs.google.com/presentation/d/1Ui3V5gPTvfdSbtUfkYns65fspmC2VYRxuHfwZP5_fbk/copy',
                },
                {
                    text: 'Identifying Parts of an Intro Paragraph',
                    url: 'https://docs.google.com/presentation/d/1N2QIflJ-F9ItjOHU2VFMwtQHjAa0J6FtayFJ9C7iIgo/copy',
                },
                {
                    text: 'Premises in Writing',
                    url: 'https://docs.google.com/presentation/d/1zKHRFffRWoMq3B84N8f3mMlzJZG3zBb5d8Zei3oMbdI/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1B-Jt0w_q5nuQBb7J3odSBqbeYB46F38wkRGrv_IebeY/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/13iy4dQhlTnk8hujLmbb2kAQHNhLL2wOn-M45r-6IGbg/copy',
                },
                {
                    text: 'Justification Practice',
                    url: 'https://docs.google.com/presentation/d/1sjQvaRGPamyqs4y81gb-9kcLB2jCAMp_efRxwOU7RWU/copy',
                },
                {
                    text: 'Practice with the Intro and Body Paragraphs',
                    url: 'https://docs.google.com/presentation/d/1qYOvBrfHTmocntt3CncUGG0hMtdkwfxPpBBxPO5lzrg/copy',
                },
                {
                    text: 'Restating the Claim',
                    url: 'https://docs.google.com/presentation/d/1G6CP0CfO9E87kmwcMxyISd6RNdBvOFgDqeVTMZgn5j0/copy',
                },
                {
                    text: 'Summarizing Evidence',
                    url: 'https://docs.google.com/presentation/d/1UuVQXJ2Md7cvso4_iWspFZ2QWjmhWRn5I62q5QPZTv0/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1wCBeL3kwtpYwU1dmpwA17p-p6VkvBR1zhLHhuzmqac4/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1aT3cRN-mTQ6DnjzXvDlLRorod4W_VIerU1VBy2b6lj8/copy',
                },
                {
                    text: 'Cause and Effect Rubric',
                    url: 'https://docs.google.com/presentation/d/1n-BBt1GhT6Z4k14TiGgaxzNUw4m5i0Ppy7w1zQSlzJk/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1nVMCogdr1XOzZ_F7wR6wQLz0pfVkEA7PNp-3PieVE5U/copy',
                },
            ],
        },
        {
            value: 'compareAndContrast',
            heading: 'Compare + Contrast',
            content: [
                {
                    text: 'Compare + Contrast Graphic Organizer TWO TOPICS',
                    url: 'https://docs.google.com/presentation/d/1qzeYQNjwPdqsPuWoKoysdb5EwheA8uakIHJ0UNCXGa8/copy',
                },
                {
                    text: 'Compare + Contrast Graphic Organizer TWO TEXTS',
                    url: 'https://docs.google.com/presentation/d/1afnQHSGJ4PZMXwqSSqlP7VdV-k2PRzGEPYN7BkdFonY/copy',
                },
                {
                    text: 'Game Cards',
                    url: 'https://docs.google.com/presentation/d/1GrsnpiM1y7Ht2S0UN-_9Im55v-75ALP0ItkdRD2yZno/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1m_wM_EB8SWYx5GPaUE44kWEKM-_ga3LmfxKwGAsCNs8/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1iUtvmjYnypUlzt09Rvuxv3u9LNu8URvuAUFfRDHPOoc/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1FRZBg2JPj5gZZbOOOJ1_FRJ_JCbY8h8qz1FgTEW1IrM/copy',
                },
                {
                    text: 'Matching Prompts + Claims',
                    url: 'https://docs.google.com/presentation/d/1PW_HKmwefgUM1UhcU9qR_Bh-3vTFJb97PeoR3Vf5te0/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1YyMoULLamRWK3iHCoWmCqU8kFhRCjUH-cEMa-wcYSno/copy',
                },
                {
                    text: 'Intro and Body Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1iVUNdcN5SIOMgk5B3REQK4LEKf7s504W_98MraT2TnQ/copy',
                },
                {
                    text: 'Premises in Writing Activity',
                    url: 'https://docs.google.com/presentation/d/1GttGgqtCyrBMWpDF0s7NzjS9DPLc2-vRsmAxq3IY3yI/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1mFjQwIAtbZz7FR2GY4QffZgjJ_lWy5w2BNs5RYmi9x4/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1puGp9M2VXHiqkNxN6ActYFOIA9n2LW64FFeHpX39nfw/copy',
                },
                {
                    text: 'Justification Activity',
                    url: 'https://docs.google.com/presentation/d/1HAk0SuBOcuQPEf_QRWSuO6_rHsu_OQPntCAcATs-kDw/copy',
                },
                {
                    text: 'Restating Claim',
                    url: 'https://docs.google.com/presentation/d/1iChbMAPy8VGCtR9d9sloRfPTCHFDbaYzJv-6OnIlHKI/copy',
                },
                {
                    text: 'Summarizing Evidence',
                    url: 'https://docs.google.com/presentation/d/18WhcJeJPOkKbwldSBO095nMn9m4febWkWHjq_5iHCOs/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1S7ig_D0jM-o7WbjHNHzEsEbHkU98L2b6djerhkUvwTc/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1VvxphuihCQyddAsCXx--U6JIrGzqYI2qTsG33Y-vhME/copy',
                },
                {
                    text: 'Compare and Contrast Rubric',
                    url: 'https://docs.google.com/presentation/d/1nSWPWUZMRrCaBVBEvuHNb_Uu5kvAMWls56WyUYAcJSI/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1Wd0MXpPLekEWSX3qHeBd7Hg644kl5PozucG47RIm0z0/copy',
                },
            ],
        },
        {
            value: 'descriptiveWriting',
            heading: 'Descriptive Writing',
            content: [
                {
                    text: 'Descriptive Writing Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/17iz45TbdYAZFuvL644Zt0LGFbGfT6xBsMeEg9p7CfNc/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1nDK-g9_4pPrnmeoVXXzG5Pd_9XTs1IA96-0PabNcgps/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/16Ltd6KEUbn3mSuj6VPXWmb0l6X_SdQ_g-McSwKGs-K4/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1yKTjKogtKHFXxVF_tEmS94dj_gVtGnR2X1jne_LuTps/copy',
                },
                {
                    text: 'Practicing Thesis Statements',
                    url: 'https://docs.google.com/presentation/d/1RP8rrwlnwVr78ZhwPZg8s9PBoEzy0182CGWgxp7yXcM/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1zOui2dhpZrFnEYa089aotw9ij9YmgvvCs46_U1jWv_4/copy',
                },
                {
                    text: 'Understanding the Body Paragraph',
                    url: 'https://docs.google.com/presentation/d/1BuiGMWiTYWA7XGRJroxuJ_JZKLW1Wz8xlD8SpWziMy8/copy',
                },
                {
                    text: 'Practicing the Topic Sentences',
                    url: 'https://docs.google.com/presentation/d/1DNtKOx9mysMMe1X0G8zqWjHP3zkFr4Z-WSUv9ozhE5E/copy',
                },
                {
                    text: 'Concluding Statement Practice',
                    url: 'https://docs.google.com/presentation/d/1BeKHyYhbsgomyacSVuYUSRyUIqaxnupbjC01MDUEZQU/copy',
                },
                {
                    text: 'Writing a Body Paragraph',
                    url: 'https://docs.google.com/presentation/d/1LMdJfP824Dz0N8JIwQ5cWZrVZVRe2zML5zTcvtU9grs/copy',
                },
                {
                    text: 'Restating the Thesis Statement',
                    url: 'https://docs.google.com/presentation/d/1PI6PcFSDoP1Si0pQrV0zFZQ7X7_YTj9MILAT7micVqw/copy',
                },
                {
                    text: 'Summarizing the Topic Sentence',
                    url: 'https://docs.google.com/presentation/d/1tVAyr9xWj1_VmB8jOkgxukvBiy-fotBTndzIp-EhqEk/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1W6cBUkWH_ax0lVIbOWO9MSDh0MaIB6QbGzb5btX7oaw/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1DVP3C10N9zXRpsTBQpLcO-8OhV4I8UIbNvhyRz3r6xU/copy',
                },
                {
                    text: 'Descriptive Writing Rubric',
                    url: 'https://docs.google.com/presentation/d/1Jpe35MyS-QMMxd3n6T3aeJ9SV51oKPLdhJNp5fBP0YQ/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1ru3xCWGtFv9i11Fv6FvctaGhng6EC5O0qDAtxy-X_Xk/copy',
                },
            ],
        },
        {
            value: 'problemSolution',
            heading: 'Problem-Solution',
            content: [
                {
                    text: 'Problem-Solution Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1rDwKJJABJ6aZIGENUUA5G2k5bVENKr6Dkgkq6uAFqoU/copy',
                },
                {
                    text: 'Sorting Cards',
                    url: 'https://docs.google.com/presentation/d/1dkfYNt-mSiZNiRVGM4VgUgrxkgqogHnlMkQXOHhD7no/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1jDFTg3EpnLrWTqdrN-oziVbsNa--D1RIsbLGnVaClRA/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1kx6Rwn6u4NJsaV--yW8ud6AyFLx6qt9U-2WU5QK1de0/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1HPMG8OPyScUHUQOU-GFwMDOqFqXHc7mpE8vIWrGk-n8/copy',
                },
                {
                    text: 'Investigation Cards',
                    url: 'https://docs.google.com/presentation/d/1XOysGaffAK3G_gay4zcsRvFN4bAeOcXUGF6kOSK8ODw/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1brT7Et7TtDRYQEOSpFmpDfsdWMPRIgotVD_xavu4i88/copy',
                },
                {
                    text: 'Premises Writing Activity',
                    url: 'https://docs.google.com/presentation/d/1oieQAD9VyRN4OYfb7OlG6WoJ7Rgf4rY764BwupL1l6k/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/13l3n14eCtqeWjV3Vhn_pWxmhWzw7yMZL72CJ-wd4Wnw/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1_YA21DXrz_KkQ6t113ksIiz3cSvMZls2RGbtUmA2jWo/copy',
                },
                {
                    text: 'Justification Activity',
                    url: 'https://docs.google.com/presentation/d/1B_GCiMBCNRxNYdgvtbcdPXRDdHlqIoi4mD3pECzXDDw/copy',
                },
                {
                    text: 'Intro and Body Paragraph Practice',
                    url: 'https://docs.google.com/presentation/d/1Fr-2xrx-CA2SEmSPhtXp5pdlzFOtW37qZtyJFXqKxXA/copy',
                },
                {
                    text: 'Restating the Thesis ',
                    url: 'https://docs.google.com/presentation/d/11WxXdB8j8jauwxWZUvajhwCp3aMi_H94xB5HibbdMig/copy',
                },
                {
                    text: 'Summarizing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1tsU0_s6-nw3GOIagsl73Z7MbdU_K-nd4hpu9Lyy6GIw/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1OQi29KJKt7-yhemOiGu6hkV7z0TIWK2rXmYXQus0GAQ/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1gGim8czfM4ouWIKvClpB96iYrk7T6TEwdXTpSarN8UM/copy',
                },
                {
                    text: 'Problem-Solution Rubric',
                    url: 'https://docs.google.com/presentation/d/1CY8uJM1JaD-RKsSLYr-6afD91DQCW89ZVi6BR8hQ13M/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1FNu9bKO8AvPN_dFD2xlJwfKV8m1KhBTLofrM_ttOYAI/copy',
                },
            ],
        },
    ],
    certificateUrl:
        '/resource-assets/program-content/EB Writing Program/Level E/Certificate_of_Completion_Expository_Writing.pdf',
};
