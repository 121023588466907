import { DownloadsConfig } from '../../interfaces/programs.interfaces';

export const EBW_6TH_GRADE_DOWNLOAD_CONFIG: DownloadsConfig = {
    resourceLink: '/resources/a1a41f11-7528-4a8a-b0e1-7c2d4368c40b',
    headingConfig: {
        letter: 'E',
        level: 'Level E',
        grade: 'Grade 6',
        color: 'var(--program-grade-level-e)',
    },
    slideDeckHeading: 'EBW Approach Slide Decks',
    slideDeckUrl:
        '/resource-assets/program-content/EB Writing Program/Level E/Level_E_Slide_Decks.zip',
    digitFilesConfig: [
        {
            value: 'overview',
            heading: 'The EBW Approach',
            content: [
                {
                    text: 'Essential Questions Generator',
                    url: 'https://docs.google.com/presentation/d/1n6Thya_mu_xDk2J-wYzK3fpKAUiX1V3XOQtqrnfqNOQ/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/19YhaVy8ExkjTPlJreKWZyCp4pKEdnWKlm8THBNELvHY/copy',
                },
                {
                    text: 'Pre-Assessment',
                    url: 'https://docs.google.com/presentation/d/1s6DHkE9E32F8o7a7w87AlKPKTNHO3prKDEh2SE1b92I/copy',
                },
                {
                    text: 'Post-Assessment',
                    url: 'https://docs.google.com/presentation/d/11X_xOLNAvd5X8zmyVV5YLccEbL-pnMzdd4WZm3eiHhg/copy',
                },
                {
                    text: 'Assessment Trackers',
                    url: 'https://docs.google.com/presentation/d/13aB1Ds3GYkOppQ74dYzlHh6gdFew0iFIBprM4VW8Aik/copy',
                },
            ],
        },
        {
            value: 'introductoryParagraph',
            heading: 'The Introductory Paragraph',
            content: [
                {
                    text: 'Introducing EBW Handout',
                    url: 'https://docs.google.com/presentation/d/10JvRUetDf6ShRhD8QkRyA7gmrswqz68Q1AdM221IK1M/copy',
                },
                {
                    text: 'TAG Practice',
                    url: 'https://docs.google.com/presentation/d/1QqPee5e4LBiT0OL666WmEsLxS3ZhuappcW6EGg_s7Vk/copy',
                },
                {
                    text: 'Practice Writing a Summary',
                    url: 'https://docs.google.com/presentation/d/1wcL0coSOhEoX7Hlgo57PojDjbmltGzHxHWBFS1BsnaQ/copy',
                },
                {
                    text: 'Claim vs Statement Practice',
                    url: 'https://docs.google.com/presentation/d/16yan8GBaqxL6HZiuxm9VgoTpBg03siV8bw0ueSN-hz8/copy',
                },
                {
                    text: 'Claim vs Statement Sort',
                    url: 'https://docs.google.com/presentation/d/15-JID410hxT6V-QYYRcI9_t8Ed_xFRZl6HABYiTuaCU/copy',
                },
                {
                    text: 'Introductory Paragraph Practice',
                    url: 'https://docs.google.com/presentation/d/1dTFpQt56nuPw_0U1Q1Cg5vwERpGG8qUbuudNMVfOXkM/copy',
                },
            ],
        },
        {
            value: 'bodyParagraphs',
            heading: 'The Body Paragraphs',
            content: [
                {
                    text: 'Premise Practice',
                    url: 'https://docs.google.com/presentation/d/14yL0JXIAwt8u6QRT9QVKWIPnYP3Z2zm0GKFRPZauwxM/copy',
                },
                {
                    text: 'Introducing the Evidence Practice',
                    url: 'https://docs.google.com/presentation/d/12I8Jl1DGCy_9KpIhVs3pvuyiKeb1We3hyvrhDMLGjuM/copy',
                },
                {
                    text: 'Justification Practice',
                    url: 'https://docs.google.com/presentation/d/19wkGhuT2yFH-MtfUwux8G2YmomIYBtxgHt11kJnD1YQ/copy',
                },
                {
                    text: 'Body Paragraph Practice',
                    url: 'https://docs.google.com/presentation/d/1qtaTHGwj0k_TWsUwE-Tgej3b4sQj5RDCvQWX__uutSY/copy',
                },
            ],
        },
        {
            value: 'conclusionParagraph',
            heading: 'The Conclusion Paragraph',
            content: [
                {
                    text: 'Restating the Claim Practice',
                    url: 'https://docs.google.com/presentation/d/1L_0bb5Ds_v2ho96tlx3Bbdq8Obq_nljqZ8kiFXEjejE/copy',
                },
                {
                    text: 'Summarizing Evidence Practice',
                    url: 'https://docs.google.com/presentation/d/1x2MaL3H441j48YU-qV7m4LjiZzqAMr2nU_dOb4-Ibps/copy',
                },
                {
                    text: 'Conclusion Paragraph Practice',
                    url: 'https://docs.google.com/presentation/d/1gBsIjzjgCMmtgX0UfuBB96dcF7-6If14kYXDL00baO4/copy',
                },
                {
                    text: 'Additional RTL Practice',
                    url: 'https://docs.google.com/presentation/d/1rPglCR9utAxE1m9HYh34X1JKVtiN6u5l33Ud885taQ0/copy',
                },
            ],
        },
        {
            value: 'miniLessons',
            heading: 'Mini Lessons',
            content: [
                {
                    text: 'Transitions Practice',
                    url: 'https://docs.google.com/presentation/d/1yfqeFv8Mfu5nqiNTql2Vvc97qcQJIYpP_vtu7eVOS-U/copy',
                },
                {
                    text: 'Student Thesaurus',
                    url: 'https://docs.google.com/presentation/d/16ciIHV4ylphoE1FykCHVDqjYvn1e4sIAKS3k9Q3Jdj8/copy',
                },
                {
                    text: 'MLA Formatting Handout',
                    url: 'https://docs.google.com/presentation/d/1RWLox-9h8jXaGC9klW1tK-HPV2lvhT561Ko7mYRwoXU/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1uun_sYXRolLURJuNZU4IMP5BiBdDEluD-kFOfEtKtMM/copy',
                },
                {
                    text: 'Socratic Seminar Checklist + Rubric',
                    url: 'https://docs.google.com/presentation/d/1OQ_Ghmb_k8DA6BBtl2HrmIdSdPeRwLlN_zov7904ies/copy',
                },
            ],
        },
        {
            value: 'bonusContent',
            heading: 'Bonus Content',
            content: [
                {
                    text: 'Student Reflection',
                    url: 'https://docs.google.com/presentation/d/10pHsOpaGAJmcX3o9VUM3CZKerMempcQyb8UiwcZKW6w/copy',
                },
                {
                    text: 'Response to Literature Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1hAAcBUlnMoWVjSu7LzyJyrPzd4KJBToDKVD7HfmZjeA/copy',
                },
                {
                    text: 'Response to Literature Rubric',
                    url: 'https://docs.google.com/presentation/d/1lfd2GKz9xJCJ8c5SnoBkfYmaWMzaOoljl--pIRILaQw/copy',
                },
                {
                    text: 'Response to Informational Text Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1gJx-wl06ZVYieuvn0A94_7mzr2xlxvWu30vrgTjodtM/copy',
                },
                {
                    text: 'Compare & Contrast Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1hwBUPm_ynY-ErRyP1j5-IXMUdIqHTdQGiH_R5M71Kqg/copy',
                },
                {
                    text: 'Multiple Texts Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1G0wG6Sts4CUq6y0lCY6BGept-uDKij7QsC7Tp6CAfv8/copy',
                },
            ],
        },
    ],
    certificateUrl:
        '/resource-assets/program-content/EB Writing Program/Level E/EBW_Certificate_of_Completion.pdf',
};
