import { DownloadsConfig } from '../../interfaces/programs.interfaces';

export const EXPOSITORY_7TH_GRADE_DOWNLOAD_CONFIG: DownloadsConfig = {
    resourceLink: [
        {
            text: 'Problem-Solution 7th Grade',
            url: '/resources/9ec40f58-c73a-419b-9b1a-a6d7d33a8990',
        },
        {
            text: 'Compare + Contrast 7th Grade',
            url: '/resources/a44e5a1a-be1d-4142-a7e3-a19c09cfb8e1',
        },
        {
            text: 'Cause + Effect 7th Grade',
            url: '/resources/ef48e4f2-393e-400d-8b02-818b03b13a79',
        },
        {
            text: 'Descriptive Writing 7th Grade',
            url: '/resources/4b9e1796-ebc4-4f95-877a-cd51134059cd',
        },
    ],
    headingConfig: {
        letter: 'F',
        level: 'Level F',
        grade: 'Grade 7',
        color: 'var(--program-grade-level-f)',
    },
    slideDeckHeading: 'Expository Slide Decks',
    slideDeckUrl: [
        {
            text: 'Cause_and_Effect_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level F/Cause_and_Effect_Slide_Decks (1).zip',
        },
        {
            text: 'Compare_and_Contrast_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level F/Compare_and_Contrast_Slide_Decks (1).zip',
        },
        {
            text: 'Descriptive_Writing_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level F/Descriptive_Writing_Slide_Decks (1).zip',
        },
        {
            text: 'Problem-Solution_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level F/Problem-Solution_Slide_Decks (1).zip',
        },
    ],
    digitFilesConfig: [
        {
            value: 'causeAndEffect',
            heading: 'Cause + Effect',
            content: [
                {
                    text: 'Cause + Effect Graphic Organizer 1',
                    url: 'https://docs.google.com/presentation/d/1fFHpbgI_L6qWOnov0hdLGNwfg53AEzPKUx_ueh9DB6Q/copy',
                },
                {
                    text: 'Cause + Effect Graphic Organizer 2',
                    url: 'https://docs.google.com/presentation/d/187N0d7MVtyWgspXJ4yFHeYghaTkmvLI2L21_Qd7w8po/copy',
                },
                {
                    text: 'Investigation Cards',
                    url: 'https://docs.google.com/presentation/d/1UHNAbrnPWGOVaYM2Q51g5NzfTRGdc_xemKpJjoi-4GY/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1oxyLPqaKpw4xE7fAciQNqgT0_u2-SE-vcpkwHCQn41M/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1Szk3NIWnfM-quzk3B9GiL-UsECDRero-8O5nhHfZsH0/copy',
                },
                {
                    text: 'Practice Writing a Summary',
                    url: 'https://docs.google.com/presentation/d/1g0rmeq3sTrZjrtOWbXOIKwFcAsZtJCJaQOhuyOf0bwM/copy',
                },
                {
                    text: 'Claim Sorting Activity',
                    url: 'https://docs.google.com/presentation/d/1GA7mTwTXLdNs3tbm_z3kzLQTa0wEC4MjXTxNYdDU9BY/copy',
                },
                {
                    text: 'Identifying Parts of an Intro Paragraph',
                    url: 'https://docs.google.com/presentation/d/1XNjBkIbZjPoWT7po_tSZmcsxE0QuOs6veH7V4HIe2M4/copy',
                },
                {
                    text: 'Premises in Writing',
                    url: 'https://docs.google.com/presentation/d/1JyWhf2q_3Re8P9LEYj8IrzFXBWumBj_-dY2ft3mfv_M/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1nbEnDOlA5LaKcBYnaX1bSvFhYMq1snYYNl8KTrMVPQE/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/19gtqbyRU5CKH351-6nuGf4TY3pFOWb4EW-B6YjpFpl4/copy',
                },
                {
                    text: 'Justification Practice',
                    url: 'https://docs.google.com/presentation/d/1qEubmFK3AnmBc8li8kG0m7JDoa9BGO315Y6ilwDuysc/copy',
                },
                {
                    text: 'Practice with the Intro and Body Paragraphs',
                    url: 'https://docs.google.com/presentation/d/1gFmVyzFSklZmTddWH_Qow2ewFBVabg_jQFTCx9e8q-8/copy',
                },
                {
                    text: 'Restating the Claim',
                    url: 'https://docs.google.com/presentation/d/1L1QKDsD_zilyqIKArB_wJPRY_WrA2r2Lmar8IUvMYQw/copy',
                },
                {
                    text: 'Summarizing Evidence',
                    url: 'https://docs.google.com/presentation/d/1rskau71KH1o84StF4CPDuFGmt_6sSwjE6bjK-8dVhq8/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/173YcfiTzMRCSsNxaSq0iuLcdjdTdRQ0bfXU08Odeeyo/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/11OiKV3OYcIVFtxHkGtg_ARwqutqzRMdDM0oL5Y_R0qY/copy',
                },
                {
                    text: 'Cause and Effect Rubric',
                    url: 'https://docs.google.com/presentation/d/13cEXGixiaRvH8nQCD5PV9D9gp8QPfMRMJ4M6lohLtnw/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1_CH3YIGo_fERnBwVTD-QRztosd1i9qdJ4QYUtruKIUI/copy',
                },
            ],
        },
        {
            value: 'compareAndContrast',
            heading: 'Compare + Contrast',
            content: [
                {
                    text: 'Compare + Contrast Graphic Organizer TWO TOPICS',
                    url: 'https://docs.google.com/presentation/d/1MIB8rHq4YUfI-E8E6r2WB4DqRTiccw9CTTO0BzYgUx0/copy',
                },
                {
                    text: 'Compare + Contrast Graphic Organizer TWO TEXTS',
                    url: 'https://docs.google.com/presentation/d/1EZS0SqKgmFgx80hzeJvznyCMBBZjxJvEi2dGFVYkxRw/copy',
                },
                {
                    text: 'Game Cards',
                    url: 'https://docs.google.com/presentation/d/1bsr7XzQi-qDmrupfitUrXEBX3oJvu4z0mfH8LlG_A3M/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1OVmJMsjefsYwIHnZw6dQjoPr4NAC5k5AUP5WNFCN-Vs/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1L8Pa9pZ3DIbNYBXU7Ut5_0vfh4vBscfFciArRT8kNmA/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/15sPgL3IY8XV1p6cqA0hdtYkVuVWG7yqPT-Q7Jrm7E70/copy',
                },
                {
                    text: 'Matching Prompts + Claims',
                    url: 'https://docs.google.com/presentation/d/1RYGLs0WytUvi_uzvjw9e023NaJqO3bH59qHCr1x3LGA/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1UrDha65RN_Qh0Xj9vKQIGFdKM5v_iJDc0QP9NnZ6pMw/copy',
                },
                {
                    text: 'Intro and Body Paragraphs Activity',
                    url: 'https://docs.google.com/presentation/d/117J0WNTqTF5cxq1PJdRXUS1PQ59DoneyftLTKf35iFg/copy',
                },
                {
                    text: 'Premises in Writing Activity',
                    url: 'https://docs.google.com/presentation/d/199HPe94uQl_-PUrfU3XErHY5fTrot__-26BogkozKdM/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1bwFrQHkbG3cNjD7f4gfd5zDvZgVkzU3YYRiTgfP7WGE/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1aJ3Q7YwDqJSvb7x1ZldxBj1X7ral558Zlo4m42ShkHg/copy',
                },
                {
                    text: 'Justification Activity',
                    url: 'https://docs.google.com/presentation/d/1aMdFbJAD-DDIY_PrsKCn2EHHI9LuO3nx0WmtSAwl4KM/copy',
                },
                {
                    text: 'Restating Claim',
                    url: 'https://docs.google.com/presentation/d/1doUXat5lan_lnfxM_XgWvCzKH07cK6TOYddrmNNNEVE/copy',
                },
                {
                    text: 'Summarizing Evidence',
                    url: 'https://docs.google.com/presentation/d/1qVU76VH8YcFaQdftS9qgkWxE_FaJr_tnLzviyG7B67Y/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/11PsihAoH_ZHqAw5-YwInpRDT5ypwgAgGnNlghHA-eYc/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1OBXjeJD5nb7A6scFUb9-9ArDMcLROU2ccLlQd46PsZo/copy',
                },
                {
                    text: 'Compare and Contrast Rubric',
                    url: 'https://docs.google.com/presentation/d/12zdXhJb4OrHc77v0VAMUwOj8GyLDTa4BPK7BpglVfXQ/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1ThZyNy5V-9rHgE6QiJnUhjlmt7ixX377NGmjiXjk4Ws/copy',
                },
            ],
        },
        {
            value: 'descriptiveWriting',
            heading: 'Descriptive Writing',
            bold: true,
            content: [
                {
                    text: 'Descriptive Writing Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1_ag9ODRItI1LlrWm55IX456mvSe8vyH27QgS8p-xGAE/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1jbPM3z6bBy_XeiAd0mwa5WNrURB-alDJM4OUX2LhgG8/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/10kVcbZQb0VT-7yB6uUkD0zs-cs-kkciEjflnCbTb6Bk/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1w7O9MIr3vlZoOrKVmdxx7OsS2E-oNM5k3UOgTdYI6Hc/copy',
                },
                {
                    text: 'Practicing Thesis Statements',
                    url: 'https://docs.google.com/presentation/d/1vxXoOnSKGWgrfTnpdKqbKqZH4J9NoNnk5IYdVu3pyAk/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1ztwQCkKEBhOhu0MahZcz5RwY8yWuqiqo3jI05aiQNtk/copy',
                },
                {
                    text: 'Understanding the Body Paragraph',
                    url: 'https://docs.google.com/presentation/d/1AqmJptdRoL6zdnS0b4806QWd947YgAEmhE1rwAUiq18/copy',
                },
                {
                    text: 'Practicing the Topic Sentences',
                    url: 'https://docs.google.com/presentation/d/1a1FF0zdASbt0dWiVGEMEm7-KHJgLI1svLf_tj2hF8W4/copy',
                },
                {
                    text: 'Concluding Statement Practice',
                    url: 'https://docs.google.com/presentation/d/1BPNvtTCxecJXV3ULvNApVXpvAcQSP_Lnim06zM2_3_k/copy',
                },
                {
                    text: 'Writing a Body Paragraph',
                    url: 'https://docs.google.com/presentation/d/1MW9fqKHdWPoUVmQMNgQ9J-Zo95YGstYMi-Bx8lY1KOI/copy',
                },
                {
                    text: 'Restating the Thesis Statement',
                    url: 'https://docs.google.com/presentation/d/1tUVToE1ohbnJUV0LHSfRFnRZlrqmRmlxkGXtLPpGFHw/copy',
                },
                {
                    text: 'Summarizing the Topic Sentence',
                    url: 'https://docs.google.com/presentation/d/181mVqP3G-QA9HsbyO1hxm-en7bpMEVsQFTfGhmBuLDM/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1BToNPLPsg5VApf0ss7xiAyua_JualDd7Eu4DByqRm7k/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1wGLXvrypc3pe7lKI6lW0JdZx3gbMDhCOc-WSTZ-cj_8/copy',
                },
                {
                    text: 'Descriptive Writing Rubric',
                    url: 'https://docs.google.com/presentation/d/1HXNYsvHt6DtXI88plHgXCaYFAyF8SjVjY6WUqspj6zE/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1_fm4EIb8qTKDMzXxqGwjfJg5znniQz_E78TqDEkFJqM/copy',
                },
            ],
        },
        {
            value: 'problemSolution',
            heading: 'Problem-Solution',
            content: [
                {
                    text: 'Problem-Solution Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1vJr-3Y820AwRryf7gQkaVEuwLQbz60tn7BuuEeCy27c/copy',
                },
                {
                    text: 'Sorting Cards',
                    url: 'https://docs.google.com/presentation/d/1tZl3_2fbPigjF5_hubPQYgVO2sN3atcmSL1xu9g5eas/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/14zJy2JDpZnngeb0qaju1FGZyJ9rANhqznZP-UtnehUs/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1lyk0Wg4IigQZw0RJonBFrZXCB9JXAYiab5YQrTEb6GI/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1h8LlLm34eN3wvkOD3McGWpI687_V3U3ToXTFQUuBWds/copy',
                },
                {
                    text: 'Investigation Cards',
                    url: 'https://docs.google.com/presentation/d/1QSeMfEXjnUxU3UkyhMGhCAlz-lBqmsx2D2P-q0nkXBE/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1weH5dqOnqYVN3vm2Cc9757IXHkM966N0_A7BePIye-8/copy',
                },
                {
                    text: 'Writing Premises Activity',
                    url: 'https://docs.google.com/presentation/d/1abuDhf5n2D6HTuv7Cr9ZFOwTaTUDhix6PvRi34RCufw/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1XV-VTD7t3OdIpKSae0Sam_j2WRZj4qq_kC_hhvyM3Eo/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1YEmK3ZaLYQHBD8AASsN9IAnHvLL-7qM-AXlRcNGwXmE/copy',
                },
                {
                    text: 'Justification Activity',
                    url: 'https://docs.google.com/presentation/d/1PyLYyLjkTpcCg2xM6jAZidFqVrIQFguyreFRhCwf25c/copy',
                },
                {
                    text: 'Intro and Body Paragraph Practice',
                    url: 'https://docs.google.com/presentation/d/1vOjo_lfjlSLr272fk5KX5zwrgX_hhN7JZaAmXn3-6BQ/copy',
                },
                {
                    text: 'Restating the Thesis ',
                    url: 'https://docs.google.com/presentation/d/1UM6JoxGnCcAJoDmLhaouxWsEMoPPZqYarpzlhGrU-CA/copy',
                },
                {
                    text: 'Summarizing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1d9UiFHEPnGewq5uOUFFsUOgkz4laqK650PApAeVAJ6Y/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1TIbnyur6gxxH6_EYXiLM_GZiHURNu44a9J60m7lcEeM/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/15vLBDeznZhSSDC_3P-arn4dpPPenE6oltG9rsaZKCOA/copy',
                },
                {
                    text: 'Problem-Solution Rubric',
                    url: 'https://docs.google.com/presentation/d/1UyCSlcAghYS_TnL0ShE_H1p9F-_iZchke1HnizhByBQ/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1MEAjddnlTcsKO0b-H4B-upMPgzAajs7EEn1eih04C9M/copy',
                },
            ],
        },
    ],
    certificateUrl:
        '/resource-assets/program-content/EB Writing Program/Level F/Certificate_of_Completion_Expository_Writing (1).pdf',
};
