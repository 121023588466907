import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { type ApolloClientOptions, InMemoryCache, Reference } from '@apollo/client/core';
import { Provider } from '@angular/core';

export function provideGraphqlClient(graphqlDomain: string): Provider {
    const provideApolloOptions = (httpLink: HttpLink): ApolloClientOptions<unknown> => ({
        cache: new InMemoryCache({
            typePolicies: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Account: {
                    fields: {
                        additionalSchoolUserData: {
                            merge: true,
                        },
                    },
                },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                DashboardConfiguration: {
                    fields: {
                        announcements: {
                            merge: false,
                        },
                    },
                },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                GetMediaResources: {
                    merge: false,
                    fields: {
                        includedObjects: {
                            merge: false,
                        },
                    },
                },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Resource: {
                    fields: {
                        // This field should always be overridden
                        // when a new value is received from the server
                        includedObjects: {
                            merge: false,
                        },
                    },
                },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                ItemDeleted: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    merge(_, incoming, { cache, readField }) {
                        cache.evict({
                            id: cache.identify({
                                // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-unsafe-argument
                                __typename: readField<string>('typename', incoming),
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                                id: readField<string>('id', incoming),
                            }),
                        });
                        cache.gc();
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                        return incoming;
                    },
                },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                AssessmentKey: {
                    fields: {
                        assessmentComponents: {
                            merge: false,
                        },
                    },
                },

                /*
                    These configuration options set up
                    customized caching used by the planner.
                    For a detailed explanation, see:
                    libs/frontend/school/data-planner-service/README.md
                */
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Query: {
                    fields: {
                        plannerCategories: {
                            keyArgs: false,
                            merge(existingArray: Reference[] = [], incomingArray: Reference[]) {
                                return mergeApolloCacheKeys(existingArray, incomingArray);
                            },
                        },
                        plannerBlocks: {
                            keyArgs: false,
                            merge(existingArray: Reference[] = [], incomingArray: Reference[]) {
                                return mergeApolloCacheKeys(existingArray, incomingArray);
                            },
                        },
                        plannerNotes: {
                            keyArgs: false,
                            merge(existingArray: Reference[] = [], incomingArray: Reference[]) {
                                return mergeApolloCacheKeys(existingArray, incomingArray);
                            },
                        },
                    },
                },
            },
        }),
        link: httpLink.create({
            uri: `${graphqlDomain}/graphql`,
            withCredentials: true,
        }),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'cache-and-network',
            },
        },
    });
    return {
        provide: APOLLO_OPTIONS,
        useFactory: provideApolloOptions,
        deps: [HttpLink],
    };
}

export function mergeApolloCacheKeys(
    existingArray: Reference[] = [],
    incomingArray: Reference[],
): Reference[] {
    const newIncomingCategories = incomingArray.filter((incomingCacheKey) => {
        const incomingCategoryAlreadyExists = existingArray.some((existingCacheKey) => {
            return existingCacheKey.__ref === incomingCacheKey.__ref;
        });
        return !incomingCategoryAlreadyExists;
    });
    return [...existingArray, ...newIncomingCategories];
}
