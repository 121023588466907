import { LinkContent } from './interfaces/programs.interfaces';

// TODO: Add links to buttons
export const VOCABULARY_PAGE_MEDIA_ID = 'cccb1bb9-eb50-48e5-9919-d5281a809f0b';
export const VOCABULARY_PAGE_LINKS: LinkContent[] = [
    {
        url: '/skills',
        text: 'SKILLS + STANDARDS',
        backgroundColor: 'var(--custom-pink)',
        params: {
            skillTitles: 'Grammar|Parts of Speech,Grammar|Sentence Structure,Grammar|Punctuation',
        },
    },
    {
        url: '/vocabulary-program/grade-level-lessons',
        text: 'GRADE LEVEL PROGRAMS',
        backgroundColor: 'var(--custom-pink)',
    },
    {
        url: '/resources',
        text: 'ADDITIONAL RESOURCES',
        backgroundColor: 'var(--custom-pink)',
        params: {
            skillTitles: 'Vocabulary|Vocabulary in Context,Vocabulary|Study of Words',
        },
    },
];

export const READING_PAGE_MEDIA_ID = '37d754a3-1ce9-451e-9205-5a16d632ba2f';

export const READING_PAGE_LINKS: LinkContent[] = [
    {
        url: '/example-sequences',
        text: 'SCOPE + SEQUENCES',
        backgroundColor: 'var(--custom-yellow)',
    },
    {
        url: '/skills',
        text: 'SKILLS + STANDARDS',
        backgroundColor: 'var(--custom-yellow)',
    },
    {
        url: '/resources',
        text: 'Resources',
        backgroundColor: 'var(--custom-yellow)',
        params: {
            skillTitles:
                "Reading Literature|Story Elements,Reading Literature|Textual Evidence,Reading Literature|Literary Devices,Reading Literature|Theme,Reading Literature|Poetry,Reading Literature|Point of View,Reading Literature|Summarizing,Reading Literature|Inferences,Reading Literature|Multiple Texts,Reading Informational Text|Multiple Texts,Reading Informational Text|Textual Evidence,Reading Informational Text|Main Idea,Reading Informational Text|Text Structure,Reading Informational Text|Point of View,Reading Informational Text|Summarizing,Reading Informational Text|Author's Purpose,Reading Informational Text|Inferences",
        },
    },
];

export const WRITING_PAGE_LINKS: LinkContent[] = [
    {
        url: 'the-ebw-approach',
        text: 'THE EBW APPROACH',
        backgroundColor: 'var(--custom-blue)',
    },
    {
        url: 'narrative-writing',
        text: 'NARRATIVE',
        backgroundColor: 'var(--custom-blue)',
    },
    {
        url: 'expository-writing',
        text: 'EXPOSITORY',
        backgroundColor: 'var(--custom-blue)',
    },
    {
        url: 'persuasive-and-argumentative-writing',
        text: 'PERSUASIVE + ARGUMENTATIVE',
        backgroundColor: 'var(--custom-blue)',
    },
];

export const GRAMMAR_PAGE_MEDIA_ID = 'cf7aa25e-a2ec-44e4-91d5-276ab4851624';

export const GRAMMAR_PAGE_LINKS: LinkContent[] = [
    {
        url: 'grammar-pd-videos',
        text: 'PD VIDEOS',
        backgroundColor: 'var(--ion-color-tertiary)',
    },
    {
        url: 'grade-level-lessons',
        text: 'GRADE LEVEL LESSONS',
        backgroundColor: 'var(--ion-color-tertiary)',
    },
    {
        url: 'supporting-lessons',
        text: 'SUPPORTING LESSONS',
        backgroundColor: 'var(--ion-color-tertiary)',
    },
];
