import { DownloadsConfig } from '../../interfaces/programs.interfaces';

export const NARRATIVE_5TH_GRADE_DOWNLOADS_CONFIG: DownloadsConfig = {
    resourceLink: '/resources/2c32208c-879b-40e6-bf11-810ea12fb1e4',
    headingConfig: {
        letter: 'D',
        grade: 'Grade 5',
        level: 'Level D',
        color: 'var(--program-grade-level-d)',
    },
    slideDeckHeading: 'Narrative Writing Slide Decks',
    slideDeckUrl:
        '/resource-assets/program-content/EB Writing Program/Level D/Narrative_Writing_Student_Slide_Decks_ (2).zip',
    digitFilesConfig: [
        {
            value: 'whatIsNarrativeWriting',
            heading: 'What is Narrative Writing?',
            content: [
                {
                    text: 'What is Narrative Writing?',
                    url: 'https://docs.google.com/presentation/d/1mgofxCwNFBI7RUe2wiaOlGTxORvC9L9OhbWh9Rqw0YM/copy',
                },
            ],
        },
        {
            value: 'miniLessons',
            heading: 'Mini Lessons',
            content: [
                {
                    text: 'Brainstorming',
                    url: 'https://docs.google.com/presentation/d/16JQqWPMKwcNo5Kb-kIZrUFSBRIHxaOnYeunSNsbjTtU/copy',
                },
                {
                    text: 'Narrative Writing Quilt',
                    url: 'https://docs.google.com/presentation/d/1lCNgDiC5Fus-8A3bOoKC5Ixsjd64mB6Tsud6QMTEamk/copy',
                },
                {
                    text: 'Understanding POV',
                    url: 'https://docs.google.com/presentation/d/1jZ2ICGeh6Zbp70VC_VqlYD5BjWlXlEt8MrxbyLkTo9U/copy',
                },
                {
                    text: 'Writing Leads',
                    url: 'https://docs.google.com/presentation/d/1M-0Pz_lVz22n7OyeMD-_gb1w_Q-10dcbwoZMAbAUjFA/copy',
                },
                {
                    text: 'Creating Endings',
                    url: 'https://docs.google.com/presentation/d/18o0wanwxi7KCUftNBpQHQyAQF4HltRjxHqMdPQuhrCA/copy',
                },
                {
                    text: 'Crafting Stronger Sentences',
                    url: 'https://docs.google.com/presentation/d/1pleDPZ9Q43EBOFduEohRSxSZnKihA4_8nD3i5tAHSw4/copy',
                },
                {
                    text: 'Expanding Sentences',
                    url: 'https://docs.google.com/presentation/d/1BI11JWKbWRSz_HW1K1pNhYMWusFlzhhGQrlEEGa71XI/copy',
                },
                {
                    text: 'Dialogue',
                    url: 'https://docs.google.com/presentation/d/1DVErevRbv9NkWMbK45r_dqu8hUYISEBUo6qF5OO4TBw/copy',
                },
                {
                    text: 'Transition Words',
                    url: 'https://docs.google.com/presentation/d/1hMSGKOZAFsnueQaGIJ3Pc9t4eru3xsejCQyp8KrWuaE/copy',
                },
                {
                    text: 'Story Arc',
                    url: 'https://docs.google.com/presentation/d/1CYXYcOMdEjs1SVLv9Yg7GRIjz0T-7ZgqrirvOkLBG2Q/copy',
                },
            ],
        },
        {
            value: 'theWritingProcess',
            heading: 'The Writing Process',
            content: [
                {
                    text: 'All About Figurative Language',
                    url: 'https://docs.google.com/presentation/d/1F9jNc8dSnV1jYTV2BG0_520uR2KpXO9yu4px-5kzxI8/copy',
                },
                {
                    text: 'Narrative Writing Rubric',
                    url: 'https://docs.google.com/presentation/d/1jViL668x5T6obJ7q9gYU9MAsxIquP_rdulCN3EWdtGY/copy',
                },
                {
                    text: 'Student Checklist',
                    url: 'https://docs.google.com/presentation/d/1IAYVPO8ExmruXB1yjOnZJRQL-D91Cz59hJWyW0IEq30/copy',
                },
            ],
        },
    ],
    certificateUrl:
        '/resource-assets/program-content/EB Writing Program/Level D/Certificate_of_Completion_Narrative_Writing (2).pdf',
};
