import { DownloadsConfig } from '../../interfaces/programs.interfaces';

export const PERSUASIVE_AND_ARGUMENTATIVE_7TH_GRADE_DOWNLOAD_CONFIG: DownloadsConfig = {
    resourceLink: [
        {
            text: 'Persuasive Writing 7th Grade',
            url: '/resources/a76d84a4-2f97-4de8-8f1c-33ad10249bbe',
        },
        {
            text: 'Argumentative Writing 7th Grade',
            url: '/resources/5534ed87-6174-4266-877c-a236e3f3e53c',
        },
    ],
    headingConfig: {
        letter: 'F',
        level: 'Level F',
        grade: 'Grade 7',
        color: 'var(--program-grade-level-f)',
    },
    slideDeckHeading: 'Persuasive and Argumentative Slide Decks',
    slideDeckUrl: [
        {
            text: 'Argumentative_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level F/Argumentative_Slide_Decks (1).zip',
        },
        {
            text: 'Persuasive_Writing_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level F/Persuasive_Writing_Slide_Decks (1).zip',
        },
    ],
    digitFilesConfig: [
        {
            value: 'argumentativeWriting',
            heading: 'Argumentative Writing',
            bold: true,
            content: [
                {
                    text: 'Argumentative Writing Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1ncec3Em3yIqJq3VLevTz86Ik9hZUGWkYZoP8jqXWnKo/copy',
                },
                {
                    text: 'Case Report',
                    url: 'https://docs.google.com/presentation/d/1MqafOg7-qnArUbvCE080Kq0LcMtcUOi45P5L_DlDQfc/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1cBCSg81LvKBgphKIIGiFcnxr3vtJug_d73YbjHQ3zXM/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1wO-jqY1Dmy5v-x31TK54MHf7WY9whcVy6BJZZETqS6I/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1yeTQ2D6GveDs1LLIAGN9u7WaKFD_q8j2Er6fYacWyW0/copy',
                },
                {
                    text: 'Claim Sorting Activity',
                    url: 'https://docs.google.com/presentation/d/1jWSCFDsxB8kyhpGwJZuHM4WfxVpGx9ba6rcbuYUYrXE/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1XyHnj1suhchDju3whY06gYXpyN76iDR6-lZ80HEVn1o/copy',
                },
                {
                    text: 'Premises Writing Activity',
                    url: 'https://docs.google.com/presentation/d/1_6bAIHMWPm71rfkjCPFVuoixmBZ0Gn45SH3k0mZ2gvM/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1cbsANmL0Ll-yzacwa-8KoK_Pm3dnM8-GkD02bzchytQ/copy',
                },

                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1qUiLxTdL7RX0VrYKtvm99dFTcmmp1hNAQ33OfTdG5Dw/copy',
                },
                {
                    text: 'Justification Activity',
                    url: 'https://docs.google.com/presentation/d/1r28MvXzV42huc00yv4iipD_L-zdWhrs2lz2bqaniw2s/copy',
                },
                {
                    text: 'Claims and Counterclaims Activity',
                    url: 'https://docs.google.com/presentation/d/1m16gba2CAJbeN9R6vB6aBvQav0k5C6hWVmUm4lhBdQw/copy',
                },
                {
                    text: 'Practice with the Body Paragraph',
                    url: 'https://docs.google.com/presentation/d/1maIHsjL50P56iPxEXtDhiid5TB4phu-XJgw88ENXobg/copy',
                },
                {
                    text: 'Restating Claim',
                    url: 'https://docs.google.com/presentation/d/1t9Off0jlKYe_JXXHeI-f78iebaPEEgm8b_EsTYHiNeM/copy',
                },
                {
                    text: 'Summarizing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1E0EtSNBOIdrgs8XfeTHwJMVIraa7mVb1b25TszXPFBw/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1IUpvwFhMcoqk8ZWy5XOmB3Z7LT-jhkRpNgvRQUC4mpQ/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1tsPZIs9XAPwNosrwu4mTYL6gT7i7Zh75N94LtdQ1vzY/copy',
                },
                {
                    text: 'Argumentative Writing Rubric',
                    url: 'https://docs.google.com/presentation/d/13E6ByI7RSvPNRsVJguMxw2lppaw7GFaPVYQDcdsTQos/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1Rp8D95qDjFpbpbM6dyPUi2zKyUIOwANDH85xbVpRIIE/copy',
                },
            ],
        },
        {
            value: 'persuasiveWriting',
            heading: 'Persuasive Writing',
            bold: true,
            content: [
                {
                    text: 'Persuasive Writing Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1ebtXSXxZW2iud69v1P9shlMsUg2DYSuYGq9piDJ-xKI/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1as2RJlAipk-0TPYi98g0YchO4cVzwk6v4hx90wkoWhU/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1KXKI_t4wi9o6-GudOm0w0VfNsxuqvnWOqKTVJm1BiYo/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1Voler4l4TjLOjf6QhxvKCahAwUjXCElOe7hMfmQf2-M/copy',
                },
                {
                    text: 'Claim Sorting Activity',
                    url: 'https://docs.google.com/presentation/d/1cPL2tObJXN4FxtHz0DSpBzt-dDSaRG9VU8gFE4IMb28/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1ypMqegs_KV1598HyvlToyLdnTXD3engB0Iic50_SD7Q/copy',
                },
                {
                    text: 'Premises Writing Activity',
                    url: 'https://docs.google.com/presentation/d/1Jm5Z8Q0IFy01ol9gfT1MVU88x4tZT-cPos6OZc428js/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/19fYCrqUfS_-BVH2xAhGqUtkjgXULJt5ph1h2rfUt-Ao/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1qONI7QMVTpnXwKcDuQQsHo3OI51oeGY8N1WJKFU9a5I/copy',
                },
                {
                    text: 'Justification Activity',
                    url: 'https://docs.google.com/presentation/d/1iDmjR6uCcxvvkAmHIwORdKOR7m5fE2Vgh6b7vAQQUJI/copy',
                },
                {
                    text: 'Practice with the Body Paragraph',
                    url: 'https://docs.google.com/presentation/d/1mYEIV5LKndNB5ls7mIp3rS9ShFrWMgE1A-D7MoTNdpQ/copy',
                },
                {
                    text: 'Restating Claim',
                    url: 'https://docs.google.com/presentation/d/10UtqG6PDQnlFG7EXdTPGg1751-2TvJzWxXYz4pI2tsQ/copy',
                },
                {
                    text: 'Summarizing Evidence',
                    url: 'https://docs.google.com/presentation/d/1ETJQuoObtbb8NmasXU56o2JKWEbT79NaOEUxYF5GN5w/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1_kndN0FBiblJLM9CSi2cGQvUu4QitG7CtzKyylFkOwo/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1ok7RUnTjnfJlW_FbEii2HuaJH1-5-VFhmL4fF_7vPfg/copy',
                },
                {
                    text: 'Persuasive Writing Rubric',
                    url: 'https://docs.google.com/presentation/d/1xjuHrcMwWF9U0owAd28oxtnJl2CLajNVQGEfrSRPkX0/copy',
                },
                {
                    text: 'Helpful Hints',
                    url: 'https://docs.google.com/presentation/d/17N83g5fsJGRkr47bO6EoZGhIDMXn2kLn5WzpQcVCwu0/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1fhhs4X3i0GHdgpJEKN5T51kqhDIVEmqgS_2QWYVIW6w/copy',
                },
            ],
        },
    ],
    certificateUrl:
        '/resource-assets/program-content/EB Writing Program/Level F/Certificate_of_Completion_Argumentative_and_Persuasive_Writing (1).pdf',
};
