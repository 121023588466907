import { SchoolUserData } from './school/school-user-data.interface';
import { Email, Phone, Uuid } from '@lib/shared-interface-utility-types';
import { StudentUserData } from './student/student-user-data.interface';

export type Account = {
    /**
     * Unique ID for this account.
     */
    id: Uuid;
    /**
     * If false, the user's access has been revoked, but is kept around for historical purposes
     */
    active: boolean;
    /**
     * The email so that others can reach this account.
     */
    email: Email;
    firstName: string;
    lastName: string;
    /**
     * The full name so that we know how to refer to the person behind this account.
     * This will just be a concatenation of the first and last name.
     */
    fullName: string;
    /**
     * Phone number in E.164 format.
     */
    phone?: Phone;
    /**
     * The base64 encoded image uploaded by the user
     */
    avatar?: string;
    /**
     * The id of the account which invited this user. Undefined for the first account created.
     */
    inviterId?: Uuid;
    /**
     * When the account data was first created.
     */
    createdAt: number;
    /**
     * When the account data was last updated.
     */
    updatedAt: number;
    /**
     * Date and time they agreed to the terms & conditions.
     */
    agreedToTermsAndConditionsAt?: number;
    /**
     * Date and time the privacy policy was acknowledged.
     */
    acknowledgedPrivacyPolicyAt?: number;
    /**
     * If the user has registered, they are no longer pending.
     */
    pending: boolean;

    // School-specific properties
    additionalSchoolUserData?: SchoolUserData;
    // Student-specific properties
    additionalStudentUserData?: StudentUserData;
};

export type AccountProfileAdminChanges = AccountProfileChanges & Partial<Pick<Account, 'email'>>;
export type SchoolProfileAdminChanges = AccountProfileAdminChanges & Pick<SchoolUserData, 'roles'>;

export type AccountProfileChanges = Partial<
    Pick<Account, 'firstName' | 'lastName' | 'phone' | 'avatar'>
>;

export type TeachingInfo = Pick<
    SchoolUserData,
    | 'schoolId'
    | 'jobTitle'
    | 'gradesTaught'
    | 'schoolName'
    | 'schoolCountry'
    | 'schoolState'
    | 'schoolCity'
>;

export type MinimalStudentData = Pick<
    Account,
    'id' | 'firstName' | 'lastName' | 'email' | 'additionalStudentUserData'
>;

export const LOGIN_AS_COOKIE_NAME = 'USER_EMAIL';
