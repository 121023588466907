import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

/** A user account */
export type ApolloAccount = {
  /** The timestamp of when the user acknowledged the privacy policy. */
  acknowledgedPrivacyPolicyAt?: Maybe<Scalars['Float']['output']>;
  /** Whether account is active or not. */
  active: Scalars['Boolean']['output'];
  /** Additional data specific to a school user. */
  additionalSchoolUserData?: Maybe<ApolloSchoolUserData>;
  /** Additional data specific to a student user. */
  additionalStudentUserData?: Maybe<ApolloStudentUserData>;
  /** The timestamp of when the user accepted the terms and conditions. */
  agreedToTermsAndConditionsAt?: Maybe<Scalars['Float']['output']>;
  /** A user supplied image. */
  avatar?: Maybe<Scalars['String']['output']>;
  /** The date/time that this document was created. */
  createdAt: Scalars['Float']['output'];
  /** The account email address. */
  email: Scalars['String']['output'];
  /** The first name of the account user. */
  firstName: Scalars['String']['output'];
  /** Full name which is just a concatenation of the first and last name. */
  fullName: Scalars['String']['output'];
  /** Unique identifier, UUIDv4. */
  id: Scalars['ID']['output'];
  /** The id of the person who invited this account. */
  inviterId?: Maybe<Scalars['ID']['output']>;
  /** The last name of the account user. */
  lastName: Scalars['String']['output'];
  /** Whether or not the user invitation is still pending. */
  pending: Scalars['Boolean']['output'];
  /** Phone number in E.164 format. */
  phone?: Maybe<Scalars['String']['output']>;
  planWouldChange: Scalars['Boolean']['output'];
  /** The last date/time this document was updated. */
  updatedAt: Scalars['Float']['output'];
};


/** A user account */
export type ApolloAccountPlanWouldChangeArgs = {
  desiredPlan: ApolloSchoolAccountPlan;
};

export type ApolloAccountProfileAdminChangesInput = {
  /** An image or avatar for identification purposes. */
  avatar?: InputMaybe<Scalars['String']['input']>;
  /** The account email address. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** The first name of the account user. */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The last name of the account user. */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Phone number in E.164 format. */
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type ApolloAccountProfileChangesInput = {
  /** An image or avatar for identification purposes. */
  avatar?: InputMaybe<Scalars['String']['input']>;
  /** The first name of the account user. */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The last name of the account user. */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Phone number in E.164 format. */
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type ApolloAccountRegistrationInput = {
  /** The user ID from the authentication system. */
  authenticationId: Scalars['ID']['input'];
  /** The account email address. */
  email: Scalars['String']['input'];
};

/** A timestamped record of an account status change. */
export type ApolloAccountStatusChangeEvent = {
  changedAt: Scalars['Float']['output'];
  changedBy?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type ApolloAddress = {
  city?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street1?: Maybe<Scalars['String']['output']>;
  street2?: Maybe<Scalars['String']['output']>;
  street3?: Maybe<Scalars['String']['output']>;
};

export type ApolloAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street1?: InputMaybe<Scalars['String']['input']>;
  street2?: InputMaybe<Scalars['String']['input']>;
  street3?: InputMaybe<Scalars['String']['input']>;
};

/** An announcement to be displayed on the dashboard. */
export type ApolloAnnouncement = {
  /** the emoji to be displayed with the announcement */
  emoji?: Maybe<Scalars['String']['output']>;
  /** the link to be followed when the announcement is clicked */
  link?: Maybe<Scalars['String']['output']>;
  /** the target of the link */
  target?: Maybe<Scalars['String']['output']>;
  /** the subtitle rich-text of the announcement */
  text?: Maybe<Scalars['String']['output']>;
  /** the bold title of the announcement */
  title: Scalars['String']['output'];
};

/** The input for an announcement object */
export type ApolloAnnouncementInput = {
  /** the emoji to be displayed with the announcement */
  emoji?: InputMaybe<Scalars['String']['input']>;
  /** the link to be followed when the announcement is clicked */
  link?: InputMaybe<Scalars['String']['input']>;
  /** the target of the link */
  target?: InputMaybe<Scalars['String']['input']>;
  /** the subtitle rich-text of the announcement */
  text?: InputMaybe<Scalars['String']['input']>;
  /** the bold title of the announcement */
  title: Scalars['String']['input'];
};

/** An option for answering a question on an assessment. */
export type ApolloAnswerOption = {
  /** Text of the answer. */
  text: Scalars['String']['output'];
};

/** An option for answering a question on an assessment. */
export type ApolloAnswerOptionInput = {
  /** Text of the answer. */
  text: Scalars['String']['input'];
};

/** An answer option with a correct flag and explanation. */
export type ApolloAnswerOptionKey = {
  /** Whether this is a correct answer. */
  correct: Scalars['Boolean']['output'];
  /** An explanation of why this answer is correct or incorrect. */
  explanation?: Maybe<Scalars['String']['output']>;
  /** Text of the answer. */
  text: Scalars['String']['output'];
};

/** An answer option with a correct flag and explanation. */
export type ApolloAnswerOptionKeyInput = {
  /** Whether this is a correct answer. */
  correct: Scalars['Boolean']['input'];
  /** An explanation of why this answer is correct or incorrect. */
  explanation?: InputMaybe<Scalars['String']['input']>;
  /** Text of the answer. */
  text: Scalars['String']['input'];
};

export type ApolloAnyClassAssignment = ApolloClassAssessmentAssignment | ApolloClassMiscellaneousAssignment;

/** An assessment of student skills at a grade level. */
export type ApolloAssessment = {
  /** Questions or question groups on this assessment. */
  assessmentComponents: Array<ApolloAssessmentComponent>;
  /** ID of the assessment. */
  id: Scalars['ID']['output'];
  /** Optional introductory instructions for the assessment. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The grade level that the assessment is intended for. */
  intendedGradeLevel: ApolloGradeLevel;
  /** Title of the assessment. */
  title: Scalars['String']['output'];
  /** The type of assessment. */
  type: ApolloAssessmentType;
};

export type ApolloAssessmentComponent = ApolloQuestionAssessmentComponent | ApolloQuestionGroupAssessmentComponent;

export type ApolloAssessmentComponentKey = ApolloQuestionAssessmentComponentKey | ApolloQuestionGroupAssessmentComponentKey;

/** Fields for all possible assessment components */
export type ApolloAssessmentComponentKeyInput = {
  /** The id of the question group. */
  questionGroupId?: InputMaybe<Scalars['ID']['input']>;
  /** The id of the question. */
  questionId?: InputMaybe<Scalars['ID']['input']>;
  /** The type of assessment component. */
  type: ApolloAssessmentComponentType;
};

/** The type of an assessment component */
export const ApolloAssessmentComponentType = {
  Question: 'QUESTION',
  QuestionGroup: 'QUESTION_GROUP'
} as const;

export type ApolloAssessmentComponentType = typeof ApolloAssessmentComponentType[keyof typeof ApolloAssessmentComponentType];
/** An assessment with correct answers and explanations. */
export type ApolloAssessmentKey = {
  assessmentComponents: Array<ApolloAssessmentComponentKey>;
  /** ID of the assessment. */
  id: Scalars['ID']['output'];
  /** Optional introductory instructions for the assessment. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The grade level that the assessment is intended for. */
  intendedGradeLevel: ApolloGradeLevel;
  /** The date this assessment becomes available to teachers */
  publishAt?: Maybe<Scalars['Float']['output']>;
  /** Title of the assessment. */
  title: Scalars['String']['output'];
  /** The type of assessment. */
  type: ApolloAssessmentType;
};

/** Input for an existing assessment key */
export type ApolloAssessmentKeyInput = {
  assessmentComponents: Array<ApolloAssessmentComponentKeyInput>;
  /** ID of the assessment. */
  id: Scalars['ID']['input'];
  /** Optional introductory instructions for the assessment. */
  instructions?: InputMaybe<Scalars['String']['input']>;
  /** The grade level that the assessment is intended for. */
  intendedGradeLevel: ApolloGradeLevel;
  /** The date this assessment becomes available to teachers */
  publishAt?: InputMaybe<Scalars['Float']['input']>;
  /** Title of the assessment. */
  title: Scalars['String']['input'];
  /** The type of assessment. */
  type: ApolloAssessmentType;
};

/** A question on an assessment. */
export type ApolloAssessmentQuestion = {
  /** Answer options for the question. */
  answerOptions: Array<ApolloAnswerOption>;
  /** The format of the question. */
  format: ApolloQuestionFormat;
  /** ID of the question. */
  id: Scalars['ID']['output'];
  /** The grade level that the question is intended for. */
  intendedGradeLevel: ApolloGradeLevel;
  /** ID of the skill that this question is intended to assess. */
  intendedSkillId: Scalars['ID']['output'];
  /** Text of the question. */
  text: Scalars['String']['output'];
};

/** A question on an assessment with correct answers and explanations. */
export type ApolloAssessmentQuestionKey = {
  /** Answer options with correct flags and explanations. */
  answerOptions: Array<ApolloAnswerOptionKey>;
  /** The format of the question. */
  format: ApolloQuestionFormat;
  /** ID of the question. */
  id: Scalars['ID']['output'];
  /** The grade level that the question is intended for. */
  intendedGradeLevel: ApolloGradeLevel;
  /** ID of the skill that this question is intended to assess. */
  intendedSkillId: Scalars['ID']['output'];
  /** Text of the question. */
  text: Scalars['String']['output'];
};

/** Input for updating an assessment question key */
export type ApolloAssessmentQuestionKeyInput = {
  /** Answer options with correct flags and explanations. */
  answerOptions: Array<ApolloAnswerOptionKeyInput>;
  /** The format of the question. */
  format: ApolloQuestionFormat;
  /** ID of the question. */
  id: Scalars['ID']['input'];
  /** The grade level that the question is intended for. */
  intendedGradeLevel: ApolloGradeLevel;
  /** ID of the skill that this question is intended to assess. */
  intendedSkillId: Scalars['ID']['input'];
  /** Text of the question. */
  text: Scalars['String']['input'];
};

/** The type of an assessment. */
export const ApolloAssessmentType = {
  Formative: 'FORMATIVE',
  Summative: 'SUMMATIVE'
} as const;

export type ApolloAssessmentType = typeof ApolloAssessmentType[keyof typeof ApolloAssessmentType];
/** The type of assignment */
export const ApolloAssignmentType = {
  Assessment: 'ASSESSMENT',
  Miscellaneous: 'MISCELLANEOUS'
} as const;

export type ApolloAssignmentType = typeof ApolloAssignmentType[keyof typeof ApolloAssignmentType];
/** An assessment assignment for a class. */
export type ApolloClassAssessmentAssignment = {
  /** ID of the assessment that this assignment is for. */
  assessmentId: Scalars['ID']['output'];
  /** End date and time of the assignment */
  endDateTime?: Maybe<Scalars['Float']['output']>;
  /** ID of the assignment. */
  id: Scalars['ID']['output'];
  /** ID of the class that this assignment is for. */
  schoolClassId: Scalars['ID']['output'];
  /** Start date and time of the assignment */
  startDateTime?: Maybe<Scalars['Float']['output']>;
  studentAssignments: Array<ApolloStudentAssignment>;
  /** Title of the assignment. */
  title: Scalars['String']['output'];
  /** The type of this assignment */
  type: ApolloAssignmentType;
};

/** A miscellaneous assignment for a class. */
export type ApolloClassMiscellaneousAssignment = {
  /** Description of the assignment. */
  description?: Maybe<Scalars['String']['output']>;
  /** End date and time of the assignment */
  endDateTime?: Maybe<Scalars['Float']['output']>;
  /** ID of the assignment. */
  id: Scalars['ID']['output'];
  /** ID of the class that this assignment is for. */
  schoolClassId: Scalars['ID']['output'];
  /** Start date and time of the assignment */
  startDateTime?: Maybe<Scalars['Float']['output']>;
  studentAssignments: Array<ApolloStudentAssignment>;
  /** Title of the assignment. */
  title: Scalars['String']['output'];
  /** The type of this assignment */
  type: ApolloAssignmentType;
};

/** An assessment with correct answers and explanations. */
export type ApolloCreateAssessmentKeyInput = {
  assessmentComponents: Array<ApolloAssessmentComponentKeyInput>;
  /** Optional introductory instructions for the assessment. */
  instructions?: InputMaybe<Scalars['String']['input']>;
  /** The grade level that the assessment is intended for. */
  intendedGradeLevel: ApolloGradeLevel;
  /** The date this assessment becomes available to teachers */
  publishAt?: InputMaybe<Scalars['Float']['input']>;
  /** Title of the assessment. */
  title: Scalars['String']['input'];
  /** The type of assessment. */
  type: ApolloAssessmentType;
};

/** Input to create a question for an assessment */
export type ApolloCreateAssessmentQuestionKeyInput = {
  /** Answer options with correct flags and explanations. */
  answerOptions: Array<ApolloAnswerOptionKeyInput>;
  /** The format of the question. */
  format: ApolloQuestionFormat;
  /** The grade level that the question is intended for. */
  intendedGradeLevel: ApolloGradeLevel;
  /** ID of the skill that this question is intended to assess. */
  intendedSkillId: Scalars['ID']['input'];
  /** Text of the question. */
  text: Scalars['String']['input'];
};

/** Input for creating a class assessment assignment. */
export type ApolloCreateClassAssessmentAssignmentInput = {
  /** ID of the assessment that this assignment is for. */
  assessmentId: Scalars['ID']['input'];
  /** End date and time of the assignment */
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  /** ID of the class that this assignment is for. */
  schoolClassId: Scalars['ID']['input'];
  /** Start date and time of the assignment */
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  /** Any student assignments that should be created for this class assignment */
  studentAssignments: Array<ApolloCreateStudentAssignment>;
  /** Title of the assignment. */
  title: Scalars['String']['input'];
};

/** Input for creating a class miscellaneous assignment. */
export type ApolloCreateClassMiscellaneousAssignmentInput = {
  /** Description of the assignment. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** End date and time of the assignment */
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  /** ID of the class that this assignment is for. */
  schoolClassId: Scalars['ID']['input'];
  /** Start date and time of the assignment */
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  /** Any student assignments that should be created for this class assignment */
  studentAssignments: Array<ApolloCreateStudentAssignment>;
  /** Title of the assignment. */
  title: Scalars['String']['input'];
};

/** Input for creating a planner block */
export type ApolloCreatePlannerBlockInput = {
  /** The color associated with this planner block */
  color: Scalars['String']['input'];
  /** The description of the planner block */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The start of the last day of this block in milliseconds since the epoch */
  endingSchoolDay: Scalars['Float']['input'];
  /** The id of the planner topic this block belongs to */
  plannerTopicId: Scalars['ID']['input'];
  /** The id of the resource tied to this block */
  resourceId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the school class this block belongs to */
  schoolClassId: Scalars['ID']['input'];
  /** The beginning of the first day of this block in milliseconds since the epoch */
  startingSchoolDay: Scalars['Float']['input'];
  /** The title of the planner block */
  title: Scalars['String']['input'];
};

/** A new planner note */
export type ApolloCreatePlannerNoteInput = {
  /** The main body of the lesson plan */
  body?: InputMaybe<Scalars['String']['input']>;
  /** The id of the sequence block associated with this class day plan */
  plannerBlockId?: InputMaybe<Scalars['ID']['input']>;
  /** The offset in days from the beginning of the sequence block */
  plannerBlockOffset?: InputMaybe<Scalars['Float']['input']>;
  /** The id of the class */
  schoolClassId: Scalars['ID']['input'];
};

/** Input for creating a planner topic */
export type ApolloCreatePlannerTopicInput = {
  /** The id of the school class this topic belongs to */
  schoolClassId: Scalars['ID']['input'];
  /** The order of the planner topic in the sequence */
  sortOrder: Scalars['Int']['input'];
  /** The title of the planner topic */
  title: Scalars['String']['input'];
};

/** Input to create a group of questions for an assessment */
export type ApolloCreateQuestionGroupKeyInput = {
  /** Optional content for the group of questions. */
  content?: InputMaybe<Scalars['String']['input']>;
  /** Optional instructions for the group of questions. */
  instructions?: InputMaybe<Scalars['String']['input']>;
  /** IDs of the questions in the group. */
  questionIds: Array<Scalars['ID']['input']>;
  /** Title of the group of questions. */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** A class a teacher is teaching */
export type ApolloCreateSchoolClassInput = {
  /** The color associated with this class */
  color: Scalars['String']['input'];
  /** The beginning of the first day of class in milliseconds since the epoch */
  firstDay: Scalars['Float']['input'];
  /** The grade level of the class */
  gradeLevel: ApolloGradeLevel;
  /** The start of the last day of class in milliseconds since the epoch */
  lastDay: Scalars['Float']['input'];
  /** The period of the class */
  period?: InputMaybe<Scalars['Int']['input']>;
  /** The ids of the skills taught in this class */
  skillIds: Array<Scalars['ID']['input']>;
  /** The title of the class */
  title: Scalars['String']['input'];
};

/** Input for creating a student assignment */
export type ApolloCreateStudentAssignment = {
  /** The end date and time for this student if it is different than that of the class assignment */
  endDateTimeOverride?: InputMaybe<Scalars['Float']['input']>;
  /** The start date and time for this student if it is different than that of the class assignment */
  startDateTimeOverride?: InputMaybe<Scalars['Float']['input']>;
  /** When the student began the assessment in ms since the epoch */
  startedAt?: InputMaybe<Scalars['Float']['input']>;
  /** The Student ID */
  studentId?: InputMaybe<Scalars['ID']['input']>;
  /** When the student finished the assessment in ms since the epoch */
  submittedAt?: InputMaybe<Scalars['Float']['input']>;
};

/** The configuration for the dashboard of one of the frontend apps */
export type ApolloDashboardConfiguration = {
  /** The announcements to be displayed on the dashboard. */
  announcements?: Maybe<Array<ApolloAnnouncement>>;
  /** The UserApplication Enum value for the associated application the configuration will be for. */
  applicationType: ApolloUserApplication;
  /** The date/time that this document was created. */
  createdAt: Scalars['Float']['output'];
  /** Unique identifier of the media library object */
  id: Scalars['ID']['output'];
  teacherSpotlight?: Maybe<ApolloTeacherSpotlight>;
  /** The last date/time this document was updated. */
  updatedAt: Scalars['Float']['output'];
};

/** The input for a dashboard configuration object */
export type ApolloDashboardConfigurationInput = {
  /** The announcements to be displayed on the dashboard. */
  announcements?: InputMaybe<Array<ApolloAnnouncementInput>>;
  /** The UserApplication Enum value for the associated application the configuration will be for. */
  applicationType: ApolloUserApplication;
  /** The id of the dashboard configuration object if it exists */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The teacher spotlight to be displayed on the dashboard. */
  teacherSpotlight?: InputMaybe<ApolloTeacherSpotlightInput>;
};

/** A facet filter that should be applied to a search */
export type ApolloFacetFilterInput = {
  /** The value to be included */
  value: Scalars['String']['input'];
};

/** Facet filters that should be applied to a search */
export type ApolloFacetFilterSetInput = {
  /** Filters to apply for this property */
  filters: Array<ApolloFacetFilterInput>;
  /** The property that these filters apply to */
  property: ApolloFilterableProperty;
};

/** A facet filter that can be applied to a search */
export type ApolloFilter = {
  /** The number of objects with this value */
  count: Scalars['Int']['output'];
  /** The value to be included */
  value: Scalars['String']['output'];
};

/** Available facet filters for the specified property */
export type ApolloFilterSet = {
  /** The available filter values for this property */
  filters: Array<ApolloFilter>;
  /** The property that these filters apply to */
  property: ApolloFilterableProperty;
};

/** A property that can be filtered on resources */
export const ApolloFilterableProperty = {
  GradeLevels: 'gradeLevels',
  SkillTitles: 'skillTitles',
  Types: 'types'
} as const;

export type ApolloFilterableProperty = typeof ApolloFilterableProperty[keyof typeof ApolloFilterableProperty];
/** Input to find all assignments for the provided studentId in the class with the provided classId. */
export type ApolloGetStudentAssignmentsByClassIdGqlInput = {
  /** The Class ID */
  classId: Scalars['ID']['input'];
  /** The Student ID */
  studentId: Scalars['ID']['input'];
};

/** The intended grade level for the resource */
export const ApolloGradeLevel = {
  AdultEducation: 'ADULT_EDUCATION',
  Grade_1: 'GRADE_1',
  Grade_2: 'GRADE_2',
  Grade_3: 'GRADE_3',
  Grade_4: 'GRADE_4',
  Grade_5: 'GRADE_5',
  Grade_6: 'GRADE_6',
  Grade_7: 'GRADE_7',
  Grade_8: 'GRADE_8',
  Grade_9: 'GRADE_9',
  Grade_10: 'GRADE_10',
  Grade_11: 'GRADE_11',
  Grade_12: 'GRADE_12',
  Grade_13: 'GRADE_13',
  Kindergarten: 'KINDERGARTEN',
  PreKindergarten: 'PRE_KINDERGARTEN',
  UnderGraduate: 'UNDER_GRADUATE'
} as const;

export type ApolloGradeLevel = typeof ApolloGradeLevel[keyof typeof ApolloGradeLevel];
export type ApolloImportedSchool = {
  createdAt: Scalars['Float']['output'];
  gradesOffered: Array<ApolloGradeLevel>;
  id: Scalars['ID']['output'];
  importFormat?: Maybe<Scalars['String']['output']>;
  isCharter?: Maybe<Scalars['Boolean']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  location?: Maybe<ApolloAddress>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  schoolId?: Maybe<Scalars['String']['output']>;
  schoolType?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Float']['output'];
  updatedStatus?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

/** An object that is included in a resource */
export type ApolloIncludedObject = {
  /** The path of the included object */
  path?: Maybe<Scalars['String']['output']>;
  storageObject: ApolloStorageObject;
  /** The id of the included storage object */
  storageObjectId: Scalars['ID']['output'];
};

/** Input type for an included object */
export type ApolloIncludedObjectInput = {
  /** The path of the included object */
  path?: InputMaybe<Scalars['String']['input']>;
  /** The included storage object */
  storageObject: ApolloStorageObjectInput;
};

export type ApolloInternalAccountInviteeInput = {
  /** The account email address. */
  email: Scalars['String']['input'];
  /** The first name of the account user. */
  firstName: Scalars['ID']['input'];
  /** The last name of the account user. */
  lastName: Scalars['ID']['input'];
  /** Phone number in E.164 format. */
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type ApolloItemDeleted = {
  id: Scalars['ID']['output'];
  typename: Scalars['String']['output'];
};

/** Media Library object returned from the API */
export type ApolloMedia = {
  /** Accessibility of the media library object by students */
  accessibleByStudents?: Maybe<Scalars['Boolean']['output']>;
  /** Accessibility of the media library object by teachers */
  accessibleByTeachers?: Maybe<Scalars['Boolean']['output']>;
  /** The date/time that this document was created. */
  createdAt: Scalars['Float']['output'];
  /** Description of the media library object */
  description?: Maybe<Scalars['String']['output']>;
  fullSizePoster?: Maybe<ApolloStorageObject>;
  /** Unique identifier of the media library object */
  id: Scalars['ID']['output'];
  /** Include in student professional development library */
  includeInStudentLibrary?: Maybe<Scalars['Boolean']['output']>;
  /** Include in teacher professional development library */
  includeInTeacherPdLibrary?: Maybe<Scalars['Boolean']['output']>;
  includedObject: ApolloStorageObject;
  includedPoster?: Maybe<ApolloStorageObject>;
  includedVttObject?: Maybe<ApolloStorageObject>;
  programThumbnailPoster?: Maybe<ApolloStorageObject>;
  thumbnailPoster?: Maybe<ApolloStorageObject>;
  /** Title of the media library object */
  title: Scalars['String']['output'];
  /** The last date/time this document was updated. */
  updatedAt: Scalars['Float']['output'];
};

/** The input for a media library object */
export type ApolloMediaInput = {
  /** Accessibility of the media library object by students */
  accessibleByStudents?: InputMaybe<Scalars['Boolean']['input']>;
  /** Accessibility of the media library object by teachers */
  accessibleByTeachers?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description of the media library object */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The optional fullSize poster image for detail views associated with the media library video object */
  fullSizePoster?: InputMaybe<ApolloStorageObjectInput>;
  /** The id of the media library object if it exists */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Include in student professional development library */
  includeInStudentLibrary?: InputMaybe<Scalars['Boolean']['input']>;
  /** Include in teacher professional development library */
  includeInTeacherPdLibrary?: InputMaybe<Scalars['Boolean']['input']>;
  /** The storage object associated with the media library object */
  includedObject?: InputMaybe<ApolloStorageObjectInput>;
  /** The optional original poster image associated with the media library video object */
  includedPoster?: InputMaybe<ApolloStorageObjectInput>;
  /** The storage object associated with the media library video objects VTT file */
  includedVttObject?: InputMaybe<ApolloStorageObjectInput>;
  /** The optional thumbnail poster image for program list views associated with the media library video object */
  programThumbnailPoster?: InputMaybe<ApolloStorageObjectInput>;
  /** The optional thumbnail poster image for library list views associated with the media library video object */
  thumbnailPoster?: InputMaybe<ApolloStorageObjectInput>;
  /** Title of the media library object */
  title: Scalars['String']['input'];
};

export type ApolloMutation = {
  addFilesToResource: ApolloResource;
  adminUpdateInternalProfile: ApolloAccount;
  adminUpdateSchoolProfile: ApolloAccount;
  beginNcesSchoolImport: Scalars['Boolean']['output'];
  createAssessment: ApolloAssessmentKey;
  createAssessmentQuestion: ApolloAssessmentQuestionKey;
  createClassAssessmentAssignment: ApolloClassAssessmentAssignment;
  createMediaResource: ApolloMedia;
  createOwnClassMiscellaneousAssignment: ApolloClassMiscellaneousAssignment;
  createPlannerBlock: ApolloPlannerBlock;
  createPlannerNote: ApolloPlannerNote;
  createPlannerTopic: ApolloPlannerTopic;
  createQuestionGroup: ApolloQuestionGroupKey;
  createResource: ApolloResource;
  createSchoolClass: ApolloSchoolClass;
  createSchoolCustomer: ApolloSchoolCustomer;
  deleteAssessmentQuestion: ApolloAssessmentQuestionKey;
  deleteClassAssessmentAssignment: ApolloItemDeleted;
  deleteFilesFromResource: ApolloResource;
  deleteMediaResource: ApolloItemDeleted;
  deletePlannerBlock: ApolloPlannerBlock;
  deletePlannerNote: ApolloPlannerNote;
  deletePlannerTopic: ApolloPlannerTopic;
  deleteQuestionGroup: ApolloQuestionGroupKey;
  deleteResource: ApolloResource;
  deleteSchoolClass: ApolloSchoolClass;
  deleteSchoolCustomer: ApolloItemDeleted;
  enrollSchoolClassStudent: ApolloSchoolClass;
  indexResource: ApolloResource;
  inviteInternalAccount: ApolloAccount;
  inviteSchoolAccount: ApolloAccount;
  inviteStudentAccount: ApolloAccount;
  registerAccount: ApolloAccount;
  reindexAllResources: Scalars['Boolean']['output'];
  removeStudentFromClass: ApolloSchoolClass;
  resendInvite: Scalars['Boolean']['output'];
  setAccountPassword: ApolloAccount;
  startLoggedInStudentAssessmentAssignment: ApolloStudentAssignment;
  startLoggedInStudentMiscellaneousAssignment: ApolloStudentAssignment;
  submitAssessmentAnswer: ApolloStudentAssessmentAnswer;
  submitLoggedInStudentAssignment: ApolloStudentAssignment;
  submitStudentAssessmentAssignment: ApolloStudentAssignment;
  updateAccountAccess: ApolloAccount;
  updateAssessment: ApolloAssessmentKey;
  updateAssessmentQuestion: ApolloAssessmentQuestionKey;
  updateClassAssessmentAssignment: ApolloClassAssessmentAssignment;
  updateDashboardConfiguration: ApolloDashboardConfiguration;
  updateLegalDocumentTimestamps: ApolloAccount;
  updateMediaResource: ApolloMedia;
  updateOwnClassMiscellaneousAssignment: ApolloClassMiscellaneousAssignment;
  updatePlannerBlock: ApolloPlannerBlock;
  updatePlannerNote: ApolloPlannerNote;
  updatePlannerTopic: ApolloPlannerTopic;
  updateQuestionGroup: ApolloQuestionGroupKey;
  updateResource: ApolloResource;
  updateSchoolClass: ApolloSchoolClass;
  updateSchoolCustomer: ApolloSchoolCustomer;
  updateSchoolStudentData: ApolloAccount;
  updateSchoolUserData: ApolloAccount;
  /** Upload students in bulk to a single class. CSV contents should have the following headers: "First Name", "Last Name", "Email". The order of the columns should not matter. Row errors are reported as well so a teacher can fix them. */
  uploadSchoolClassStudents: ApolloStudentCsvUploadResult;
  userUpdateAccountEmail: ApolloAccount;
  userUpdateAccountProfile: ApolloAccount;
};


export type ApolloMutationAddFilesToResourceArgs = {
  input: ApolloResourceFilesInput;
};


export type ApolloMutationAdminUpdateInternalProfileArgs = {
  changes: ApolloAccountProfileAdminChangesInput;
  id: Scalars['ID']['input'];
};


export type ApolloMutationAdminUpdateSchoolProfileArgs = {
  changes: ApolloSchoolProfileAdminChangesInput;
  id: Scalars['ID']['input'];
};


export type ApolloMutationCreateAssessmentArgs = {
  assessment: ApolloCreateAssessmentKeyInput;
};


export type ApolloMutationCreateAssessmentQuestionArgs = {
  assessmentQuestion: ApolloCreateAssessmentQuestionKeyInput;
};


export type ApolloMutationCreateClassAssessmentAssignmentArgs = {
  input: ApolloCreateClassAssessmentAssignmentInput;
};


export type ApolloMutationCreateMediaResourceArgs = {
  input: ApolloMediaInput;
};


export type ApolloMutationCreateOwnClassMiscellaneousAssignmentArgs = {
  input: ApolloCreateClassMiscellaneousAssignmentInput;
};


export type ApolloMutationCreatePlannerBlockArgs = {
  plannerBlock: ApolloCreatePlannerBlockInput;
};


export type ApolloMutationCreatePlannerNoteArgs = {
  input: ApolloCreatePlannerNoteInput;
};


export type ApolloMutationCreatePlannerTopicArgs = {
  plannerTopic: ApolloCreatePlannerTopicInput;
};


export type ApolloMutationCreateQuestionGroupArgs = {
  questionGroup: ApolloCreateQuestionGroupKeyInput;
};


export type ApolloMutationCreateResourceArgs = {
  input: ApolloResourceInput;
};


export type ApolloMutationCreateSchoolClassArgs = {
  input: ApolloCreateSchoolClassInput;
};


export type ApolloMutationCreateSchoolCustomerArgs = {
  input: ApolloNewSchoolCustomerInput;
};


export type ApolloMutationDeleteAssessmentQuestionArgs = {
  assessmentQuestionId: Scalars['ID']['input'];
};


export type ApolloMutationDeleteClassAssessmentAssignmentArgs = {
  classAssessmentAssignmentId: Scalars['ID']['input'];
};


export type ApolloMutationDeleteFilesFromResourceArgs = {
  resourceId: Scalars['String']['input'];
};


export type ApolloMutationDeleteMediaResourceArgs = {
  id: Scalars['ID']['input'];
};


export type ApolloMutationDeletePlannerBlockArgs = {
  plannerBlockId: Scalars['String']['input'];
};


export type ApolloMutationDeletePlannerNoteArgs = {
  plannerNoteId: Scalars['String']['input'];
};


export type ApolloMutationDeletePlannerTopicArgs = {
  plannerTopicId: Scalars['String']['input'];
};


export type ApolloMutationDeleteQuestionGroupArgs = {
  questionGroupId: Scalars['ID']['input'];
};


export type ApolloMutationDeleteResourceArgs = {
  resourceId: Scalars['String']['input'];
};


export type ApolloMutationDeleteSchoolClassArgs = {
  schoolClassId: Scalars['ID']['input'];
};


export type ApolloMutationDeleteSchoolCustomerArgs = {
  id: Scalars['ID']['input'];
};


export type ApolloMutationEnrollSchoolClassStudentArgs = {
  schoolClassId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
};


export type ApolloMutationIndexResourceArgs = {
  resourceId: Scalars['String']['input'];
};


export type ApolloMutationInviteInternalAccountArgs = {
  invitee: ApolloInternalAccountInviteeInput;
};


export type ApolloMutationInviteSchoolAccountArgs = {
  invitee: ApolloSchoolAccountInviteeInput;
};


export type ApolloMutationInviteStudentAccountArgs = {
  invitee: ApolloStudentAccountInviteeInput;
};


export type ApolloMutationRegisterAccountArgs = {
  registration: ApolloAccountRegistrationInput;
};


export type ApolloMutationRemoveStudentFromClassArgs = {
  input: ApolloRemoveStudentFromClassGqlInput;
};


export type ApolloMutationResendInviteArgs = {
  id: Scalars['ID']['input'];
};


export type ApolloMutationSetAccountPasswordArgs = {
  accountId: Scalars['ID']['input'];
  password: Scalars['String']['input'];
};


export type ApolloMutationStartLoggedInStudentAssessmentAssignmentArgs = {
  studentAssignmentId: Scalars['ID']['input'];
};


export type ApolloMutationStartLoggedInStudentMiscellaneousAssignmentArgs = {
  studentAssignmentId: Scalars['ID']['input'];
};


export type ApolloMutationSubmitAssessmentAnswerArgs = {
  input: ApolloStudentAssessmentAnswerInput;
};


export type ApolloMutationSubmitLoggedInStudentAssignmentArgs = {
  studentAssignmentId: Scalars['ID']['input'];
};


export type ApolloMutationSubmitStudentAssessmentAssignmentArgs = {
  studentAssignmentId: Scalars['ID']['input'];
};


export type ApolloMutationUpdateAccountAccessArgs = {
  canAccess: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};


export type ApolloMutationUpdateAssessmentArgs = {
  assessment: ApolloAssessmentKeyInput;
};


export type ApolloMutationUpdateAssessmentQuestionArgs = {
  assessmentQuestion: ApolloAssessmentQuestionKeyInput;
};


export type ApolloMutationUpdateClassAssessmentAssignmentArgs = {
  input: ApolloUpdateClassAssessmentAssignmentInput;
};


export type ApolloMutationUpdateDashboardConfigurationArgs = {
  input: ApolloDashboardConfigurationInput;
};


export type ApolloMutationUpdateMediaResourceArgs = {
  input: ApolloMediaInput;
};


export type ApolloMutationUpdateOwnClassMiscellaneousAssignmentArgs = {
  input: ApolloUpdateClassMiscellaneousAssignmentInput;
};


export type ApolloMutationUpdatePlannerBlockArgs = {
  plannerBlock: ApolloPlannerBlockInput;
};


export type ApolloMutationUpdatePlannerNoteArgs = {
  input: ApolloUpdatePlannerNoteInput;
};


export type ApolloMutationUpdatePlannerTopicArgs = {
  plannerTopic: ApolloPlannerTopicInput;
};


export type ApolloMutationUpdateQuestionGroupArgs = {
  questionGroup: ApolloQuestionGroupKeyInput;
};


export type ApolloMutationUpdateResourceArgs = {
  input: ApolloResourceInput;
};


export type ApolloMutationUpdateSchoolClassArgs = {
  input: ApolloUpdateSchoolClassInput;
};


export type ApolloMutationUpdateSchoolCustomerArgs = {
  id: Scalars['ID']['input'];
  update: ApolloSchoolCustomerUpdateInput;
};


export type ApolloMutationUpdateSchoolStudentDataArgs = {
  updatedStudent: ApolloUpdateStudentDataGqlInput;
};


export type ApolloMutationUpdateSchoolUserDataArgs = {
  update: ApolloSchoolUserDataUpdate;
};


export type ApolloMutationUploadSchoolClassStudentsArgs = {
  classId: Scalars['ID']['input'];
  csvContents: Scalars['String']['input'];
  schoolCustomerId: Scalars['ID']['input'];
};


export type ApolloMutationUserUpdateAccountProfileArgs = {
  id: Scalars['ID']['input'];
  profile: ApolloAccountProfileChangesInput;
  teachingInfo?: InputMaybe<ApolloTeachingInfoInput>;
};

/** The named media library */
export const ApolloNamedMediaLibrary = {
  Other: 'OTHER',
  Student: 'STUDENT',
  Teacher: 'TEACHER'
} as const;

export type ApolloNamedMediaLibrary = typeof ApolloNamedMediaLibrary[keyof typeof ApolloNamedMediaLibrary];
export type ApolloNewSchoolCustomerInput = {
  importedSchoolId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ApolloOmitObjectType = {
  /** If true, the user may provide their own unlisted response. */
  allowOther?: InputMaybe<Scalars['Boolean']['input']>;
  /** The answers to choose from for this question. */
  answers: Array<ApolloSurveyAnswerChoiceInput>;
  /** The format of the survey question. */
  format: ApolloSurveyQuestionFormat;
  /** Short text to clarify anything about the question. */
  hint?: InputMaybe<Scalars['String']['input']>;
  /** If provided, the maximum number of answers that can be selected. */
  maxSelect?: InputMaybe<Scalars['Int']['input']>;
  /** If provided, the minimum number of answers that must be selected in order for the question to be considered answered. */
  minSelect?: InputMaybe<Scalars['Int']['input']>;
};

/** A paginated list of school users. */
export type ApolloPaginatedSchoolUsersSearchResult = {
  /** The list of school users. */
  items: Array<ApolloSchoolAccount>;
  /** The index of the page in the result set. */
  pageIndex: Scalars['Int']['output'];
  /** The size of the page in the result set. */
  pageSize: Scalars['Int']['output'];
  /** The original search term. */
  searchTerm: Scalars['String']['output'];
  /** The total number of items in the result set. */
  totalCount: Scalars['Int']['output'];
};

/** The input to a paginated search query. */
export type ApolloPaginatedSearchCriteria = {
  /** The index of the page in the result set */
  pageIndex: Scalars['Int']['input'];
  /** The desired size of the page in the result set */
  pageSize: Scalars['Int']['input'];
  /** The search term. An empty string will not filter the result set. */
  searchTerm: Scalars['String']['input'];
};

/** Pagination options for a search */
export type ApolloPaginationOptionsInput = {
  /** The index of the page in the result set */
  pageIndex: Scalars['Int']['input'];
  /** The desired size of the page in the result set */
  pageSize: Scalars['Int']['input'];
};

export type ApolloPartialSchool = {
  gradesOffered?: Maybe<Array<ApolloGradeLevel>>;
  id?: Maybe<Scalars['ID']['output']>;
  isCharter?: Maybe<Scalars['Boolean']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  location?: Maybe<ApolloAddress>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  schoolType?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type ApolloPlainSurveyQuestion = {
  /** The options for the survey question. */
  options: ApolloSurveyQuestionOptions;
  /** The text of the survey question. */
  text: Scalars['String']['output'];
};

export type ApolloPlainSurveyResponse = {
  answer?: Maybe<ApolloSurveyAnswer>;
  answers?: Maybe<Array<ApolloSurveyAnswer>>;
  format: ApolloSurveyQuestionFormat;
};

export type ApolloPlainSurveyResult = {
  question: ApolloPlainSurveyQuestion;
  response: ApolloPlainSurveyResponse;
};

/** A block on a scope and planner */
export type ApolloPlannerBlock = {
  /** The color associated with this planner block */
  color: Scalars['String']['output'];
  /** The description of the planner block */
  description?: Maybe<Scalars['String']['output']>;
  /** The start of the last day of this block in milliseconds since the epoch */
  endingSchoolDay: Scalars['Float']['output'];
  /** The id of the planner block generated by the front end */
  id: Scalars['ID']['output'];
  /** The id of the planner topic this block belongs to */
  plannerTopicId: Scalars['ID']['output'];
  /** The id of the resource tied to this block */
  resourceId?: Maybe<Scalars['String']['output']>;
  /** The id of the school class this block belongs to */
  schoolClassId: Scalars['ID']['output'];
  /** The beginning of the first day of this block in milliseconds since the epoch */
  startingSchoolDay: Scalars['Float']['output'];
  /** The title of the planner block */
  title: Scalars['String']['output'];
};

/** A block on a scope and planner */
export type ApolloPlannerBlockInput = {
  /** The color associated with this planner block */
  color: Scalars['String']['input'];
  /** The description of the planner block */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The start of the last day of this block in milliseconds since the epoch */
  endingSchoolDay: Scalars['Float']['input'];
  /** The id of the planner block generated by the front end */
  id: Scalars['ID']['input'];
  /** The id of the planner topic this block belongs to */
  plannerTopicId: Scalars['ID']['input'];
  /** The id of the resource tied to this block */
  resourceId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the school class this block belongs to */
  schoolClassId: Scalars['ID']['input'];
  /** The beginning of the first day of this block in milliseconds since the epoch */
  startingSchoolDay: Scalars['Float']['input'];
  /** The title of the planner block */
  title: Scalars['String']['input'];
};

/** A note on the planner */
export type ApolloPlannerNote = {
  /** The main body of the lesson plan */
  body?: Maybe<Scalars['String']['output']>;
  /** The id of the class day plan */
  id: Scalars['ID']['output'];
  /** The id of the sequence block associated with this class day plan */
  plannerBlockId?: Maybe<Scalars['ID']['output']>;
  /** The offset in days from the beginning of the sequence block */
  plannerBlockOffset?: Maybe<Scalars['Float']['output']>;
  /** The id of the class */
  schoolClassId: Scalars['ID']['output'];
};

/** A topic on a scope and sequence */
export type ApolloPlannerTopic = {
  /** The id of this planner topic */
  id: Scalars['ID']['output'];
  /** The id of the school class this topic belongs to */
  schoolClassId: Scalars['ID']['output'];
  /** The order of the planner topic in the sequence */
  sortOrder: Scalars['Int']['output'];
  /** The title of the planner topic */
  title: Scalars['String']['output'];
};

/** A topic on a scope and sequence */
export type ApolloPlannerTopicInput = {
  /** The id of this planner topic */
  id: Scalars['ID']['input'];
  /** The id of the school class this topic belongs to */
  schoolClassId: Scalars['ID']['input'];
  /** The order of the planner topic in the sequence */
  sortOrder: Scalars['Int']['input'];
  /** The title of the planner topic */
  title: Scalars['String']['input'];
};

export type ApolloQuery = {
  accountByEmail?: Maybe<ApolloAccount>;
  adminSchoolCustomer: ApolloSchoolCustomer;
  allAssessments: Array<ApolloAssessment>;
  assessmentForInternalUser: ApolloAssessmentKey;
  assessmentsForGradeLevel: Array<ApolloAssessment>;
  authenticatedAccount: ApolloAccount;
  canRegister: Scalars['Boolean']['output'];
  classAssessmentAssignments: Array<ApolloClassAssessmentAssignment>;
  dashboardConfiguration: ApolloDashboardConfiguration;
  getAllSkills: Array<ApolloSkill>;
  getAnyClassAssignment: ApolloAnyClassAssignment;
  getLoggedInStudentAssignment: ApolloStudentAssignment;
  getMediaResource: ApolloMedia;
  getMediaResources: Array<ApolloMedia>;
  getMediaResourcesByIds: Array<ApolloMedia>;
  getNcesSchoolUploadUrl: Scalars['String']['output'];
  getResource: ApolloResource;
  getSignedDownloadUrl: ApolloSignedUrl;
  getSignedUploadUrl: ApolloSignedUrl;
  getStudentAssessmentResultsForClassAssignment: Array<ApolloStudentAssessmentResult>;
  getStudentAssignments: Array<ApolloStudentAssignment>;
  getStudentAssignmentsByClassId: Array<ApolloStudentAssignment>;
  importedSchool?: Maybe<ApolloImportedSchool>;
  importedSchools: Array<ApolloImportedSchool>;
  importedSchoolsCount: Scalars['Int']['output'];
  internalAccounts: Array<ApolloAccount>;
  loggedInAssessmentForStudentAssignment: ApolloAssessment;
  loggedInStudentAssessmentAnswers: Array<ApolloStudentAssessmentAnswer>;
  loginAs: Scalars['Boolean']['output'];
  ownClassMiscellaneousAssignments: Array<ApolloClassMiscellaneousAssignment>;
  randomStudentPasswords: Array<Scalars['String']['output']>;
  schoolAccounts: ApolloPaginatedSchoolUsersSearchResult;
  schoolClass: ApolloSchoolClass;
  schoolClassesByTeacherId: Array<ApolloSchoolClass>;
  schoolCustomer?: Maybe<ApolloSchoolCustomer>;
  schoolCustomers: Array<ApolloSchoolCustomer>;
  schoolUserDetails?: Maybe<ApolloSchoolUserDetails>;
  searchResources: ApolloResourceSearchResults;
  skillGroups: Array<ApolloSkillGroup>;
  studentAccessibleSchoolClasses: Array<ApolloStudentAccessibleSchoolClass>;
  studentAccount?: Maybe<ApolloAccount>;
  studentLoginEmail?: Maybe<Scalars['String']['output']>;
  studentSchool?: Maybe<ApolloSchoolCustomer>;
  studentSchools: Array<ApolloSchoolCustomer>;
  /** Get all survey questions sorted by order. */
  survey: Array<ApolloSurveyQuestion>;
  teacherSchoolClasses: Array<ApolloSchoolClass>;
  /** Gets the school and coworker accounts for the currently logged in user. */
  userSchool?: Maybe<ApolloSchoolCustomer>;
};


export type ApolloQueryAccountByEmailArgs = {
  email: Scalars['String']['input'];
};


export type ApolloQueryAdminSchoolCustomerArgs = {
  id: Scalars['ID']['input'];
};


export type ApolloQueryAssessmentForInternalUserArgs = {
  assessmentId: Scalars['ID']['input'];
};


export type ApolloQueryAssessmentsForGradeLevelArgs = {
  gradeLevel: ApolloGradeLevel;
};


export type ApolloQueryCanRegisterArgs = {
  email: Scalars['String']['input'];
};


export type ApolloQueryClassAssessmentAssignmentsArgs = {
  classId: Scalars['ID']['input'];
};


export type ApolloQueryDashboardConfigurationArgs = {
  applicationType: ApolloUserApplication;
};


export type ApolloQueryGetAnyClassAssignmentArgs = {
  assignmentId: Scalars['ID']['input'];
};


export type ApolloQueryGetLoggedInStudentAssignmentArgs = {
  studentAssignmentId: Scalars['ID']['input'];
};


export type ApolloQueryGetMediaResourceArgs = {
  id: Scalars['String']['input'];
};


export type ApolloQueryGetMediaResourcesArgs = {
  library?: InputMaybe<ApolloNamedMediaLibrary>;
};


export type ApolloQueryGetMediaResourcesByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type ApolloQueryGetResourceArgs = {
  resourceId: Scalars['String']['input'];
};


export type ApolloQueryGetSignedDownloadUrlArgs = {
  input: ApolloSignedUrlInput;
};


export type ApolloQueryGetSignedUploadUrlArgs = {
  input: ApolloSignedUrlInput;
};


export type ApolloQueryGetStudentAssessmentResultsForClassAssignmentArgs = {
  classAssignmentId: Scalars['ID']['input'];
};


export type ApolloQueryGetStudentAssignmentsByClassIdArgs = {
  input: ApolloGetStudentAssignmentsByClassIdGqlInput;
};


export type ApolloQueryImportedSchoolArgs = {
  schoolId: Scalars['String']['input'];
};


export type ApolloQueryImportedSchoolsArgs = {
  searchCriteria: ApolloSchoolSearchCriteria;
  skipExistingCustomers?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ApolloQueryLoggedInAssessmentForStudentAssignmentArgs = {
  studentAssignmentId: Scalars['ID']['input'];
};


export type ApolloQueryLoggedInStudentAssessmentAnswersArgs = {
  studentAssignmentId: Scalars['ID']['input'];
};


export type ApolloQueryLoginAsArgs = {
  email: Scalars['String']['input'];
};


export type ApolloQueryOwnClassMiscellaneousAssignmentsArgs = {
  classId: Scalars['ID']['input'];
};


export type ApolloQueryRandomStudentPasswordsArgs = {
  count: Scalars['Int']['input'];
};


export type ApolloQuerySchoolAccountsArgs = {
  searchCriteria: ApolloPaginatedSearchCriteria;
};


export type ApolloQuerySchoolClassArgs = {
  schoolClassId: Scalars['ID']['input'];
};


export type ApolloQuerySchoolClassesByTeacherIdArgs = {
  id: Scalars['ID']['input'];
};


export type ApolloQuerySchoolCustomerArgs = {
  id: Scalars['ID']['input'];
};


export type ApolloQuerySchoolCustomersArgs = {
  searchCriteria: ApolloSchoolSearchCriteria;
};


export type ApolloQuerySchoolUserDetailsArgs = {
  id: Scalars['ID']['input'];
};


export type ApolloQuerySearchResourcesArgs = {
  input: ApolloResourceSearchInput;
};


export type ApolloQueryStudentAccountArgs = {
  schoolCustomerId: Scalars['ID']['input'];
  studentId: Scalars['String']['input'];
};


export type ApolloQueryStudentLoginEmailArgs = {
  schoolCustomerId: Scalars['ID']['input'];
  studentId: Scalars['String']['input'];
};


export type ApolloQueryStudentSchoolArgs = {
  id: Scalars['ID']['input'];
};


export type ApolloQueryStudentSchoolsArgs = {
  searchCriteria: ApolloSchoolSearchCriteria;
};


export type ApolloQuerySurveyArgs = {
  surveyTitle: Scalars['String']['input'];
};


export type ApolloQueryTeacherSchoolClassesArgs = {
  input?: InputMaybe<ApolloTeacherSchoolClassesFilterInput>;
};

/** A question assessment component */
export type ApolloQuestionAssessmentComponent = {
  question: ApolloAssessmentQuestion;
  /** The id of the question. */
  questionId: Scalars['ID']['output'];
  /** The type of assessment component. */
  type: ApolloAssessmentComponentType;
};

/** A question assessment component with correct answers */
export type ApolloQuestionAssessmentComponentKey = {
  question: ApolloAssessmentQuestionKey;
  /** The id of the question. */
  questionId: Scalars['ID']['output'];
  /** The type of assessment component. */
  type: ApolloAssessmentComponentType;
};

/** The format of a question on an assessment. */
export const ApolloQuestionFormat = {
  Essay: 'ESSAY',
  LongAnswer: 'LONG_ANSWER',
  MultipleChoice: 'MULTIPLE_CHOICE',
  ShortAnswer: 'SHORT_ANSWER',
  TrueFalse: 'TRUE_FALSE'
} as const;

export type ApolloQuestionFormat = typeof ApolloQuestionFormat[keyof typeof ApolloQuestionFormat];
/** A group of questions on an assessment. */
export type ApolloQuestionGroup = {
  /** Optional content for the group of questions. */
  content?: Maybe<Scalars['String']['output']>;
  /** ID of the group of questions. */
  id: Scalars['ID']['output'];
  /** Optional instructions for the group of questions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** IDs of the questions in the group. */
  questionIds: Array<Scalars['ID']['output']>;
  /** Questions in the group. */
  questions?: Maybe<Array<ApolloAssessmentQuestion>>;
  /** Title of the group of questions. */
  title?: Maybe<Scalars['String']['output']>;
};

/** A question group assessment component */
export type ApolloQuestionGroupAssessmentComponent = {
  questionGroup: ApolloQuestionGroup;
  /** The id of the question group. */
  questionGroupId: Scalars['ID']['output'];
  /** The type of assessment component. */
  type: ApolloAssessmentComponentType;
};

/** A question group assessment component with correct answers */
export type ApolloQuestionGroupAssessmentComponentKey = {
  questionGroup: ApolloQuestionGroupKey;
  /** The id of the question group. */
  questionGroupId: Scalars['ID']['output'];
  /** The type of assessment component. */
  type: ApolloAssessmentComponentType;
};

/** A group of questions on an assessment with correct answers and explanations. */
export type ApolloQuestionGroupKey = {
  /** Optional content for the group of questions. */
  content?: Maybe<Scalars['String']['output']>;
  /** ID of the group of questions. */
  id: Scalars['ID']['output'];
  /** Optional instructions for the group of questions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** IDs of the questions in the group. */
  questionIds: Array<Scalars['ID']['output']>;
  /** Questions in the group with correct answers and explanations. */
  questions?: Maybe<Array<ApolloAssessmentQuestionKey>>;
  /** Title of the group of questions. */
  title?: Maybe<Scalars['String']['output']>;
};

/** Input for updating a question group key */
export type ApolloQuestionGroupKeyInput = {
  /** Optional content for the group of questions. */
  content?: InputMaybe<Scalars['String']['input']>;
  /** ID of the group of questions. */
  id: Scalars['ID']['input'];
  /** Optional instructions for the group of questions. */
  instructions?: InputMaybe<Scalars['String']['input']>;
  /** IDs of the questions in the group. */
  questionIds: Array<Scalars['ID']['input']>;
  /** Title of the group of questions. */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Input to remove a student from a class. */
export type ApolloRemoveStudentFromClassGqlInput = {
  /** The id of the class to remove sthe student from. */
  classId: Scalars['ID']['input'];
  /** The student ID to remove from the class */
  studentId: Scalars['ID']['input'];
};

/** A resource */
export type ApolloResource = {
  description: Scalars['String']['output'];
  fullSizeImage?: Maybe<ApolloStorageObject>;
  /** The intended grade levels for this resource */
  gradeLevels: Array<ApolloGradeLevel>;
  /** Whether or not to include this resource in search results */
  hideInSearchResults: Scalars['Boolean']['output'];
  /** The id of the resource */
  id: Scalars['ID']['output'];
  /** The objects (files) included in this resource.  The first object in this array is the zip file that contains all other objects */
  includedObjects: Array<ApolloIncludedObject>;
  /** Additional resource keywords */
  keywords?: Maybe<Scalars['String']['output']>;
  /** High end of how much time this resource takes up */
  maximumHoursEstimate?: Maybe<Scalars['Float']['output']>;
  /** Low end of how much time this resource takes up */
  minimumHoursEstimate?: Maybe<Scalars['Float']['output']>;
  originalImage?: Maybe<ApolloStorageObject>;
  /** Popularity used to determine default sort order */
  popularity?: Maybe<Scalars['Float']['output']>;
  /** External resources referenced by this resource */
  references?: Maybe<Scalars['String']['output']>;
  /** Ids of skills addressed by this resource */
  skillIds: Array<Scalars['ID']['output']>;
  thumbnailImage?: Maybe<ApolloStorageObject>;
  /** The title of the resource */
  title: Scalars['String']['output'];
  /** Any types associated with the resource */
  types: Array<ApolloResourceType>;
};

/** Payload sent by a client to add files to a resource */
export type ApolloResourceFilesInput = {
  /** The files to add to the resource */
  includedObjects: Array<ApolloIncludedObjectInput>;
  /** The id of the resource */
  resourceId: Scalars['ID']['input'];
};

/** A piece of resource from the creation form */
export type ApolloResourceInput = {
  description: Scalars['String']['input'];
  /** The large size image for this resource */
  fullSizeImage?: InputMaybe<ApolloStorageObjectInput>;
  /** The intended grade levels for this resource */
  gradeLevels: Array<ApolloGradeLevel>;
  /** Whether or not to include this resource in search results */
  hideInSearchResults: Scalars['Boolean']['input'];
  /** The id of the resource unit if the resource unit already exists */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Additional resource keywords */
  keywords?: InputMaybe<Scalars['String']['input']>;
  /** High end of how much time this resource takes up */
  maximumHoursEstimate?: InputMaybe<Scalars['Float']['input']>;
  /** Low end of how much time this resource takes up */
  minimumHoursEstimate?: InputMaybe<Scalars['Float']['input']>;
  /** The original image for this resource */
  originalImage?: InputMaybe<ApolloStorageObjectInput>;
  /** Popularity used to determine default sort order */
  popularity?: InputMaybe<Scalars['Float']['input']>;
  /** External resources referenced by this resource */
  references?: InputMaybe<Scalars['String']['input']>;
  /** Ids of skills addressed by this resource */
  skillIds: Array<Scalars['ID']['input']>;
  /** The thumbnail image for this resource */
  thumbnailImage?: InputMaybe<ApolloStorageObjectInput>;
  /** The title of the resource */
  title: Scalars['String']['input'];
  /** Any types associated with the resource */
  types: Array<ApolloResourceType>;
};

/** Input from users searching for resources */
export type ApolloResourceSearchInput = {
  appliedFacetFilters: Array<ApolloFacetFilterSetInput>;
  /** Whether or not to include hidden resources in search results */
  includeHiddenResources: Scalars['Boolean']['input'];
  paginationOptions: ApolloPaginationOptionsInput;
  /** The search phrase to use when searching for resources */
  searchPhrase: Scalars['String']['input'];
};

/** A resource as returned in search results */
export type ApolloResourceSearchResult = {
  description: Scalars['String']['output'];
  /** The full size image for this resource */
  fullSizeImage?: Maybe<ApolloStorageObject>;
  /** The intended grade levels for this resource */
  gradeLevels: Array<ApolloGradeLevel>;
  /** Whether or not to include this resource in search results */
  hideInSearchResults: Scalars['Boolean']['output'];
  /** The id of the resource */
  id: Scalars['ID']['output'];
  /** Additional resource keywords */
  keywords?: Maybe<Scalars['String']['output']>;
  /** High end of how much time this resource takes up */
  maximumHoursEstimate?: Maybe<Scalars['Float']['output']>;
  /** Low end of how much time this resource takes up */
  minimumHoursEstimate?: Maybe<Scalars['Float']['output']>;
  /** The original image for this resource */
  originalImage?: Maybe<ApolloStorageObject>;
  /** Popularity used to determine default sort order */
  popularity?: Maybe<Scalars['Float']['output']>;
  /** External resources referenced by this resource */
  references?: Maybe<Scalars['String']['output']>;
  /** Ids of skills addressed by this resource */
  skillIds: Array<Scalars['ID']['output']>;
  /** The thumbnail image for this resource */
  thumbnailImage?: Maybe<ApolloStorageObject>;
  /** The title of the resource */
  title: Scalars['String']['output'];
  /** Any types associated with the resource */
  types: Array<ApolloResourceType>;
};

/** Results of a search for resources */
export type ApolloResourceSearchResults = {
  /** Sets of available filters applying to these results */
  availableFilters: Array<ApolloFilterSet>;
  /** The total number of resources that matched the search */
  numberOfResults: Scalars['Int']['output'];
  /** The resources that matched the search, respecting pagination */
  resources: Array<ApolloResourceSearchResult>;
};

/** A type of resource */
export const ApolloResourceType = {
  General: 'GENERAL',
  SkillBasedPassage: 'SKILL_BASED_PASSAGE'
} as const;

export type ApolloResourceType = typeof ApolloResourceType[keyof typeof ApolloResourceType];
/** Details about a school user or teacher account */
export type ApolloSchoolAccount = {
  /** Whether account is active or not. */
  active: Scalars['Boolean']['output'];
  /** The date/time that this document was created. */
  createdAt: Scalars['Float']['output'];
  /** The account email address. */
  email: Scalars['String']['output'];
  /** The first name of the account user. */
  firstName: Scalars['String']['output'];
  /** Full name which is just a concatenation of the first and last name. */
  fullName: Scalars['String']['output'];
  gradesTaught: Array<ApolloGradeLevel>;
  id: Scalars['String']['output'];
  isIndependent: Scalars['Boolean']['output'];
  /** The last name of the account user. */
  lastName: Scalars['String']['output'];
  nClasses: Scalars['Int']['output'];
  nStudents: Scalars['Int']['output'];
  /** Whether or not the user invitation is still pending. */
  pending: Scalars['Boolean']['output'];
  school?: Maybe<ApolloPartialSchool>;
};

export type ApolloSchoolAccountInviteeInput = {
  additionalSchoolUserData: ApolloSchoolAccountInviteeUserDataInput;
  /** The account email address. */
  email: Scalars['String']['input'];
  /** The first name of the account user. */
  firstName: Scalars['ID']['input'];
  /** The last name of the account user. */
  lastName: Scalars['ID']['input'];
  /** Phone number in E.164 format. */
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type ApolloSchoolAccountInviteeUserDataInput = {
  /** The role or roles which define what a user can do in the school app. */
  roles?: InputMaybe<Array<ApolloSchoolAccountRole>>;
  /** The id of a school customer with whom this school account is associated. */
  schoolCustomerId: Scalars['ID']['input'];
};

/** The plan of the school account */
export const ApolloSchoolAccountPlan = {
  Basic: 'BASIC',
  FullAccess: 'FULL_ACCESS'
} as const;

export type ApolloSchoolAccountPlan = typeof ApolloSchoolAccountPlan[keyof typeof ApolloSchoolAccountPlan];
/** The role describes the actions a user can take in the school app. */
export const ApolloSchoolAccountRole = {
  Administrator: 'ADMINISTRATOR',
  Teacher: 'TEACHER'
} as const;

export type ApolloSchoolAccountRole = typeof ApolloSchoolAccountRole[keyof typeof ApolloSchoolAccountRole];
/** A class a teacher is teaching */
export type ApolloSchoolClass = {
  /** Assignments for this class */
  assignments?: Maybe<Array<ApolloAnyClassAssignment>>;
  /** The color associated with this class */
  color: Scalars['String']['output'];
  /** The beginning of the first day of class in milliseconds since the epoch */
  firstDay: Scalars['Float']['output'];
  /** The grade level of the class */
  gradeLevel: ApolloGradeLevel;
  /** The id of the class */
  id: Scalars['ID']['output'];
  /** The start of the last day of class in milliseconds since the epoch */
  lastDay: Scalars['Float']['output'];
  /** The period of the class */
  period?: Maybe<Scalars['Int']['output']>;
  /** Planner blocks in the sequence for this class */
  plannerBlocks?: Maybe<Array<ApolloPlannerBlock>>;
  /** Planner notes for this class, potentially filtered by date */
  plannerNotes?: Maybe<Array<ApolloPlannerNote>>;
  /** Planner opics in the sequence for this class */
  plannerTopics?: Maybe<Array<ApolloPlannerTopic>>;
  /** The ids of the skills taught in this class */
  skillIds: Array<Scalars['ID']['output']>;
  studentIds: Array<Scalars['ID']['output']>;
  /** Students enrolled in the school class. */
  students: Array<ApolloAccount>;
  /** Id of the teacher for this class */
  teacherId: Scalars['ID']['output'];
  /** The title of the class */
  title: Scalars['String']['output'];
};


/** A class a teacher is teaching */
export type ApolloSchoolClassPlannerNotesArgs = {
  input?: InputMaybe<ApolloTeacherSchoolClassesFilterInput>;
};

export type ApolloSchoolCustomer = {
  /** The accounts which belong to this school. */
  accounts: Array<ApolloAccount>;
  createdAt: Scalars['Float']['output'];
  gradesOffered: Array<ApolloGradeLevel>;
  hubspotCompanyId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  importedSchoolId?: Maybe<Scalars['ID']['output']>;
  isCharter?: Maybe<Scalars['Boolean']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  location?: Maybe<ApolloAddress>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  schoolType?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Float']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export type ApolloSchoolCustomerUpdateInput = {
  gradesOffered: Array<ApolloGradeLevel>;
  hubspotCompanyId?: InputMaybe<Scalars['String']['input']>;
  importedSchoolId?: InputMaybe<Scalars['ID']['input']>;
  isCharter?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<ApolloAddressInput>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  schoolType?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

/** The job title of this school user. */
export const ApolloSchoolJobTitle = {
  Admin: 'ADMIN',
  ClassroomTeacher: 'CLASSROOM_TEACHER',
  HomeschoolEducator: 'HOMESCHOOL_EDUCATOR',
  InstructionalCoach: 'INSTRUCTIONAL_COACH',
  Other: 'OTHER'
} as const;

export type ApolloSchoolJobTitle = typeof ApolloSchoolJobTitle[keyof typeof ApolloSchoolJobTitle];
export type ApolloSchoolProfileAdminChangesInput = {
  /** An image or avatar for identification purposes. */
  avatar?: InputMaybe<Scalars['String']['input']>;
  /** The account email address. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** The first name of the account user. */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The last name of the account user. */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Phone number in E.164 format. */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The role or roles which define what a user can do in the school app. */
  roles: Array<ApolloSchoolAccountRole>;
};

export type ApolloSchoolSearchCriteria = {
  /** Maximum number of items to return. */
  limit: Scalars['Int']['input'];
  /** A string that could match any relevant property on a school customer. */
  searchTerm: Scalars['String']['input'];
  /** Number of items to skip. */
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** Additional data applicable to the school user. */
export type ApolloSchoolUserData = {
  /** The self-reported grades this user teaches. */
  gradesTaught?: Maybe<Array<ApolloGradeLevel>>;
  /** The self-reported job title of this user. */
  jobTitle?: Maybe<ApolloSchoolJobTitle>;
  /** The member id if this school user was signed up automatically by Kajabi. */
  kajabiMemberId?: Maybe<Scalars['Float']['output']>;
  /** The plan which describes the features available to this user. */
  plan: ApolloSchoolAccountPlan;
  /** A calculation of whether the user has completed the registration survey. */
  registrationSurveyCompleted: Scalars['Boolean']['output'];
  /** All historical answers to the registration survey. */
  registrationSurveyResults?: Maybe<Array<ApolloSurveyResult>>;
  /** The role or roles which define what a user can do in the school app. */
  roles: Array<ApolloSchoolAccountRole>;
  /** The city of the school with which an independent school user is affiliated. This is not required if the user is affiliated with an NCES school. */
  schoolCity?: Maybe<Scalars['String']['output']>;
  /** The countrry of the school with which an independent school user is affiliated. This is not required if the user is affiliated with an NCES school. */
  schoolCountry?: Maybe<Scalars['String']['output']>;
  /** The id of a school customer with whom this school account is associated. */
  schoolCustomerId?: Maybe<Scalars['ID']['output']>;
  /** The id of the NCES school with which an independent school user is affiliated. */
  schoolId?: Maybe<Scalars['String']['output']>;
  /** The name of the school with which an independent school user is affiliated. This is not required if the user is affiliated with an NCES school. */
  schoolName?: Maybe<Scalars['String']['output']>;
  /** The state or territory of the school with which an independent school user is affiliated. This is not required if the user is affiliated with an NCES school. */
  schoolState?: Maybe<Scalars['String']['output']>;
  /** The type of school user to which this data refers. */
  userType?: Maybe<ApolloSchoolUserType>;
  /** Wether the user has watched the welcome video already. */
  watchedWelcomeVideo?: Maybe<Scalars['Boolean']['output']>;
};

/** Only the following items can be updated by a school user. */
export type ApolloSchoolUserDataUpdate = {
  /** The self-reported grades this user teaches. */
  gradesTaught?: InputMaybe<Array<ApolloGradeLevel>>;
  /** The self-reported job title of this user. */
  jobTitle?: InputMaybe<ApolloSchoolJobTitle>;
  /** The plan which describes the features available to this user. */
  plan?: InputMaybe<ApolloSchoolAccountPlan>;
  /** The results of filling out the registration survey. */
  registrationSurveyResults?: InputMaybe<Array<ApolloSurveyResultUpdate>>;
  /** The role or roles which define what a user can do in the school app. */
  roles?: InputMaybe<Array<ApolloSchoolAccountRole>>;
  /** The city of the school with which an independent school user is affiliated. This is not required if the user is affiliated with an NCES school. */
  schoolCity?: InputMaybe<Scalars['String']['input']>;
  /** The countrry of the school with which an independent school user is affiliated. This is not required if the user is affiliated with an NCES school. */
  schoolCountry?: InputMaybe<Scalars['String']['input']>;
  /** The id of the NCES school with which an independent school user is affiliated. */
  schoolId?: InputMaybe<Scalars['String']['input']>;
  /** The name of the school with which an independent school user is affiliated. This is not required if the user is affiliated with an NCES school. */
  schoolName?: InputMaybe<Scalars['String']['input']>;
  /** The state or territory of the school with which an independent school user is affiliated. This is not required if the user is affiliated with an NCES school. */
  schoolState?: InputMaybe<Scalars['String']['input']>;
  /** Wether the user has watched the welcome video already. */
  watchedWelcomeVideo?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Even more details about a school user or teacher account */
export type ApolloSchoolUserDetails = {
  /** Whether account is active or not. */
  active: Scalars['Boolean']['output'];
  /** Additional data specific to a school user. */
  additionalSchoolUserData?: Maybe<ApolloSchoolUserData>;
  /** A user supplied image. */
  avatar?: Maybe<Scalars['String']['output']>;
  /** The date/time that this document was created. */
  createdAt: Scalars['Float']['output'];
  /** The account email address. */
  email: Scalars['String']['output'];
  /** The first name of the account user. */
  firstName: Scalars['String']['output'];
  /** Full name which is just a concatenation of the first and last name. */
  fullName: Scalars['String']['output'];
  /** Unique identifier, UUIDv4. */
  id: Scalars['ID']['output'];
  /** The last name of the account user. */
  lastName: Scalars['String']['output'];
  /** Whether or not the user invitation is still pending. */
  pending: Scalars['Boolean']['output'];
  /** Phone number in E.164 format. */
  phone?: Maybe<Scalars['String']['output']>;
  /** The school with which this account is affiliated. */
  school?: Maybe<ApolloPartialSchool>;
  /** The history of status changes to this account, most recent first. */
  statusChangeEvents: Array<ApolloAccountStatusChangeEvent>;
  /** The survey results for this account without all the extra ids and such. */
  surveyResults?: Maybe<Array<ApolloPlainSurveyResult>>;
};

/** The type of school user to which this school account refers */
export const ApolloSchoolUserType = {
  DependentAffiliated: 'DEPENDENT_AFFILIATED',
  IndependentAffiliated: 'INDEPENDENT_AFFILIATED',
  IndependentAffiliatedCustom: 'INDEPENDENT_AFFILIATED_CUSTOM',
  IndependentNonAffiliated: 'INDEPENDENT_NON_AFFILIATED'
} as const;

export type ApolloSchoolUserType = typeof ApolloSchoolUserType[keyof typeof ApolloSchoolUserType];
/** A signed url */
export type ApolloSignedUrl = {
  /** The id of the corresponding storage object document */
  id: Scalars['ID']['output'];
  /** The signed url */
  signedUrl: Scalars['String']['output'];
};

/** A signed url */
export type ApolloSignedUrlInput = {
  /** The id of the corresponding storage object document */
  id: Scalars['ID']['input'];
};

/** An individual skill that exists within a skill group */
export type ApolloSkill = {
  description?: Maybe<Scalars['String']['output']>;
  group?: Maybe<ApolloSkillGroup>;
  groupId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  standards?: Maybe<Array<ApolloStandard>>;
  title: Scalars['String']['output'];
};

/** A grouping of skills */
export type ApolloSkillGroup = {
  color: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  skillGroupKey: Scalars['String']['output'];
  skills: Array<ApolloSkill>;
  sortOrder: Scalars['Float']['output'];
  title: Scalars['String']['output'];
};

/** A student's performance on a particular skill in an assessment */
export type ApolloSkillProficiency = {
  /** The number of correct answers pertaining to this skill. */
  correctAnswers: Scalars['Float']['output'];
  /** The total number of questions asked pertaining to this skill. */
  questionsCount: Scalars['Float']['output'];
  /** ID of the skill being measured. */
  skillId: Scalars['ID']['output'];
};

/** An individual standard that exists within a standard system */
export type ApolloStandard = {
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  gradeLevel: ApolloGradeLevel;
  id: Scalars['ID']['output'];
  shortCode: Scalars['String']['output'];
  skillIds: Array<Scalars['ID']['output']>;
  skills?: Maybe<Array<ApolloSkill>>;
  standardSystemId: Scalars['ID']['output'];
};

/** A storage object returned from the API */
export type ApolloStorageObject = {
  /** The id of the storage object */
  id: Scalars['ID']['output'];
  /** The mime type of the file */
  mimeType?: Maybe<Scalars['String']['output']>;
  /** Filename provided by the person that uploaded the file */
  name: Scalars['String']['output'];
  /** Is this file publicly accessible? */
  publiclyAccessible: Scalars['Boolean']['output'];
  signedDownloadUrl?: Maybe<Scalars['String']['output']>;
  /** Storage key for the storage object */
  storageKey: Scalars['String']['output'];
};

/** A storage object for creation/update */
export type ApolloStorageObjectInput = {
  /** The id of the storage object if the storage object already exists */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The mime type of the file */
  mimeType?: InputMaybe<Scalars['String']['input']>;
  /** Filename provided by the person that uploaded the file */
  name: Scalars['String']['input'];
  /** Is this file publicly accessible? */
  publiclyAccessible: Scalars['Boolean']['input'];
};

/** Information about a class that can be viewed by students */
export type ApolloStudentAccessibleSchoolClass = {
  /** The id of the class */
  id: Scalars['ID']['output'];
  /** The period of the class */
  period?: Maybe<Scalars['Int']['output']>;
  /** The title of the class */
  title: Scalars['String']['output'];
};

export type ApolloStudentAccountInviteeInput = {
  additionalStudentUserData: ApolloStudentAccountInviteeUserDataInput;
  /** The account email address. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** The first name of the account user. */
  firstName: Scalars['ID']['input'];
  /** The last name of the account user. */
  lastName: Scalars['ID']['input'];
};

export type ApolloStudentAccountInviteeUserDataInput = {
  /** The id of a school customer with whom this student account is associated. */
  schoolCustomerId: Scalars['ID']['input'];
  /** The school-assigned id of this student. */
  studentId: Scalars['String']['input'];
};

/** The answer for an Assessment Question. */
export type ApolloStudentAssessmentAnswer = {
  /** The id of the answer */
  id: Scalars['ID']['output'];
  /** The id of the question being answered */
  questionId: Scalars['ID']['output'];
  /** the selected answer for the question */
  selectedAnswer: ApolloAnswerOption;
  /** The index of the selected answer in the answer options array of the question */
  selectedAnswerIndex: Scalars['Int']['output'];
  /** The student assignment corresponding to this answer */
  studentAssignmentId: Scalars['ID']['output'];
  /** The written answered if applicable */
  writtenAnswer?: Maybe<Scalars['String']['output']>;
};

/** A new or updated answer to an assessment question. */
export type ApolloStudentAssessmentAnswerInput = {
  /** The id of the question being answered */
  questionId: Scalars['ID']['input'];
  /** the selected answer for the question */
  selectedAnswer: ApolloAnswerOptionInput;
  /** The index of the selected answer in the answer options array of the question */
  selectedAnswerIndex: Scalars['Int']['input'];
  /** The student assignment corresponding to this answer */
  studentAssignmentId: Scalars['ID']['input'];
  /** The written answered if applicable */
  writtenAnswer?: InputMaybe<Scalars['String']['input']>;
};

/** A summary of a student's assessment result */
export type ApolloStudentAssessmentResult = {
  /** ID of the assessment that this result is for. */
  assessmentId: Scalars['ID']['output'];
  /** The number of questions the student answered correctly. */
  correctAnswers: Scalars['Float']['output'];
  /** ID of the result. */
  id: Scalars['ID']['output'];
  /** The number of questions the student answered incorrectly. */
  incorrectAnswers: Scalars['Float']['output'];
  /** Instructor notes on the student's performance. */
  notes: Scalars['String']['output'];
  /** Proficiency by skill */
  proficiencyBySkill: Array<ApolloSkillProficiency>;
  /** The number of questions in the assessment. */
  questionsCount: Scalars['Float']['output'];
  /** ID of the student assignment that this result is for. */
  studentAssignmentId: Scalars['ID']['output'];
  /** ID of the student that this result is for. */
  studentId: Scalars['ID']['output'];
  /** The number of questions the student did not answer. */
  unanswered: Scalars['Float']['output'];
};

/** A student assignment.  Tracks assignment data unique to the student */
export type ApolloStudentAssignment = {
  /** The class assignment corresponding to this student assignment */
  classAssignment?: Maybe<ApolloAnyClassAssignment>;
  /** ID of the class assignment */
  classAssignmentId: Scalars['ID']['output'];
  /** The end date and time for this student if it is different than that of the class assignment */
  endDateTimeOverride?: Maybe<Scalars['Float']['output']>;
  /** ID of the student assignment */
  id: Scalars['ID']['output'];
  /** The start date and time for this student if it is different than that of the class assignment */
  startDateTimeOverride?: Maybe<Scalars['Float']['output']>;
  /** When the student began the assessment in ms since the epoch */
  startedAt?: Maybe<Scalars['Float']['output']>;
  /** ID of the student who owns this assignment */
  studentId: Scalars['ID']['output'];
  /** When the student finished the assessment in ms since the epoch */
  submittedAt?: Maybe<Scalars['Float']['output']>;
};

export type ApolloStudentCsvRow = {
  /** The first name of the account user. */
  firstName: Scalars['String']['output'];
  /** The last name of the account user. */
  lastName: Scalars['String']['output'];
  /** The school-assigned id of this student. */
  studentId: Scalars['String']['output'];
};

export type ApolloStudentCsvRowError = {
  errorMessage: Scalars['String']['output'];
  row: ApolloStudentCsvRow;
  rowIndex: Scalars['Float']['output'];
};

export type ApolloStudentCsvUploadResult = {
  errors: Array<ApolloStudentCsvRowError>;
  schoolClass: ApolloSchoolClass;
};

/** Additional data applicable to the school user. */
export type ApolloStudentUserData = {
  /** The id of a school customer with whom this student account is associated. */
  schoolCustomerId: Scalars['ID']['output'];
  /** The school-assigned id of this student. */
  studentId: Scalars['String']['output'];
};

export type ApolloSurveyAnswer = ApolloSurveyAnswerChoice | ApolloSurveyAnswerOtherChoice;

export type ApolloSurveyAnswerChoice = {
  /** The id of the answer from the provided answer options. */
  id: Scalars['ID']['output'];
  /** The text of the answer. */
  text: Scalars['String']['output'];
};

export type ApolloSurveyAnswerChoiceInput = {
  /** The id of the answer from the provided answer options. */
  id: Scalars['ID']['input'];
  /** The text of the answer. */
  text: Scalars['String']['input'];
};

export type ApolloSurveyAnswerInput = {
  /** The id of the answer from the provided answer options. */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The text of the answer. */
  text: Scalars['String']['input'];
};

export type ApolloSurveyAnswerOtherChoice = {
  /** The text of the answer. */
  text: Scalars['String']['output'];
};

export type ApolloSurveyMultipleSelectOptions = {
  /** If true, the user may provide their own unlisted response. */
  allowOther?: Maybe<Scalars['Boolean']['output']>;
  /** The answers to choose from for this question. */
  answers: Array<ApolloSurveyAnswerChoice>;
  /** The format of the survey question. */
  format: ApolloSurveyQuestionFormat;
  /** Short text to clarify anything about the question. */
  hint?: Maybe<Scalars['String']['output']>;
  /** If provided, the maximum number of answers that can be selected. */
  maxSelect?: Maybe<Scalars['Int']['output']>;
  /** If provided, the minimum number of answers that must be selected in order for the question to be considered answered. */
  minSelect?: Maybe<Scalars['Int']['output']>;
};

export type ApolloSurveyMultipleSelectResponse = {
  answers: Array<ApolloSurveyAnswer>;
  format: ApolloSurveyQuestionFormat;
};

export type ApolloSurveyMultipleSelectResponseInput = {
  answers: Array<ApolloSurveyAnswerInput>;
  format: ApolloSurveyQuestionFormat;
};

export type ApolloSurveyMultipleSelectResultInput = {
  question: ApolloSurveyQuestionInput;
  response: ApolloSurveyMultipleSelectResponseInput;
};

/** A survey question with a defined format. */
export type ApolloSurveyQuestion = {
  /** The date and time the survey question was created. */
  createdAt: Scalars['Float']['output'];
  /** The id of the survey question. */
  id: Scalars['ID']['output'];
  /** The options for the survey question. */
  options: ApolloSurveyQuestionOptions;
  /** The text of the survey question. */
  text: Scalars['String']['output'];
  /** The date and time the survey question was last updated. */
  updatedAt: Scalars['Float']['output'];
};

/** The format of a survey question */
export const ApolloSurveyQuestionFormat = {
  MultipleSelect: 'MULTIPLE_SELECT',
  SingleSelect: 'SINGLE_SELECT'
} as const;

export type ApolloSurveyQuestionFormat = typeof ApolloSurveyQuestionFormat[keyof typeof ApolloSurveyQuestionFormat];
export type ApolloSurveyQuestionInput = {
  /** The date and time the survey question was created. */
  createdAt: Scalars['Float']['input'];
  /** The id of the survey question. */
  id: Scalars['ID']['input'];
  /** The options for the survey question. */
  options: ApolloOmitObjectType;
  /** The text of the survey question. */
  text: Scalars['String']['input'];
  /** The date and time the survey question was last updated. */
  updatedAt: Scalars['Float']['input'];
};

export type ApolloSurveyQuestionOptions = ApolloSurveyMultipleSelectOptions | ApolloSurveySingleSelectOptions;

export type ApolloSurveyResponse = ApolloSurveyMultipleSelectResponse | ApolloSurveySingleSelectResponse;

/** A survey question with a defined format. */
export type ApolloSurveyResult = {
  /** The date and time this result was created. */
  createdAt: Scalars['Float']['output'];
  /** The historical duplicate of the original question which resulted in this response. */
  question: ApolloSurveyQuestion;
  /** The response to the question. */
  response: ApolloSurveyResponse;
};

export type ApolloSurveyResultUpdate = {
  multipleSelect?: InputMaybe<ApolloSurveyMultipleSelectResultInput>;
  singleSelect?: InputMaybe<ApolloSurveySingleSelectResultInput>;
};

export type ApolloSurveySingleSelectOptions = {
  /** If true, the user may provide their own unlisted response. */
  allowOther?: Maybe<Scalars['Boolean']['output']>;
  /** The answers to choose from for this question. */
  answers: Array<ApolloSurveyAnswerChoice>;
  /** The format of the survey question. */
  format: ApolloSurveyQuestionFormat;
  /** Short text to clarify anything about the question. */
  hint?: Maybe<Scalars['String']['output']>;
};

export type ApolloSurveySingleSelectResponse = {
  answer: ApolloSurveyAnswer;
  format: ApolloSurveyQuestionFormat;
};

export type ApolloSurveySingleSelectResponseInput = {
  answer: ApolloSurveyAnswerInput;
  format: ApolloSurveyQuestionFormat;
};

export type ApolloSurveySingleSelectResultInput = {
  question: ApolloSurveyQuestionInput;
  response: ApolloSurveySingleSelectResponseInput;
};

/** Properties that may be used to filter returned school classes */
export type ApolloTeacherSchoolClassesFilterInput = {
  /** Beginning date of requested data */
  firstDay?: InputMaybe<Scalars['Float']['input']>;
  /** Ending date of requested data */
  lastDay?: InputMaybe<Scalars['Float']['input']>;
};

/** The teacher spotlight to be displayed on the dashboard. */
export type ApolloTeacherSpotlight = {
  /** The name of the teacher to be spotlighted. */
  name: Scalars['String']['output'];
  /** The photo of the teacher to be spotlighted. */
  photo?: Maybe<ApolloStorageObject>;
  /** The quote of the teacher to be spotlighted. */
  quote: Scalars['String']['output'];
};

/** The input for a teacher spotlight object */
export type ApolloTeacherSpotlightInput = {
  /** The name of the teacher to be spotlighted. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The photo of the teacher to be spotlighted. */
  photo?: InputMaybe<ApolloStorageObjectInput>;
  /** The quote from the teacher to be spotlighted. */
  quote?: InputMaybe<Scalars['String']['input']>;
};

/** Only the following items can be updated by a school user. */
export type ApolloTeachingInfoInput = {
  /** The self-reported grades this user teaches. */
  gradesTaught?: InputMaybe<Array<ApolloGradeLevel>>;
  /** The self-reported job title of this user. */
  jobTitle?: InputMaybe<ApolloSchoolJobTitle>;
  /** The city of the school with which an independent school user is affiliated. This is not required if the user is affiliated with an NCES school. */
  schoolCity?: InputMaybe<Scalars['String']['input']>;
  /** The countrry of the school with which an independent school user is affiliated. This is not required if the user is affiliated with an NCES school. */
  schoolCountry?: InputMaybe<Scalars['String']['input']>;
  /** The id of the NCES school with which an independent school user is affiliated. */
  schoolId?: InputMaybe<Scalars['String']['input']>;
  /** The name of the school with which an independent school user is affiliated. This is not required if the user is affiliated with an NCES school. */
  schoolName?: InputMaybe<Scalars['String']['input']>;
  /** The state or territory of the school with which an independent school user is affiliated. This is not required if the user is affiliated with an NCES school. */
  schoolState?: InputMaybe<Scalars['String']['input']>;
};

/** Input for updating a class assessment assignment. */
export type ApolloUpdateClassAssessmentAssignmentInput = {
  /** ID of the assessment that this assignment is for. */
  assessmentId: Scalars['ID']['input'];
  /** End date and time of the assignment */
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  /** ID of the assignment. */
  id: Scalars['ID']['input'];
  /** Start date and time of the assignment */
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  /** Any student assignments that should be updated for this class assignment */
  studentAssignments: Array<ApolloUpdateStudentAssignment>;
  /** Title of the assignment. */
  title: Scalars['String']['input'];
  /** The type of this assignment */
  type: ApolloAssignmentType;
};

/** Input for updating a class miscellaneous assignment. */
export type ApolloUpdateClassMiscellaneousAssignmentInput = {
  /** Description of the assignment. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** End date and time of the assignment */
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  /** ID of the assignment. */
  id: Scalars['ID']['input'];
  /** Start date and time of the assignment */
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  /** Any student assignments that should be updated for this class assignment */
  studentAssignments: Array<ApolloUpdateStudentAssignment>;
  /** Title of the assignment. */
  title: Scalars['String']['input'];
  /** The type of this assignment */
  type: ApolloAssignmentType;
};

/** The input for updating a planner note */
export type ApolloUpdatePlannerNoteInput = {
  /** The main body of the lesson plan */
  body?: InputMaybe<Scalars['String']['input']>;
  /** The id of the class day plan */
  id: Scalars['ID']['input'];
  /** The id of the sequence block associated with this class day plan */
  plannerBlockId?: InputMaybe<Scalars['ID']['input']>;
  /** The offset in days from the beginning of the sequence block */
  plannerBlockOffset?: InputMaybe<Scalars['Float']['input']>;
  /** The id of the class */
  schoolClassId: Scalars['ID']['input'];
};

/** Input to update a class a teacher is teaching */
export type ApolloUpdateSchoolClassInput = {
  /** The color associated with this class */
  color: Scalars['String']['input'];
  /** The beginning of the first day of class in milliseconds since the epoch */
  firstDay: Scalars['Float']['input'];
  /** The grade level of the class */
  gradeLevel: ApolloGradeLevel;
  /** The id of the class */
  id: Scalars['ID']['input'];
  /** The start of the last day of class in milliseconds since the epoch */
  lastDay: Scalars['Float']['input'];
  /** The period of the class */
  period?: InputMaybe<Scalars['Int']['input']>;
  /** The ids of the skills taught in this class */
  skillIds: Array<Scalars['ID']['input']>;
  /** The title of the class */
  title: Scalars['String']['input'];
};

/** Input for updating a student assignment */
export type ApolloUpdateStudentAssignment = {
  /** ID of the class assignment */
  classAssignmentId?: InputMaybe<Scalars['ID']['input']>;
  /** The end date and time for this student if it is different than that of the class assignment */
  endDateTimeOverride?: InputMaybe<Scalars['Float']['input']>;
  /** ID of the student who owns this assignment */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** ID of the class assignment */
  schoolClassId?: InputMaybe<Scalars['ID']['input']>;
  /** The start date and time for this student if it is different than that of the class assignment */
  startDateTimeOverride?: InputMaybe<Scalars['Float']['input']>;
  /** When the student began the assessment in ms since the epoch */
  startedAt?: InputMaybe<Scalars['Float']['input']>;
  /** ID of the student who owns this assignment */
  studentId?: InputMaybe<Scalars['ID']['input']>;
  /** When the student finished the assessment in ms since the epoch */
  submittedAt?: InputMaybe<Scalars['Float']['input']>;
};

/** Update input for school student. */
export type ApolloUpdateStudentDataGqlInput = {
  /** Input for a student additionalStudentUserData field */
  additionalStudentUserData: ApolloStudentAccountInviteeUserDataInput;
  /** The account email address. */
  email: Scalars['String']['input'];
  /** The first name of the account user. */
  firstName: Scalars['String']['input'];
  /** Unique identifier, UUIDv4. */
  id: Scalars['ID']['input'];
  /** The last name of the account user. */
  lastName: Scalars['String']['input'];
};

/** The user application */
export const ApolloUserApplication = {
  Internal: 'INTERNAL',
  School: 'SCHOOL',
  Student: 'STUDENT'
} as const;

export type ApolloUserApplication = typeof ApolloUserApplication[keyof typeof ApolloUserApplication];
export type ApolloAuthenticatedAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type ApolloAuthenticatedAccountQuery = { authenticatedAccount: { acknowledgedPrivacyPolicyAt?: number | undefined, active: boolean, agreedToTermsAndConditionsAt?: number | undefined, avatar?: string | undefined, createdAt: number, email: string, firstName: string, fullName: string, id: string, lastName: string, pending: boolean, phone?: string | undefined, updatedAt: number, additionalSchoolUserData?: { plan: ApolloSchoolAccountPlan, roles: Array<ApolloSchoolAccountRole>, gradesTaught?: Array<ApolloGradeLevel> | undefined, jobTitle?: ApolloSchoolJobTitle | undefined, kajabiMemberId?: number | undefined, registrationSurveyCompleted: boolean, schoolCity?: string | undefined, schoolCountry?: string | undefined, schoolCustomerId?: string | undefined, schoolId?: string | undefined, schoolName?: string | undefined, schoolState?: string | undefined, userType?: ApolloSchoolUserType | undefined, watchedWelcomeVideo?: boolean | undefined } | undefined, additionalStudentUserData?: { schoolCustomerId: string, studentId: string } | undefined } };

export type ApolloLoginAsQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ApolloLoginAsQuery = { loginAs: boolean };

export type ApolloInternalAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApolloInternalAccountsQuery = { internalAccounts: Array<{ acknowledgedPrivacyPolicyAt?: number | undefined, active: boolean, agreedToTermsAndConditionsAt?: number | undefined, avatar?: string | undefined, createdAt: number, email: string, firstName: string, fullName: string, id: string, lastName: string, pending: boolean, phone?: string | undefined, updatedAt: number, additionalSchoolUserData?: { plan: ApolloSchoolAccountPlan, roles: Array<ApolloSchoolAccountRole>, gradesTaught?: Array<ApolloGradeLevel> | undefined, jobTitle?: ApolloSchoolJobTitle | undefined, kajabiMemberId?: number | undefined, registrationSurveyCompleted: boolean, schoolCity?: string | undefined, schoolCountry?: string | undefined, schoolCustomerId?: string | undefined, schoolId?: string | undefined, schoolName?: string | undefined, schoolState?: string | undefined, userType?: ApolloSchoolUserType | undefined, watchedWelcomeVideo?: boolean | undefined } | undefined, additionalStudentUserData?: { schoolCustomerId: string, studentId: string } | undefined }> };

export type ApolloRandomStudentPasswordsQueryVariables = Exact<{
  count: Scalars['Int']['input'];
}>;


export type ApolloRandomStudentPasswordsQuery = { randomStudentPasswords: Array<string> };

export type ApolloStudentAccountQueryVariables = Exact<{
  studentId: Scalars['String']['input'];
  schoolCustomerId: Scalars['ID']['input'];
}>;


export type ApolloStudentAccountQuery = { studentAccount?: { id: string, firstName: string, lastName: string, email: string, additionalStudentUserData?: { studentId: string, schoolCustomerId: string } | undefined } | undefined };

export type ApolloStudentLoginEmailQueryVariables = Exact<{
  schoolCustomerId: Scalars['ID']['input'];
  studentId: Scalars['String']['input'];
}>;


export type ApolloStudentLoginEmailQuery = { studentLoginEmail?: string | undefined };

export type ApolloCheckCanRegisterQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ApolloCheckCanRegisterQuery = { canRegister: boolean };

export type ApolloAccountPlanWillUpgradeQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ApolloAccountPlanWillUpgradeQuery = { accountByEmail?: { planWouldChange: boolean } | undefined };

export type ApolloUpdateLegalDocumentTimestampsMutationVariables = Exact<{ [key: string]: never; }>;


export type ApolloUpdateLegalDocumentTimestampsMutation = { updateLegalDocumentTimestamps: { acknowledgedPrivacyPolicyAt?: number | undefined, active: boolean, agreedToTermsAndConditionsAt?: number | undefined, avatar?: string | undefined, createdAt: number, email: string, firstName: string, fullName: string, id: string, lastName: string, pending: boolean, phone?: string | undefined, updatedAt: number, additionalSchoolUserData?: { plan: ApolloSchoolAccountPlan, roles: Array<ApolloSchoolAccountRole>, gradesTaught?: Array<ApolloGradeLevel> | undefined, jobTitle?: ApolloSchoolJobTitle | undefined, kajabiMemberId?: number | undefined, registrationSurveyCompleted: boolean, schoolCity?: string | undefined, schoolCountry?: string | undefined, schoolCustomerId?: string | undefined, schoolId?: string | undefined, schoolName?: string | undefined, schoolState?: string | undefined, userType?: ApolloSchoolUserType | undefined, watchedWelcomeVideo?: boolean | undefined } | undefined, additionalStudentUserData?: { schoolCustomerId: string, studentId: string } | undefined } };

export type ApolloInviteInternalAccountMutationVariables = Exact<{
  invitee: ApolloInternalAccountInviteeInput;
}>;


export type ApolloInviteInternalAccountMutation = { inviteInternalAccount: { acknowledgedPrivacyPolicyAt?: number | undefined, active: boolean, agreedToTermsAndConditionsAt?: number | undefined, avatar?: string | undefined, createdAt: number, email: string, firstName: string, fullName: string, id: string, lastName: string, pending: boolean, phone?: string | undefined, updatedAt: number, additionalSchoolUserData?: { plan: ApolloSchoolAccountPlan, roles: Array<ApolloSchoolAccountRole>, gradesTaught?: Array<ApolloGradeLevel> | undefined, jobTitle?: ApolloSchoolJobTitle | undefined, kajabiMemberId?: number | undefined, registrationSurveyCompleted: boolean, schoolCity?: string | undefined, schoolCountry?: string | undefined, schoolCustomerId?: string | undefined, schoolId?: string | undefined, schoolName?: string | undefined, schoolState?: string | undefined, userType?: ApolloSchoolUserType | undefined, watchedWelcomeVideo?: boolean | undefined } | undefined, additionalStudentUserData?: { schoolCustomerId: string, studentId: string } | undefined } };

export type ApolloInviteSchoolAccountMutationVariables = Exact<{
  invitee: ApolloSchoolAccountInviteeInput;
}>;


export type ApolloInviteSchoolAccountMutation = { inviteSchoolAccount: { acknowledgedPrivacyPolicyAt?: number | undefined, active: boolean, agreedToTermsAndConditionsAt?: number | undefined, avatar?: string | undefined, createdAt: number, email: string, firstName: string, fullName: string, id: string, lastName: string, pending: boolean, phone?: string | undefined, updatedAt: number, additionalSchoolUserData?: { plan: ApolloSchoolAccountPlan, roles: Array<ApolloSchoolAccountRole>, gradesTaught?: Array<ApolloGradeLevel> | undefined, jobTitle?: ApolloSchoolJobTitle | undefined, kajabiMemberId?: number | undefined, registrationSurveyCompleted: boolean, schoolCity?: string | undefined, schoolCountry?: string | undefined, schoolCustomerId?: string | undefined, schoolId?: string | undefined, schoolName?: string | undefined, schoolState?: string | undefined, userType?: ApolloSchoolUserType | undefined, watchedWelcomeVideo?: boolean | undefined } | undefined, additionalStudentUserData?: { schoolCustomerId: string, studentId: string } | undefined } };

export type ApolloInviteStudentAccountMutationVariables = Exact<{
  invitee: ApolloStudentAccountInviteeInput;
}>;


export type ApolloInviteStudentAccountMutation = { inviteStudentAccount: { acknowledgedPrivacyPolicyAt?: number | undefined, active: boolean, agreedToTermsAndConditionsAt?: number | undefined, avatar?: string | undefined, createdAt: number, email: string, firstName: string, fullName: string, id: string, lastName: string, pending: boolean, phone?: string | undefined, updatedAt: number, additionalSchoolUserData?: { plan: ApolloSchoolAccountPlan, roles: Array<ApolloSchoolAccountRole>, gradesTaught?: Array<ApolloGradeLevel> | undefined, jobTitle?: ApolloSchoolJobTitle | undefined, kajabiMemberId?: number | undefined, registrationSurveyCompleted: boolean, schoolCity?: string | undefined, schoolCountry?: string | undefined, schoolCustomerId?: string | undefined, schoolId?: string | undefined, schoolName?: string | undefined, schoolState?: string | undefined, userType?: ApolloSchoolUserType | undefined, watchedWelcomeVideo?: boolean | undefined } | undefined, additionalStudentUserData?: { schoolCustomerId: string, studentId: string } | undefined } };

export type ApolloResendInviteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ApolloResendInviteMutation = { resendInvite: boolean };

export type ApolloRegisterAccountMutationVariables = Exact<{
  registration: ApolloAccountRegistrationInput;
}>;


export type ApolloRegisterAccountMutation = { registerAccount: { acknowledgedPrivacyPolicyAt?: number | undefined, active: boolean, agreedToTermsAndConditionsAt?: number | undefined, avatar?: string | undefined, createdAt: number, email: string, firstName: string, fullName: string, id: string, lastName: string, pending: boolean, phone?: string | undefined, updatedAt: number, additionalSchoolUserData?: { plan: ApolloSchoolAccountPlan, roles: Array<ApolloSchoolAccountRole>, gradesTaught?: Array<ApolloGradeLevel> | undefined, jobTitle?: ApolloSchoolJobTitle | undefined, kajabiMemberId?: number | undefined, registrationSurveyCompleted: boolean, schoolCity?: string | undefined, schoolCountry?: string | undefined, schoolCustomerId?: string | undefined, schoolId?: string | undefined, schoolName?: string | undefined, schoolState?: string | undefined, userType?: ApolloSchoolUserType | undefined, watchedWelcomeVideo?: boolean | undefined } | undefined, additionalStudentUserData?: { schoolCustomerId: string, studentId: string } | undefined } };

export type ApolloSetAccountPasswordMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  password: Scalars['String']['input'];
}>;


export type ApolloSetAccountPasswordMutation = { setAccountPassword: { acknowledgedPrivacyPolicyAt?: number | undefined, active: boolean, agreedToTermsAndConditionsAt?: number | undefined, avatar?: string | undefined, createdAt: number, email: string, firstName: string, fullName: string, id: string, lastName: string, pending: boolean, phone?: string | undefined, updatedAt: number, additionalSchoolUserData?: { plan: ApolloSchoolAccountPlan, roles: Array<ApolloSchoolAccountRole>, gradesTaught?: Array<ApolloGradeLevel> | undefined, jobTitle?: ApolloSchoolJobTitle | undefined, kajabiMemberId?: number | undefined, registrationSurveyCompleted: boolean, schoolCity?: string | undefined, schoolCountry?: string | undefined, schoolCustomerId?: string | undefined, schoolId?: string | undefined, schoolName?: string | undefined, schoolState?: string | undefined, userType?: ApolloSchoolUserType | undefined, watchedWelcomeVideo?: boolean | undefined } | undefined, additionalStudentUserData?: { schoolCustomerId: string, studentId: string } | undefined } };

export type ApolloUpdateAccountAccessMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  canAccess: Scalars['Boolean']['input'];
}>;


export type ApolloUpdateAccountAccessMutation = { updateAccountAccess: { id: string } };

export type ApolloAdminUpdateInternalProfileMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  changes: ApolloAccountProfileAdminChangesInput;
}>;


export type ApolloAdminUpdateInternalProfileMutation = { adminUpdateInternalProfile: { acknowledgedPrivacyPolicyAt?: number | undefined, active: boolean, agreedToTermsAndConditionsAt?: number | undefined, avatar?: string | undefined, createdAt: number, email: string, firstName: string, fullName: string, id: string, lastName: string, pending: boolean, phone?: string | undefined, updatedAt: number, additionalSchoolUserData?: { plan: ApolloSchoolAccountPlan, roles: Array<ApolloSchoolAccountRole>, gradesTaught?: Array<ApolloGradeLevel> | undefined, jobTitle?: ApolloSchoolJobTitle | undefined, kajabiMemberId?: number | undefined, registrationSurveyCompleted: boolean, schoolCity?: string | undefined, schoolCountry?: string | undefined, schoolCustomerId?: string | undefined, schoolId?: string | undefined, schoolName?: string | undefined, schoolState?: string | undefined, userType?: ApolloSchoolUserType | undefined, watchedWelcomeVideo?: boolean | undefined } | undefined, additionalStudentUserData?: { schoolCustomerId: string, studentId: string } | undefined } };

export type ApolloAdminUpdateSchoolProfileMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  changes: ApolloSchoolProfileAdminChangesInput;
}>;


export type ApolloAdminUpdateSchoolProfileMutation = { adminUpdateSchoolProfile: { acknowledgedPrivacyPolicyAt?: number | undefined, active: boolean, agreedToTermsAndConditionsAt?: number | undefined, avatar?: string | undefined, createdAt: number, email: string, firstName: string, fullName: string, id: string, lastName: string, pending: boolean, phone?: string | undefined, updatedAt: number, additionalSchoolUserData?: { plan: ApolloSchoolAccountPlan, roles: Array<ApolloSchoolAccountRole>, gradesTaught?: Array<ApolloGradeLevel> | undefined, jobTitle?: ApolloSchoolJobTitle | undefined, kajabiMemberId?: number | undefined, registrationSurveyCompleted: boolean, schoolCity?: string | undefined, schoolCountry?: string | undefined, schoolCustomerId?: string | undefined, schoolId?: string | undefined, schoolName?: string | undefined, schoolState?: string | undefined, userType?: ApolloSchoolUserType | undefined, watchedWelcomeVideo?: boolean | undefined } | undefined, additionalStudentUserData?: { schoolCustomerId: string, studentId: string } | undefined } };

export type ApolloUpdateStudentDataMutationVariables = Exact<{
  input: ApolloUpdateStudentDataGqlInput;
}>;


export type ApolloUpdateStudentDataMutation = { updateSchoolStudentData: { id: string, firstName: string, lastName: string, email: string, additionalStudentUserData?: { schoolCustomerId: string, studentId: string } | undefined } };

export type ApolloUpdateSchoolUserDataMutationVariables = Exact<{
  update: ApolloSchoolUserDataUpdate;
}>;


export type ApolloUpdateSchoolUserDataMutation = { updateSchoolUserData: { acknowledgedPrivacyPolicyAt?: number | undefined, active: boolean, agreedToTermsAndConditionsAt?: number | undefined, avatar?: string | undefined, createdAt: number, email: string, firstName: string, fullName: string, id: string, lastName: string, pending: boolean, phone?: string | undefined, updatedAt: number, additionalSchoolUserData?: { plan: ApolloSchoolAccountPlan, roles: Array<ApolloSchoolAccountRole>, gradesTaught?: Array<ApolloGradeLevel> | undefined, jobTitle?: ApolloSchoolJobTitle | undefined, kajabiMemberId?: number | undefined, registrationSurveyCompleted: boolean, schoolCity?: string | undefined, schoolCountry?: string | undefined, schoolCustomerId?: string | undefined, schoolId?: string | undefined, schoolName?: string | undefined, schoolState?: string | undefined, userType?: ApolloSchoolUserType | undefined, watchedWelcomeVideo?: boolean | undefined } | undefined, additionalStudentUserData?: { schoolCustomerId: string, studentId: string } | undefined } };

export type ApolloUserUpdateAccountProfileMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  profile: ApolloAccountProfileChangesInput;
  teachingInfo?: InputMaybe<ApolloTeachingInfoInput>;
}>;


export type ApolloUserUpdateAccountProfileMutation = { userUpdateAccountProfile: { acknowledgedPrivacyPolicyAt?: number | undefined, active: boolean, agreedToTermsAndConditionsAt?: number | undefined, avatar?: string | undefined, createdAt: number, email: string, firstName: string, fullName: string, id: string, lastName: string, pending: boolean, phone?: string | undefined, updatedAt: number, additionalSchoolUserData?: { plan: ApolloSchoolAccountPlan, roles: Array<ApolloSchoolAccountRole>, gradesTaught?: Array<ApolloGradeLevel> | undefined, jobTitle?: ApolloSchoolJobTitle | undefined, kajabiMemberId?: number | undefined, registrationSurveyCompleted: boolean, schoolCity?: string | undefined, schoolCountry?: string | undefined, schoolCustomerId?: string | undefined, schoolId?: string | undefined, schoolName?: string | undefined, schoolState?: string | undefined, userType?: ApolloSchoolUserType | undefined, watchedWelcomeVideo?: boolean | undefined } | undefined, additionalStudentUserData?: { schoolCustomerId: string, studentId: string } | undefined } };

export type ApolloUserUpdateAccountEmailMutationVariables = Exact<{ [key: string]: never; }>;


export type ApolloUserUpdateAccountEmailMutation = { userUpdateAccountEmail: { id: string } };

export type ApolloAccountPropertiesFragment = { acknowledgedPrivacyPolicyAt?: number | undefined, active: boolean, agreedToTermsAndConditionsAt?: number | undefined, avatar?: string | undefined, createdAt: number, email: string, firstName: string, fullName: string, id: string, lastName: string, pending: boolean, phone?: string | undefined, updatedAt: number, additionalSchoolUserData?: { plan: ApolloSchoolAccountPlan, roles: Array<ApolloSchoolAccountRole>, gradesTaught?: Array<ApolloGradeLevel> | undefined, jobTitle?: ApolloSchoolJobTitle | undefined, kajabiMemberId?: number | undefined, registrationSurveyCompleted: boolean, schoolCity?: string | undefined, schoolCountry?: string | undefined, schoolCustomerId?: string | undefined, schoolId?: string | undefined, schoolName?: string | undefined, schoolState?: string | undefined, userType?: ApolloSchoolUserType | undefined, watchedWelcomeVideo?: boolean | undefined } | undefined, additionalStudentUserData?: { schoolCustomerId: string, studentId: string } | undefined };

export type ApolloCreateAssessmentMutationVariables = Exact<{
  assessment: ApolloCreateAssessmentKeyInput;
}>;


export type ApolloCreateAssessmentMutation = { createAssessment: { id: string, intendedGradeLevel: ApolloGradeLevel, title: string, type: ApolloAssessmentType, instructions?: string | undefined, publishAt?: number | undefined, assessmentComponents: Array<{ type: ApolloAssessmentComponentType, question: { id: string, format: ApolloQuestionFormat, intendedSkillId: string, intendedGradeLevel: ApolloGradeLevel, text: string, answerOptions: Array<{ text: string, correct: boolean, explanation?: string | undefined }> } } | { type: ApolloAssessmentComponentType, questionGroup: { id: string, instructions?: string | undefined, title?: string | undefined, content?: string | undefined, questionIds: Array<string>, questions?: Array<{ id: string, format: ApolloQuestionFormat, intendedSkillId: string, intendedGradeLevel: ApolloGradeLevel, text: string, answerOptions: Array<{ text: string, correct: boolean, explanation?: string | undefined }> }> | undefined } }> } };

export type ApolloUpdateAssessmentMutationVariables = Exact<{
  assessment: ApolloAssessmentKeyInput;
}>;


export type ApolloUpdateAssessmentMutation = { updateAssessment: { id: string, intendedGradeLevel: ApolloGradeLevel, title: string, type: ApolloAssessmentType, instructions?: string | undefined, publishAt?: number | undefined, assessmentComponents: Array<{ type: ApolloAssessmentComponentType, question: { id: string, format: ApolloQuestionFormat, intendedSkillId: string, intendedGradeLevel: ApolloGradeLevel, text: string, answerOptions: Array<{ text: string, correct: boolean, explanation?: string | undefined }> } } | { type: ApolloAssessmentComponentType, questionGroup: { id: string, instructions?: string | undefined, title?: string | undefined, content?: string | undefined, questionIds: Array<string>, questions?: Array<{ id: string, format: ApolloQuestionFormat, intendedSkillId: string, intendedGradeLevel: ApolloGradeLevel, text: string, answerOptions: Array<{ text: string, correct: boolean, explanation?: string | undefined }> }> | undefined } }> } };

export type ApolloCreateQuestionGroupMutationVariables = Exact<{
  questionGroup: ApolloCreateQuestionGroupKeyInput;
}>;


export type ApolloCreateQuestionGroupMutation = { createQuestionGroup: { id: string, instructions?: string | undefined, title?: string | undefined, content?: string | undefined, questionIds: Array<string>, questions?: Array<{ id: string, format: ApolloQuestionFormat, intendedSkillId: string, intendedGradeLevel: ApolloGradeLevel, text: string, answerOptions: Array<{ text: string, correct: boolean, explanation?: string | undefined }> }> | undefined } };

export type ApolloUpdateQuestionGroupMutationVariables = Exact<{
  questionGroup: ApolloQuestionGroupKeyInput;
}>;


export type ApolloUpdateQuestionGroupMutation = { updateQuestionGroup: { id: string, instructions?: string | undefined, title?: string | undefined, content?: string | undefined, questionIds: Array<string>, questions?: Array<{ id: string, format: ApolloQuestionFormat, intendedSkillId: string, intendedGradeLevel: ApolloGradeLevel, text: string, answerOptions: Array<{ text: string, correct: boolean, explanation?: string | undefined }> }> | undefined } };

export type ApolloDeleteQuestionGroupMutationVariables = Exact<{
  questionGroupId: Scalars['ID']['input'];
}>;


export type ApolloDeleteQuestionGroupMutation = { deleteQuestionGroup: { id: string } };

export type ApolloCreateAssessmentQuestionMutationVariables = Exact<{
  assessmentQuestion: ApolloCreateAssessmentQuestionKeyInput;
}>;


export type ApolloCreateAssessmentQuestionMutation = { createAssessmentQuestion: { id: string, format: ApolloQuestionFormat, intendedSkillId: string, intendedGradeLevel: ApolloGradeLevel, text: string, answerOptions: Array<{ text: string, correct: boolean, explanation?: string | undefined }> } };

export type ApolloUpdateAssessmentQuestionMutationVariables = Exact<{
  assessmentQuestion: ApolloAssessmentQuestionKeyInput;
}>;


export type ApolloUpdateAssessmentQuestionMutation = { updateAssessmentQuestion: { id: string, format: ApolloQuestionFormat, intendedSkillId: string, intendedGradeLevel: ApolloGradeLevel, text: string, answerOptions: Array<{ text: string, correct: boolean, explanation?: string | undefined }> } };

export type ApolloDeleteAssessmentQuestionMutationVariables = Exact<{
  assessmentQuestionId: Scalars['ID']['input'];
}>;


export type ApolloDeleteAssessmentQuestionMutation = { deleteAssessmentQuestion: { id: string } };

export type ApolloAllAssessmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApolloAllAssessmentsQuery = { allAssessments: Array<{ id: string, intendedGradeLevel: ApolloGradeLevel, title: string, type: ApolloAssessmentType }> };

export type ApolloAssessmentsForGradeLevelQueryVariables = Exact<{
  gradeLevel: ApolloGradeLevel;
}>;


export type ApolloAssessmentsForGradeLevelQuery = { assessmentsForGradeLevel: Array<{ id: string, intendedGradeLevel: ApolloGradeLevel, title: string, type: ApolloAssessmentType }> };

export type ApolloAssessmentForInternalUserQueryVariables = Exact<{
  assessmentId: Scalars['ID']['input'];
}>;


export type ApolloAssessmentForInternalUserQuery = { assessmentForInternalUser: { id: string, intendedGradeLevel: ApolloGradeLevel, title: string, type: ApolloAssessmentType, instructions?: string | undefined, publishAt?: number | undefined, assessmentComponents: Array<{ type: ApolloAssessmentComponentType, question: { id: string, format: ApolloQuestionFormat, intendedSkillId: string, intendedGradeLevel: ApolloGradeLevel, text: string, answerOptions: Array<{ text: string, correct: boolean, explanation?: string | undefined }> } } | { type: ApolloAssessmentComponentType, questionGroup: { id: string, instructions?: string | undefined, title?: string | undefined, content?: string | undefined, questionIds: Array<string>, questions?: Array<{ id: string, format: ApolloQuestionFormat, intendedSkillId: string, intendedGradeLevel: ApolloGradeLevel, text: string, answerOptions: Array<{ text: string, correct: boolean, explanation?: string | undefined }> }> | undefined } }> } };

export type ApolloLoggedInAssessmentForStudentAssignmentQueryVariables = Exact<{
  studentAssignmentId: Scalars['ID']['input'];
}>;


export type ApolloLoggedInAssessmentForStudentAssignmentQuery = { loggedInAssessmentForStudentAssignment: { instructions?: string | undefined, id: string, intendedGradeLevel: ApolloGradeLevel, title: string, type: ApolloAssessmentType, assessmentComponents: Array<{ type: ApolloAssessmentComponentType, question: { id: string, format: ApolloQuestionFormat, intendedSkillId: string, intendedGradeLevel: ApolloGradeLevel, text: string, answerOptions: Array<{ text: string }> } } | { type: ApolloAssessmentComponentType, questionGroup: { instructions?: string | undefined, title?: string | undefined, content?: string | undefined, questionIds: Array<string>, questions?: Array<{ id: string, format: ApolloQuestionFormat, intendedSkillId: string, intendedGradeLevel: ApolloGradeLevel, text: string, answerOptions: Array<{ text: string }> }> | undefined } }> } };

export type ApolloStartLoggedInStudentAssessmentAssignmentMutationVariables = Exact<{
  studentAssignmentId: Scalars['ID']['input'];
}>;


export type ApolloStartLoggedInStudentAssessmentAssignmentMutation = { startLoggedInStudentAssessmentAssignment: { id: string } };

export type ApolloLoggedInStudentAssessmentAnswersQueryVariables = Exact<{
  studentAssignmentId: Scalars['ID']['input'];
}>;


export type ApolloLoggedInStudentAssessmentAnswersQuery = { loggedInStudentAssessmentAnswers: Array<{ id: string, questionId: string, studentAssignmentId: string, selectedAnswerIndex: number, selectedAnswer: { text: string } }> };

export type ApolloGetStudentAssessmentResultsForClassAssignmentQueryVariables = Exact<{
  classAssignmentId: Scalars['ID']['input'];
}>;


export type ApolloGetStudentAssessmentResultsForClassAssignmentQuery = { getStudentAssessmentResultsForClassAssignment: Array<{ id: string, studentId: string, assessmentId: string, studentAssignmentId: string, questionsCount: number, correctAnswers: number, incorrectAnswers: number, unanswered: number, proficiencyBySkill: Array<{ skillId: string, questionsCount: number, correctAnswers: number }> }> };

export type ApolloSubmitStudentAssessmentAssignmentMutationVariables = Exact<{
  studentAssignmentId: Scalars['ID']['input'];
}>;


export type ApolloSubmitStudentAssessmentAssignmentMutation = { submitStudentAssessmentAssignment: { id: string, studentId: string, classAssignmentId: string, startDateTimeOverride?: number | undefined, endDateTimeOverride?: number | undefined } };

export type ApolloAnswerAssessmentQuestionMutationVariables = Exact<{
  input: ApolloStudentAssessmentAnswerInput;
}>;


export type ApolloAnswerAssessmentQuestionMutation = { submitAssessmentAnswer: { id: string, questionId: string, studentAssignmentId: string, selectedAnswerIndex: number, selectedAnswer: { text: string } } };

export type ApolloAssessmentSummaryFragment = { id: string, intendedGradeLevel: ApolloGradeLevel, title: string, type: ApolloAssessmentType };

export type ApolloAssessmentPropertiesFragment = { instructions?: string | undefined, id: string, intendedGradeLevel: ApolloGradeLevel, title: string, type: ApolloAssessmentType, assessmentComponents: Array<{ type: ApolloAssessmentComponentType, question: { id: string, format: ApolloQuestionFormat, intendedSkillId: string, intendedGradeLevel: ApolloGradeLevel, text: string, answerOptions: Array<{ text: string }> } } | { type: ApolloAssessmentComponentType, questionGroup: { instructions?: string | undefined, title?: string | undefined, content?: string | undefined, questionIds: Array<string>, questions?: Array<{ id: string, format: ApolloQuestionFormat, intendedSkillId: string, intendedGradeLevel: ApolloGradeLevel, text: string, answerOptions: Array<{ text: string }> }> | undefined } }> };

export type ApolloAssessmentKeyPropertiesFragment = { id: string, intendedGradeLevel: ApolloGradeLevel, title: string, type: ApolloAssessmentType, instructions?: string | undefined, publishAt?: number | undefined, assessmentComponents: Array<{ type: ApolloAssessmentComponentType, question: { id: string, format: ApolloQuestionFormat, intendedSkillId: string, intendedGradeLevel: ApolloGradeLevel, text: string, answerOptions: Array<{ text: string, correct: boolean, explanation?: string | undefined }> } } | { type: ApolloAssessmentComponentType, questionGroup: { id: string, instructions?: string | undefined, title?: string | undefined, content?: string | undefined, questionIds: Array<string>, questions?: Array<{ id: string, format: ApolloQuestionFormat, intendedSkillId: string, intendedGradeLevel: ApolloGradeLevel, text: string, answerOptions: Array<{ text: string, correct: boolean, explanation?: string | undefined }> }> | undefined } }> };

export type ApolloQuestionPropertiesFragment = { id: string, format: ApolloQuestionFormat, intendedSkillId: string, intendedGradeLevel: ApolloGradeLevel, text: string, answerOptions: Array<{ text: string }> };

export type ApolloQuestionGroupPropertiesFragment = { instructions?: string | undefined, title?: string | undefined, content?: string | undefined, questionIds: Array<string>, questions?: Array<{ id: string, format: ApolloQuestionFormat, intendedSkillId: string, intendedGradeLevel: ApolloGradeLevel, text: string, answerOptions: Array<{ text: string }> }> | undefined };

export type ApolloQuestionKeyPropertiesFragment = { id: string, format: ApolloQuestionFormat, intendedSkillId: string, intendedGradeLevel: ApolloGradeLevel, text: string, answerOptions: Array<{ text: string, correct: boolean, explanation?: string | undefined }> };

export type ApolloQuestionGroupKeyPropertiesFragment = { id: string, instructions?: string | undefined, title?: string | undefined, content?: string | undefined, questionIds: Array<string>, questions?: Array<{ id: string, format: ApolloQuestionFormat, intendedSkillId: string, intendedGradeLevel: ApolloGradeLevel, text: string, answerOptions: Array<{ text: string, correct: boolean, explanation?: string | undefined }> }> | undefined };

export type ApolloGetAnyClassAssignmentQueryVariables = Exact<{
  assignmentId: Scalars['ID']['input'];
}>;


export type ApolloGetAnyClassAssignmentQuery = { getAnyClassAssignment: { id: string, assessmentId: string, schoolClassId: string, title: string, type: ApolloAssignmentType, startDateTime?: number | undefined, endDateTime?: number | undefined, studentAssignments: Array<{ id: string, studentId: string, classAssignmentId: string, startDateTimeOverride?: number | undefined, endDateTimeOverride?: number | undefined, submittedAt?: number | undefined, startedAt?: number | undefined }> } | { id: string, schoolClassId: string, title: string, type: ApolloAssignmentType, description?: string | undefined, startDateTime?: number | undefined, endDateTime?: number | undefined, studentAssignments: Array<{ id: string, studentId: string, classAssignmentId: string, startDateTimeOverride?: number | undefined, endDateTimeOverride?: number | undefined, startedAt?: number | undefined, submittedAt?: number | undefined }> } };

export type ApolloGetStudentAssignmentsByClassQueryVariables = Exact<{
  input: ApolloGetStudentAssignmentsByClassIdGqlInput;
}>;


export type ApolloGetStudentAssignmentsByClassQuery = { getStudentAssignmentsByClassId: Array<{ id: string, studentId: string, classAssignmentId: string, startDateTimeOverride?: number | undefined, endDateTimeOverride?: number | undefined, startedAt?: number | undefined, submittedAt?: number | undefined, classAssignment?: { id: string, schoolClassId: string, assessmentId: string, title: string, type: ApolloAssignmentType, startDateTime?: number | undefined, endDateTime?: number | undefined } | { id: string, schoolClassId: string, title: string, type: ApolloAssignmentType, description?: string | undefined, startDateTime?: number | undefined, endDateTime?: number | undefined } | undefined }> };

export type ApolloClassMiscellaneousAssignmentsQueryVariables = Exact<{
  classId: Scalars['ID']['input'];
}>;


export type ApolloClassMiscellaneousAssignmentsQuery = { ownClassMiscellaneousAssignments: Array<{ id: string, schoolClassId: string, title: string, type: ApolloAssignmentType, description?: string | undefined, startDateTime?: number | undefined, endDateTime?: number | undefined }> };

export type ApolloGetStudentAssignmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApolloGetStudentAssignmentsQuery = { getStudentAssignments: Array<{ id: string, studentId: string, classAssignmentId: string, startDateTimeOverride?: number | undefined, endDateTimeOverride?: number | undefined, startedAt?: number | undefined, submittedAt?: number | undefined, classAssignment?: { id: string, schoolClassId: string, assessmentId: string, title: string, type: ApolloAssignmentType, startDateTime?: number | undefined, endDateTime?: number | undefined } | { id: string, schoolClassId: string, title: string, type: ApolloAssignmentType, description?: string | undefined, startDateTime?: number | undefined, endDateTime?: number | undefined } | undefined }> };

export type ApolloGetLoggedInStudentAssignmentQueryVariables = Exact<{
  studentAssignmentId: Scalars['ID']['input'];
}>;


export type ApolloGetLoggedInStudentAssignmentQuery = { getLoggedInStudentAssignment: { id: string, studentId: string, classAssignmentId: string, startDateTimeOverride?: number | undefined, endDateTimeOverride?: number | undefined, startedAt?: number | undefined, submittedAt?: number | undefined, classAssignment?: { id: string, schoolClassId: string, assessmentId: string, title: string, type: ApolloAssignmentType, startDateTime?: number | undefined, endDateTime?: number | undefined } | { id: string, schoolClassId: string, title: string, type: ApolloAssignmentType, description?: string | undefined, startDateTime?: number | undefined, endDateTime?: number | undefined } | undefined } };

export type ApolloClassAssessmentAssignmentsQueryVariables = Exact<{
  classId: Scalars['ID']['input'];
}>;


export type ApolloClassAssessmentAssignmentsQuery = { classAssessmentAssignments: Array<{ id: string, assessmentId: string, schoolClassId: string, title: string, type: ApolloAssignmentType, startDateTime?: number | undefined, endDateTime?: number | undefined, studentAssignments: Array<{ id: string, studentId: string, classAssignmentId: string, startDateTimeOverride?: number | undefined, endDateTimeOverride?: number | undefined, submittedAt?: number | undefined, startedAt?: number | undefined }> }> };

export type ApolloCreateClassAssessmentAssignmentMutationVariables = Exact<{
  input: ApolloCreateClassAssessmentAssignmentInput;
}>;


export type ApolloCreateClassAssessmentAssignmentMutation = { createClassAssessmentAssignment: { id: string, assessmentId: string, schoolClassId: string, title: string, type: ApolloAssignmentType, startDateTime?: number | undefined, endDateTime?: number | undefined, studentAssignments: Array<{ id: string, studentId: string, classAssignmentId: string, startDateTimeOverride?: number | undefined, endDateTimeOverride?: number | undefined, submittedAt?: number | undefined, startedAt?: number | undefined }> } };

export type ApolloCreateOwnClassMiscellaneousAssignmentMutationVariables = Exact<{
  input: ApolloCreateClassMiscellaneousAssignmentInput;
}>;


export type ApolloCreateOwnClassMiscellaneousAssignmentMutation = { createOwnClassMiscellaneousAssignment: { id: string, schoolClassId: string, title: string, type: ApolloAssignmentType, description?: string | undefined, startDateTime?: number | undefined, endDateTime?: number | undefined, studentAssignments: Array<{ id: string, studentId: string, classAssignmentId: string, startDateTimeOverride?: number | undefined, endDateTimeOverride?: number | undefined, startedAt?: number | undefined, submittedAt?: number | undefined }> } };

export type ApolloStartLoggedInStudentMiscellaneousAssignmentMutationVariables = Exact<{
  studentAssignmentId: Scalars['ID']['input'];
}>;


export type ApolloStartLoggedInStudentMiscellaneousAssignmentMutation = { startLoggedInStudentMiscellaneousAssignment: { id: string, studentId: string, classAssignmentId: string, startDateTimeOverride?: number | undefined, endDateTimeOverride?: number | undefined, startedAt?: number | undefined, submittedAt?: number | undefined, classAssignment?: { id: string, schoolClassId: string, assessmentId: string, title: string, type: ApolloAssignmentType, startDateTime?: number | undefined, endDateTime?: number | undefined } | { id: string, schoolClassId: string, title: string, type: ApolloAssignmentType, description?: string | undefined, startDateTime?: number | undefined, endDateTime?: number | undefined } | undefined } };

export type ApolloSubmitLoggedInStudentAssignmentMutationVariables = Exact<{
  studentAssignmentId: Scalars['ID']['input'];
}>;


export type ApolloSubmitLoggedInStudentAssignmentMutation = { submitLoggedInStudentAssignment: { id: string, studentId: string, classAssignmentId: string, startDateTimeOverride?: number | undefined, endDateTimeOverride?: number | undefined, startedAt?: number | undefined, submittedAt?: number | undefined, classAssignment?: { id: string, schoolClassId: string, assessmentId: string, title: string, type: ApolloAssignmentType, startDateTime?: number | undefined, endDateTime?: number | undefined } | { id: string, schoolClassId: string, title: string, type: ApolloAssignmentType, description?: string | undefined, startDateTime?: number | undefined, endDateTime?: number | undefined } | undefined } };

export type ApolloUpdateClassAssessmentAssignmentMutationVariables = Exact<{
  input: ApolloUpdateClassAssessmentAssignmentInput;
}>;


export type ApolloUpdateClassAssessmentAssignmentMutation = { updateClassAssessmentAssignment: { id: string, assessmentId: string, schoolClassId: string, title: string, type: ApolloAssignmentType, startDateTime?: number | undefined, endDateTime?: number | undefined, studentAssignments: Array<{ id: string, studentId: string, classAssignmentId: string, startDateTimeOverride?: number | undefined, endDateTimeOverride?: number | undefined, submittedAt?: number | undefined, startedAt?: number | undefined }> } };

export type ApolloUpdateOwnClassMiscellaneousAssignmentMutationVariables = Exact<{
  input: ApolloUpdateClassMiscellaneousAssignmentInput;
}>;


export type ApolloUpdateOwnClassMiscellaneousAssignmentMutation = { updateOwnClassMiscellaneousAssignment: { id: string, schoolClassId: string, title: string, type: ApolloAssignmentType, description?: string | undefined, startDateTime?: number | undefined, endDateTime?: number | undefined, studentAssignments: Array<{ id: string, studentId: string, classAssignmentId: string, startDateTimeOverride?: number | undefined, endDateTimeOverride?: number | undefined, startedAt?: number | undefined, submittedAt?: number | undefined }> } };

export type ApolloDeleteClassAssessmentAssignmentMutationVariables = Exact<{
  classAssessmentAssignmentId: Scalars['ID']['input'];
}>;


export type ApolloDeleteClassAssessmentAssignmentMutation = { deleteClassAssessmentAssignment: { id: string } };

export type ApolloClassAssessmentAssignmentPropertiesFragment = { id: string, assessmentId: string, schoolClassId: string, title: string, type: ApolloAssignmentType, startDateTime?: number | undefined, endDateTime?: number | undefined, studentAssignments: Array<{ id: string, studentId: string, classAssignmentId: string, startDateTimeOverride?: number | undefined, endDateTimeOverride?: number | undefined, submittedAt?: number | undefined, startedAt?: number | undefined }> };

export type ApolloClassMiscellaneousAssignmentPropertiesFragment = { id: string, schoolClassId: string, title: string, type: ApolloAssignmentType, description?: string | undefined, startDateTime?: number | undefined, endDateTime?: number | undefined, studentAssignments: Array<{ id: string, studentId: string, classAssignmentId: string, startDateTimeOverride?: number | undefined, endDateTimeOverride?: number | undefined, startedAt?: number | undefined, submittedAt?: number | undefined }> };

export type ApolloStudentAssignmentPropertiesFragment = { id: string, studentId: string, classAssignmentId: string, startDateTimeOverride?: number | undefined, endDateTimeOverride?: number | undefined, startedAt?: number | undefined, submittedAt?: number | undefined, classAssignment?: { id: string, schoolClassId: string, assessmentId: string, title: string, type: ApolloAssignmentType, startDateTime?: number | undefined, endDateTime?: number | undefined } | { id: string, schoolClassId: string, title: string, type: ApolloAssignmentType, description?: string | undefined, startDateTime?: number | undefined, endDateTime?: number | undefined } | undefined };

export type ApolloDashboardConfigurationQueryVariables = Exact<{
  applicationType: ApolloUserApplication;
}>;


export type ApolloDashboardConfigurationQuery = { dashboardConfiguration: { id: string, applicationType: ApolloUserApplication, createdAt: number, updatedAt: number, teacherSpotlight?: { name: string, quote: string, photo?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined } | undefined, announcements?: Array<{ title: string, text?: string | undefined, link?: string | undefined, target?: string | undefined, emoji?: string | undefined }> | undefined } };

export type ApolloUpdateDashboardConfigurationMutationVariables = Exact<{
  input: ApolloDashboardConfigurationInput;
}>;


export type ApolloUpdateDashboardConfigurationMutation = { updateDashboardConfiguration: { id: string, applicationType: ApolloUserApplication, createdAt: number, updatedAt: number, teacherSpotlight?: { name: string, quote: string, photo?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined } | undefined, announcements?: Array<{ title: string, text?: string | undefined, link?: string | undefined, target?: string | undefined, emoji?: string | undefined }> | undefined } };

export type ApolloFullSchoolDashboardConfigurationFragment = { id: string, applicationType: ApolloUserApplication, createdAt: number, updatedAt: number, teacherSpotlight?: { name: string, quote: string, photo?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined } | undefined, announcements?: Array<{ title: string, text?: string | undefined, link?: string | undefined, target?: string | undefined, emoji?: string | undefined }> | undefined };

export type ApolloTeacherSpotlightFragment = { name: string, quote: string, photo?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined };

export type ApolloAnnouncementFragment = { title: string, text?: string | undefined, link?: string | undefined, target?: string | undefined, emoji?: string | undefined };

export type ApolloDeleteMediaResourceMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ApolloDeleteMediaResourceMutation = { deleteMediaResource: { id: string, typename: string } };

export type ApolloCreateMediaResourceMutationVariables = Exact<{
  input: ApolloMediaInput;
}>;


export type ApolloCreateMediaResourceMutation = { createMediaResource: { id: string, title: string, description?: string | undefined, accessibleByStudents?: boolean | undefined, accessibleByTeachers?: boolean | undefined, includeInStudentLibrary?: boolean | undefined, includeInTeacherPdLibrary?: boolean | undefined, createdAt: number, updatedAt: number, includedObject: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean }, includedVttObject?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, includedPoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, programThumbnailPoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, thumbnailPoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, fullSizePoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined } };

export type ApolloUpdateMediaResourceMutationVariables = Exact<{
  input: ApolloMediaInput;
}>;


export type ApolloUpdateMediaResourceMutation = { updateMediaResource: { id: string, title: string, description?: string | undefined, accessibleByStudents?: boolean | undefined, accessibleByTeachers?: boolean | undefined, includeInStudentLibrary?: boolean | undefined, includeInTeacherPdLibrary?: boolean | undefined, createdAt: number, updatedAt: number, includedObject: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean }, includedVttObject?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, includedPoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, programThumbnailPoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, thumbnailPoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, fullSizePoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined } };

export type ApolloGetTeachersLibraryListViewQueryVariables = Exact<{ [key: string]: never; }>;


export type ApolloGetTeachersLibraryListViewQuery = { getMediaResources: Array<{ id: string, title: string, description?: string | undefined, accessibleByStudents?: boolean | undefined, accessibleByTeachers?: boolean | undefined, includeInStudentLibrary?: boolean | undefined, includeInTeacherPdLibrary?: boolean | undefined, createdAt: number, updatedAt: number, includedObject: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean }, includedVttObject?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, thumbnailPoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined }> };

export type ApolloGetStudentsLibraryListViewQueryVariables = Exact<{ [key: string]: never; }>;


export type ApolloGetStudentsLibraryListViewQuery = { getMediaResources: Array<{ id: string, title: string, description?: string | undefined, accessibleByStudents?: boolean | undefined, accessibleByTeachers?: boolean | undefined, includeInStudentLibrary?: boolean | undefined, includeInTeacherPdLibrary?: boolean | undefined, createdAt: number, updatedAt: number, includedObject: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean }, includedVttObject?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, thumbnailPoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined }> };

export type ApolloGetOtherLibraryListViewQueryVariables = Exact<{ [key: string]: never; }>;


export type ApolloGetOtherLibraryListViewQuery = { getMediaResources: Array<{ id: string, title: string, description?: string | undefined, accessibleByStudents?: boolean | undefined, accessibleByTeachers?: boolean | undefined, includeInStudentLibrary?: boolean | undefined, includeInTeacherPdLibrary?: boolean | undefined, createdAt: number, updatedAt: number, includedObject: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean }, includedVttObject?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, thumbnailPoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined }> };

export type ApolloGetMediaResourceQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ApolloGetMediaResourceQuery = { getMediaResource: { id: string, title: string, description?: string | undefined, accessibleByStudents?: boolean | undefined, accessibleByTeachers?: boolean | undefined, includeInStudentLibrary?: boolean | undefined, includeInTeacherPdLibrary?: boolean | undefined, createdAt: number, updatedAt: number, includedObject: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean }, includedVttObject?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, includedPoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, programThumbnailPoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, thumbnailPoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, fullSizePoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined } };

export type ApolloGetMediaResourceDetailsViewQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ApolloGetMediaResourceDetailsViewQuery = { getMediaResource: { id: string, title: string, description?: string | undefined, accessibleByStudents?: boolean | undefined, accessibleByTeachers?: boolean | undefined, includeInStudentLibrary?: boolean | undefined, includeInTeacherPdLibrary?: boolean | undefined, createdAt: number, updatedAt: number, includedObject: { signedDownloadUrl?: string | undefined, id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean }, includedVttObject?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, fullSizePoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined } };

export type ApolloGetMediaResourcesByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type ApolloGetMediaResourcesByIdsQuery = { getMediaResourcesByIds: Array<{ id: string, title: string, description?: string | undefined, accessibleByStudents?: boolean | undefined, accessibleByTeachers?: boolean | undefined, includeInStudentLibrary?: boolean | undefined, includeInTeacherPdLibrary?: boolean | undefined, createdAt: number, updatedAt: number, includedObject: { signedDownloadUrl?: string | undefined, id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean }, includedVttObject?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, fullSizePoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, programThumbnailPoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined }> };

export type ApolloGetProgramMediaResourceQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ApolloGetProgramMediaResourceQuery = { getMediaResource: { id: string, title: string, description?: string | undefined, accessibleByStudents?: boolean | undefined, accessibleByTeachers?: boolean | undefined, includeInStudentLibrary?: boolean | undefined, includeInTeacherPdLibrary?: boolean | undefined, createdAt: number, updatedAt: number, includedObject: { signedDownloadUrl?: string | undefined, id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean }, includedVttObject?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, fullSizePoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, programThumbnailPoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined } };

export type ApolloGetWelcomeVideoUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type ApolloGetWelcomeVideoUrlQuery = { getMediaResource: { includedObject: { name: string, signedDownloadUrl?: string | undefined }, includedVttObject?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, fullSizePoster?: { storageKey: string } | undefined } };

export type ApolloStorageObjectPropertiesFragment = { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean };

export type ApolloMediaObjectFragment = { id: string, title: string, description?: string | undefined, accessibleByStudents?: boolean | undefined, accessibleByTeachers?: boolean | undefined, includeInStudentLibrary?: boolean | undefined, includeInTeacherPdLibrary?: boolean | undefined, createdAt: number, updatedAt: number };

export type ApolloFullMediaObjectFragment = { id: string, title: string, description?: string | undefined, accessibleByStudents?: boolean | undefined, accessibleByTeachers?: boolean | undefined, includeInStudentLibrary?: boolean | undefined, includeInTeacherPdLibrary?: boolean | undefined, createdAt: number, updatedAt: number, includedObject: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean }, includedVttObject?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, includedPoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, programThumbnailPoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, thumbnailPoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, fullSizePoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined };

export type ApolloListMediaObjectFragment = { id: string, title: string, description?: string | undefined, accessibleByStudents?: boolean | undefined, accessibleByTeachers?: boolean | undefined, includeInStudentLibrary?: boolean | undefined, includeInTeacherPdLibrary?: boolean | undefined, createdAt: number, updatedAt: number, includedObject: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean }, includedVttObject?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, thumbnailPoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined };

export type ApolloProgramMediaObjectFragment = { id: string, title: string, description?: string | undefined, accessibleByStudents?: boolean | undefined, accessibleByTeachers?: boolean | undefined, includeInStudentLibrary?: boolean | undefined, includeInTeacherPdLibrary?: boolean | undefined, createdAt: number, updatedAt: number, includedObject: { signedDownloadUrl?: string | undefined, id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean }, includedVttObject?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, fullSizePoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, programThumbnailPoster?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined };

export type ApolloCreatePlannerTopicMutationVariables = Exact<{
  plannerTopic: ApolloCreatePlannerTopicInput;
}>;


export type ApolloCreatePlannerTopicMutation = { createPlannerTopic: { id: string, schoolClassId: string, title: string, sortOrder: number } };

export type ApolloUpdatePlannerTopicMutationVariables = Exact<{
  plannerTopic: ApolloPlannerTopicInput;
}>;


export type ApolloUpdatePlannerTopicMutation = { updatePlannerTopic: { id: string, schoolClassId: string, title: string, sortOrder: number } };

export type ApolloDeletePlannerTopicMutationVariables = Exact<{
  plannerTopicId: Scalars['String']['input'];
}>;


export type ApolloDeletePlannerTopicMutation = { deletePlannerTopic: { id: string } };

export type ApolloCreatePlannerBlockMutationVariables = Exact<{
  plannerBlock: ApolloCreatePlannerBlockInput;
}>;


export type ApolloCreatePlannerBlockMutation = { createPlannerBlock: { id: string, title: string, resourceId?: string | undefined, description?: string | undefined, color: string, startingSchoolDay: number, endingSchoolDay: number, schoolClassId: string, plannerTopicId: string } };

export type ApolloUpdatePlannerBlockMutationVariables = Exact<{
  plannerBlock: ApolloPlannerBlockInput;
}>;


export type ApolloUpdatePlannerBlockMutation = { updatePlannerBlock: { id: string, title: string, resourceId?: string | undefined, description?: string | undefined, color: string, startingSchoolDay: number, endingSchoolDay: number, schoolClassId: string, plannerTopicId: string } };

export type ApolloDeletePlannerBlockMutationVariables = Exact<{
  plannerBlockId: Scalars['String']['input'];
}>;


export type ApolloDeletePlannerBlockMutation = { deletePlannerBlock: { id: string } };

export type ApolloCreatePlannerNoteMutationVariables = Exact<{
  input: ApolloCreatePlannerNoteInput;
}>;


export type ApolloCreatePlannerNoteMutation = { createPlannerNote: { id: string, body?: string | undefined, schoolClassId: string, plannerBlockId?: string | undefined, plannerBlockOffset?: number | undefined } };

export type ApolloUpdatePlannerNoteMutationVariables = Exact<{
  input: ApolloUpdatePlannerNoteInput;
}>;


export type ApolloUpdatePlannerNoteMutation = { updatePlannerNote: { id: string, body?: string | undefined, schoolClassId: string, plannerBlockId?: string | undefined, plannerBlockOffset?: number | undefined } };

export type ApolloDeletePlannerNoteMutationVariables = Exact<{
  plannerNoteId: Scalars['String']['input'];
}>;


export type ApolloDeletePlannerNoteMutation = { deletePlannerNote: { id: string } };

export type ApolloPlannerTopicFragmentFragment = { id: string, schoolClassId: string, title: string, sortOrder: number };

export type ApolloPlannerBlockFragmentFragment = { id: string, title: string, resourceId?: string | undefined, description?: string | undefined, color: string, startingSchoolDay: number, endingSchoolDay: number, schoolClassId: string, plannerTopicId: string };

export type ApolloPlannerNoteFragmentFragment = { id: string, body?: string | undefined, schoolClassId: string, plannerBlockId?: string | undefined, plannerBlockOffset?: number | undefined };

export type ApolloGetResourceQueryVariables = Exact<{
  resourceId: Scalars['String']['input'];
}>;


export type ApolloGetResourceQuery = { getResource: { id: string, title: string, types: Array<ApolloResourceType>, skillIds: Array<string>, gradeLevels: Array<ApolloGradeLevel>, references?: string | undefined, minimumHoursEstimate?: number | undefined, maximumHoursEstimate?: number | undefined, description: string, keywords?: string | undefined, popularity?: number | undefined, hideInSearchResults: boolean, originalImage?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, fullSizeImage?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, thumbnailImage?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, includedObjects: Array<{ path?: string | undefined, storageObject: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } }> } };

export type ApolloSearchResourcesQueryVariables = Exact<{
  input: ApolloResourceSearchInput;
}>;


export type ApolloSearchResourcesQuery = { searchResources: { numberOfResults: number, resources: Array<{ id: string, title: string, types: Array<ApolloResourceType>, skillIds: Array<string>, gradeLevels: Array<ApolloGradeLevel>, references?: string | undefined, minimumHoursEstimate?: number | undefined, maximumHoursEstimate?: number | undefined, description: string, keywords?: string | undefined, hideInSearchResults: boolean, thumbnailImage?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined }>, availableFilters: Array<{ property: ApolloFilterableProperty, filters: Array<{ value: string, count: number }> }> } };

export type ApolloCreateResourceMutationVariables = Exact<{
  input: ApolloResourceInput;
}>;


export type ApolloCreateResourceMutation = { createResource: { id: string, title: string, types: Array<ApolloResourceType>, skillIds: Array<string>, gradeLevels: Array<ApolloGradeLevel>, references?: string | undefined, minimumHoursEstimate?: number | undefined, maximumHoursEstimate?: number | undefined, description: string, keywords?: string | undefined, popularity?: number | undefined, hideInSearchResults: boolean, originalImage?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, fullSizeImage?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, thumbnailImage?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, includedObjects: Array<{ path?: string | undefined, storageObject: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } }> } };

export type ApolloUpdateResourceMutationVariables = Exact<{
  input: ApolloResourceInput;
}>;


export type ApolloUpdateResourceMutation = { updateResource: { id: string, title: string, types: Array<ApolloResourceType>, skillIds: Array<string>, gradeLevels: Array<ApolloGradeLevel>, references?: string | undefined, minimumHoursEstimate?: number | undefined, maximumHoursEstimate?: number | undefined, description: string, keywords?: string | undefined, popularity?: number | undefined, hideInSearchResults: boolean, originalImage?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, fullSizeImage?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, thumbnailImage?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, includedObjects: Array<{ path?: string | undefined, storageObject: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } }> } };

export type ApolloDeleteResourceMutationVariables = Exact<{
  resourceId: Scalars['String']['input'];
}>;


export type ApolloDeleteResourceMutation = { deleteResource: { id: string } };

export type ApolloAddFilesToResourceMutationVariables = Exact<{
  input: ApolloResourceFilesInput;
}>;


export type ApolloAddFilesToResourceMutation = { addFilesToResource: { id: string, title: string, types: Array<ApolloResourceType>, skillIds: Array<string>, gradeLevels: Array<ApolloGradeLevel>, references?: string | undefined, minimumHoursEstimate?: number | undefined, maximumHoursEstimate?: number | undefined, description: string, keywords?: string | undefined, popularity?: number | undefined, hideInSearchResults: boolean, includedObjects: Array<{ path?: string | undefined, storageObject: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } }>, fullSizeImage?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, thumbnailImage?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined } };

export type ApolloDeleteFilesFromResourceMutationVariables = Exact<{
  resourceId: Scalars['String']['input'];
}>;


export type ApolloDeleteFilesFromResourceMutation = { deleteFilesFromResource: { id: string, title: string, types: Array<ApolloResourceType>, skillIds: Array<string>, gradeLevels: Array<ApolloGradeLevel>, references?: string | undefined, minimumHoursEstimate?: number | undefined, maximumHoursEstimate?: number | undefined, description: string, keywords?: string | undefined, popularity?: number | undefined, hideInSearchResults: boolean, includedObjects: Array<{ path?: string | undefined, storageObject: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } }>, fullSizeImage?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, thumbnailImage?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined } };

export type ApolloIndexResourceMutationVariables = Exact<{
  resourceId: Scalars['String']['input'];
}>;


export type ApolloIndexResourceMutation = { indexResource: { id: string, title: string, types: Array<ApolloResourceType>, skillIds: Array<string>, gradeLevels: Array<ApolloGradeLevel>, references?: string | undefined, minimumHoursEstimate?: number | undefined, maximumHoursEstimate?: number | undefined, description: string, keywords?: string | undefined, popularity?: number | undefined, hideInSearchResults: boolean, fullSizeImage?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, thumbnailImage?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, includedObjects: Array<{ path?: string | undefined, storageObject: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } }> } };

export type ApolloReindexAllResourcesMutationVariables = Exact<{ [key: string]: never; }>;


export type ApolloReindexAllResourcesMutation = { reindexAllResources: boolean };

export type ApolloBaseResourcePropertiesFragment = { id: string, title: string, types: Array<ApolloResourceType>, skillIds: Array<string>, gradeLevels: Array<ApolloGradeLevel>, references?: string | undefined, minimumHoursEstimate?: number | undefined, maximumHoursEstimate?: number | undefined, description: string, keywords?: string | undefined, popularity?: number | undefined, hideInSearchResults: boolean, fullSizeImage?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, thumbnailImage?: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } | undefined, includedObjects: Array<{ path?: string | undefined, storageObject: { id: string, name: string, storageKey: string, mimeType?: string | undefined, publiclyAccessible: boolean } }> };

export type ApolloSearchSchoolAccountsQueryVariables = Exact<{
  searchCriteria: ApolloPaginatedSearchCriteria;
}>;


export type ApolloSearchSchoolAccountsQuery = { schoolAccounts: { pageIndex: number, pageSize: number, searchTerm: string, totalCount: number, items: Array<{ id: string, fullName: string, firstName: string, lastName: string, email: string, active: boolean, pending: boolean, gradesTaught: Array<ApolloGradeLevel>, isIndependent: boolean, nClasses: number, nStudents: number, createdAt: number, school?: { name?: string | undefined, location?: { city?: string | undefined, state?: string | undefined } | undefined } | undefined }> } };

export type ApolloGetSchoolUserDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ApolloGetSchoolUserDetailsQuery = { schoolUserDetails?: { id: string, active: boolean, avatar?: string | undefined, email: string, firstName: string, fullName: string, lastName: string, pending: boolean, phone?: string | undefined, additionalSchoolUserData?: { gradesTaught?: Array<ApolloGradeLevel> | undefined, jobTitle?: ApolloSchoolJobTitle | undefined, kajabiMemberId?: number | undefined, plan: ApolloSchoolAccountPlan, roles: Array<ApolloSchoolAccountRole>, schoolCustomerId?: string | undefined } | undefined, statusChangeEvents: Array<{ type: string, changedAt: number, changedBy?: string | undefined }>, school?: { gradesOffered?: Array<ApolloGradeLevel> | undefined, id?: string | undefined, isCharter?: boolean | undefined, level?: string | undefined, name?: string | undefined, phone?: string | undefined, schoolType?: string | undefined, website?: string | undefined, location?: { street1?: string | undefined, street2?: string | undefined, street3?: string | undefined, city?: string | undefined, state?: string | undefined, postalCode?: string | undefined } | undefined } | undefined, surveyResults?: Array<{ question: { text: string, options: { format: ApolloSurveyQuestionFormat, hint?: string | undefined } | { format: ApolloSurveyQuestionFormat, hint?: string | undefined } }, response: { format: ApolloSurveyQuestionFormat, answer?: { text: string } | { text: string } | undefined, answers?: Array<{ text: string } | { text: string }> | undefined } }> | undefined } | undefined };

export type ApolloSchoolAccountPropertiesFragment = { id: string, fullName: string, firstName: string, lastName: string, email: string, active: boolean, pending: boolean, gradesTaught: Array<ApolloGradeLevel>, isIndependent: boolean, nClasses: number, nStudents: number, createdAt: number, school?: { name?: string | undefined, location?: { city?: string | undefined, state?: string | undefined } | undefined } | undefined };

export type ApolloSchoolUserDetailPropertiesFragment = { id: string, active: boolean, avatar?: string | undefined, email: string, firstName: string, fullName: string, lastName: string, pending: boolean, phone?: string | undefined, additionalSchoolUserData?: { gradesTaught?: Array<ApolloGradeLevel> | undefined, jobTitle?: ApolloSchoolJobTitle | undefined, kajabiMemberId?: number | undefined, plan: ApolloSchoolAccountPlan, roles: Array<ApolloSchoolAccountRole>, schoolCustomerId?: string | undefined } | undefined, statusChangeEvents: Array<{ type: string, changedAt: number, changedBy?: string | undefined }>, school?: { gradesOffered?: Array<ApolloGradeLevel> | undefined, id?: string | undefined, isCharter?: boolean | undefined, level?: string | undefined, name?: string | undefined, phone?: string | undefined, schoolType?: string | undefined, website?: string | undefined, location?: { street1?: string | undefined, street2?: string | undefined, street3?: string | undefined, city?: string | undefined, state?: string | undefined, postalCode?: string | undefined } | undefined } | undefined, surveyResults?: Array<{ question: { text: string, options: { format: ApolloSurveyQuestionFormat, hint?: string | undefined } | { format: ApolloSurveyQuestionFormat, hint?: string | undefined } }, response: { format: ApolloSurveyQuestionFormat, answer?: { text: string } | { text: string } | undefined, answers?: Array<{ text: string } | { text: string }> | undefined } }> | undefined };

export type ApolloSchoolClassesByTeacherIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ApolloSchoolClassesByTeacherIdQuery = { schoolClassesByTeacherId: Array<{ id: string, title: string, period?: number | undefined, gradeLevel: ApolloGradeLevel, firstDay: number, lastDay: number, color: string, skillIds: Array<string>, studentIds: Array<string>, teacherId: string }> };

export type ApolloTeacherSchoolClassesQueryVariables = Exact<{ [key: string]: never; }>;


export type ApolloTeacherSchoolClassesQuery = { teacherSchoolClasses: Array<{ id: string, title: string, period?: number | undefined, gradeLevel: ApolloGradeLevel, firstDay: number, lastDay: number, color: string, skillIds: Array<string>, studentIds: Array<string>, teacherId: string }> };

export type ApolloTeacherSchoolClassesWithDayPlansQueryVariables = Exact<{
  dateRangeFilter: ApolloTeacherSchoolClassesFilterInput;
}>;


export type ApolloTeacherSchoolClassesWithDayPlansQuery = { teacherSchoolClasses: Array<{ id: string, title: string, period?: number | undefined, gradeLevel: ApolloGradeLevel, firstDay: number, lastDay: number, color: string, skillIds: Array<string>, studentIds: Array<string>, teacherId: string, plannerTopics?: Array<{ id: string, schoolClassId: string, title: string, sortOrder: number }> | undefined, plannerBlocks?: Array<{ id: string, title: string, resourceId?: string | undefined, description?: string | undefined, color: string, startingSchoolDay: number, endingSchoolDay: number, schoolClassId: string, plannerTopicId: string }> | undefined, plannerNotes?: Array<{ id: string, body?: string | undefined, schoolClassId: string, plannerBlockId?: string | undefined, plannerBlockOffset?: number | undefined }> | undefined }> };

export type ApolloSchoolClassWithoutSequenceQueryVariables = Exact<{
  schoolClassId: Scalars['ID']['input'];
}>;


export type ApolloSchoolClassWithoutSequenceQuery = { schoolClass: { id: string, title: string, period?: number | undefined, gradeLevel: ApolloGradeLevel, firstDay: number, lastDay: number, color: string, skillIds: Array<string>, studentIds: Array<string>, teacherId: string } };

export type ApolloSchoolClassWithSequenceQueryVariables = Exact<{
  schoolClassId: Scalars['ID']['input'];
}>;


export type ApolloSchoolClassWithSequenceQuery = { schoolClass: { id: string, title: string, period?: number | undefined, gradeLevel: ApolloGradeLevel, firstDay: number, lastDay: number, color: string, skillIds: Array<string>, studentIds: Array<string>, teacherId: string, plannerTopics?: Array<{ id: string, schoolClassId: string, title: string, sortOrder: number }> | undefined, plannerBlocks?: Array<{ id: string, title: string, resourceId?: string | undefined, description?: string | undefined, color: string, startingSchoolDay: number, endingSchoolDay: number, schoolClassId: string, plannerTopicId: string }> | undefined } };

export type ApolloSchoolClassWithRosterQueryVariables = Exact<{
  schoolClassId: Scalars['ID']['input'];
}>;


export type ApolloSchoolClassWithRosterQuery = { schoolClass: { id: string, title: string, period?: number | undefined, gradeLevel: ApolloGradeLevel, firstDay: number, lastDay: number, color: string, skillIds: Array<string>, studentIds: Array<string>, teacherId: string, students: Array<{ acknowledgedPrivacyPolicyAt?: number | undefined, active: boolean, agreedToTermsAndConditionsAt?: number | undefined, avatar?: string | undefined, createdAt: number, email: string, firstName: string, fullName: string, id: string, lastName: string, pending: boolean, phone?: string | undefined, updatedAt: number, additionalSchoolUserData?: { plan: ApolloSchoolAccountPlan, roles: Array<ApolloSchoolAccountRole>, gradesTaught?: Array<ApolloGradeLevel> | undefined, jobTitle?: ApolloSchoolJobTitle | undefined, kajabiMemberId?: number | undefined, registrationSurveyCompleted: boolean, schoolCity?: string | undefined, schoolCountry?: string | undefined, schoolCustomerId?: string | undefined, schoolId?: string | undefined, schoolName?: string | undefined, schoolState?: string | undefined, userType?: ApolloSchoolUserType | undefined, watchedWelcomeVideo?: boolean | undefined } | undefined, additionalStudentUserData?: { schoolCustomerId: string, studentId: string } | undefined }> } };

export type ApolloSchoolClassWithDetailsQueryVariables = Exact<{
  schoolClassId: Scalars['ID']['input'];
}>;


export type ApolloSchoolClassWithDetailsQuery = { schoolClass: { id: string, title: string, period?: number | undefined, gradeLevel: ApolloGradeLevel, firstDay: number, lastDay: number, color: string, skillIds: Array<string>, teacherId: string, studentIds: Array<string>, students: Array<{ fullName: string, id: string, active: boolean, firstName: string, lastName: string, email: string, createdAt: number, updatedAt: number, pending: boolean, additionalStudentUserData?: { schoolCustomerId: string, studentId: string } | undefined }> } };

export type ApolloStudentAccessibleSchoolClassesQueryVariables = Exact<{ [key: string]: never; }>;


export type ApolloStudentAccessibleSchoolClassesQuery = { studentAccessibleSchoolClasses: Array<{ id: string, title: string, period?: number | undefined }> };

export type ApolloSchoolClassStudentsQueryVariables = Exact<{
  schoolClassId: Scalars['ID']['input'];
}>;


export type ApolloSchoolClassStudentsQuery = { schoolClass: { students: Array<{ acknowledgedPrivacyPolicyAt?: number | undefined, active: boolean, agreedToTermsAndConditionsAt?: number | undefined, avatar?: string | undefined, createdAt: number, email: string, firstName: string, fullName: string, id: string, lastName: string, pending: boolean, phone?: string | undefined, updatedAt: number, additionalSchoolUserData?: { plan: ApolloSchoolAccountPlan, roles: Array<ApolloSchoolAccountRole>, gradesTaught?: Array<ApolloGradeLevel> | undefined, jobTitle?: ApolloSchoolJobTitle | undefined, kajabiMemberId?: number | undefined, registrationSurveyCompleted: boolean, schoolCity?: string | undefined, schoolCountry?: string | undefined, schoolCustomerId?: string | undefined, schoolId?: string | undefined, schoolName?: string | undefined, schoolState?: string | undefined, userType?: ApolloSchoolUserType | undefined, watchedWelcomeVideo?: boolean | undefined } | undefined, additionalStudentUserData?: { schoolCustomerId: string, studentId: string } | undefined }> } };

export type ApolloCreateSchoolClassMutationVariables = Exact<{
  input: ApolloCreateSchoolClassInput;
}>;


export type ApolloCreateSchoolClassMutation = { createSchoolClass: { id: string, title: string, period?: number | undefined, gradeLevel: ApolloGradeLevel, firstDay: number, lastDay: number, color: string, skillIds: Array<string>, studentIds: Array<string>, teacherId: string } };

export type ApolloDeleteSchoolClassMutationVariables = Exact<{
  schoolClassId: Scalars['ID']['input'];
}>;


export type ApolloDeleteSchoolClassMutation = { deleteSchoolClass: { id: string } };

export type ApolloRemoveStudentFromClassMutationVariables = Exact<{
  input: ApolloRemoveStudentFromClassGqlInput;
}>;


export type ApolloRemoveStudentFromClassMutation = { removeStudentFromClass: { id: string, title: string, period?: number | undefined, gradeLevel: ApolloGradeLevel, firstDay: number, lastDay: number, color: string, skillIds: Array<string>, studentIds: Array<string>, teacherId: string } };

export type ApolloEnrollSchoolClassStudentMutationVariables = Exact<{
  schoolClassId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
}>;


export type ApolloEnrollSchoolClassStudentMutation = { enrollSchoolClassStudent: { id: string, title: string, period?: number | undefined, gradeLevel: ApolloGradeLevel, firstDay: number, lastDay: number, color: string, skillIds: Array<string>, studentIds: Array<string>, teacherId: string } };

export type ApolloUpdateSchoolClassMutationVariables = Exact<{
  input: ApolloUpdateSchoolClassInput;
}>;


export type ApolloUpdateSchoolClassMutation = { updateSchoolClass: { id: string, title: string, period?: number | undefined, gradeLevel: ApolloGradeLevel, firstDay: number, lastDay: number, color: string, skillIds: Array<string>, studentIds: Array<string>, teacherId: string } };

export type ApolloUploadSchoolClassStudentsMutationVariables = Exact<{
  schoolCustomerId: Scalars['ID']['input'];
  classId: Scalars['ID']['input'];
  csvContents: Scalars['String']['input'];
}>;


export type ApolloUploadSchoolClassStudentsMutation = { uploadSchoolClassStudents: { schoolClass: { id: string, title: string, period?: number | undefined, gradeLevel: ApolloGradeLevel, firstDay: number, lastDay: number, color: string, skillIds: Array<string>, studentIds: Array<string>, teacherId: string, students: Array<{ acknowledgedPrivacyPolicyAt?: number | undefined, active: boolean, agreedToTermsAndConditionsAt?: number | undefined, avatar?: string | undefined, createdAt: number, email: string, firstName: string, fullName: string, id: string, lastName: string, pending: boolean, phone?: string | undefined, updatedAt: number, additionalSchoolUserData?: { plan: ApolloSchoolAccountPlan, roles: Array<ApolloSchoolAccountRole>, gradesTaught?: Array<ApolloGradeLevel> | undefined, jobTitle?: ApolloSchoolJobTitle | undefined, kajabiMemberId?: number | undefined, registrationSurveyCompleted: boolean, schoolCity?: string | undefined, schoolCountry?: string | undefined, schoolCustomerId?: string | undefined, schoolId?: string | undefined, schoolName?: string | undefined, schoolState?: string | undefined, userType?: ApolloSchoolUserType | undefined, watchedWelcomeVideo?: boolean | undefined } | undefined, additionalStudentUserData?: { schoolCustomerId: string, studentId: string } | undefined }> }, errors: Array<{ errorMessage: string, rowIndex: number, row: { studentId: string, firstName: string, lastName: string } }> } };

export type ApolloSchoolClassPropertiesFragment = { id: string, title: string, period?: number | undefined, gradeLevel: ApolloGradeLevel, firstDay: number, lastDay: number, color: string, skillIds: Array<string>, studentIds: Array<string>, teacherId: string };

export type ApolloSchoolClassDetailPropertiesFragment = { id: string, title: string, period?: number | undefined, gradeLevel: ApolloGradeLevel, firstDay: number, lastDay: number, color: string, skillIds: Array<string>, teacherId: string, studentIds: Array<string>, students: Array<{ fullName: string, id: string, active: boolean, firstName: string, lastName: string, email: string, createdAt: number, updatedAt: number, pending: boolean, additionalStudentUserData?: { schoolCustomerId: string, studentId: string } | undefined }> };

export type ApolloStudentAccessibleSchoolClassPropertiesFragment = { id: string, title: string, period?: number | undefined };

export type ApolloGetNcesSchoolUploadUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type ApolloGetNcesSchoolUploadUrlQuery = { getNcesSchoolUploadUrl: string };

export type ApolloGetImportedSchoolsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type ApolloGetImportedSchoolsCountQuery = { importedSchoolsCount: number };

export type ApolloGetImportedSchoolQueryVariables = Exact<{
  schoolId: Scalars['String']['input'];
}>;


export type ApolloGetImportedSchoolQuery = { importedSchool?: { id: string, name: string, phone?: string | undefined, website?: string | undefined, schoolType?: string | undefined, level?: string | undefined, isCharter?: boolean | undefined, gradesOffered: Array<ApolloGradeLevel>, createdAt: number, updatedAt: number, schoolId?: string | undefined, importFormat?: string | undefined, updatedStatus?: string | undefined, location?: { street1?: string | undefined, street2?: string | undefined, street3?: string | undefined, city?: string | undefined, state?: string | undefined, postalCode?: string | undefined } | undefined } | undefined };

export type ApolloSearchImportedSchoolsQueryVariables = Exact<{
  searchCriteria: ApolloSchoolSearchCriteria;
  skipExistingCustomers?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ApolloSearchImportedSchoolsQuery = { importedSchools: Array<{ id: string, name: string, phone?: string | undefined, website?: string | undefined, schoolType?: string | undefined, level?: string | undefined, isCharter?: boolean | undefined, gradesOffered: Array<ApolloGradeLevel>, createdAt: number, updatedAt: number, schoolId?: string | undefined, importFormat?: string | undefined, updatedStatus?: string | undefined, location?: { street1?: string | undefined, street2?: string | undefined, street3?: string | undefined, city?: string | undefined, state?: string | undefined, postalCode?: string | undefined } | undefined }> };

export type ApolloSearchSchoolCustomersQueryVariables = Exact<{
  searchCriteria: ApolloSchoolSearchCriteria;
}>;


export type ApolloSearchSchoolCustomersQuery = { schoolCustomers: Array<{ schoolType?: string | undefined, level?: string | undefined, isCharter?: boolean | undefined, gradesOffered: Array<ApolloGradeLevel>, createdAt: number, updatedAt: number, importedSchoolId?: string | undefined, hubspotCompanyId?: string | undefined, id: string, name: string, phone?: string | undefined, website?: string | undefined, location?: { street1?: string | undefined, street2?: string | undefined, street3?: string | undefined, city?: string | undefined, state?: string | undefined, postalCode?: string | undefined } | undefined }> };

export type ApolloSearchStudentSchoolsQueryVariables = Exact<{
  searchCriteria: ApolloSchoolSearchCriteria;
}>;


export type ApolloSearchStudentSchoolsQuery = { studentSchools: Array<{ id: string, name: string, phone?: string | undefined, website?: string | undefined, location?: { street1?: string | undefined, street2?: string | undefined, street3?: string | undefined, city?: string | undefined, state?: string | undefined, postalCode?: string | undefined } | undefined }> };

export type ApolloFindSchoolCustomerByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ApolloFindSchoolCustomerByIdQuery = { schoolCustomer?: { schoolType?: string | undefined, level?: string | undefined, isCharter?: boolean | undefined, gradesOffered: Array<ApolloGradeLevel>, createdAt: number, updatedAt: number, importedSchoolId?: string | undefined, hubspotCompanyId?: string | undefined, id: string, name: string, phone?: string | undefined, website?: string | undefined, location?: { street1?: string | undefined, street2?: string | undefined, street3?: string | undefined, city?: string | undefined, state?: string | undefined, postalCode?: string | undefined } | undefined } | undefined };

export type ApolloFindStudentSchoolByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ApolloFindStudentSchoolByIdQuery = { studentSchool?: { id: string, name: string, phone?: string | undefined, website?: string | undefined, location?: { street1?: string | undefined, street2?: string | undefined, street3?: string | undefined, city?: string | undefined, state?: string | undefined, postalCode?: string | undefined } | undefined } | undefined };

export type ApolloGetUserSchoolAndAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApolloGetUserSchoolAndAccountsQuery = { userSchool?: { schoolType?: string | undefined, level?: string | undefined, isCharter?: boolean | undefined, gradesOffered: Array<ApolloGradeLevel>, createdAt: number, updatedAt: number, importedSchoolId?: string | undefined, hubspotCompanyId?: string | undefined, id: string, name: string, phone?: string | undefined, website?: string | undefined, accounts: Array<{ acknowledgedPrivacyPolicyAt?: number | undefined, active: boolean, agreedToTermsAndConditionsAt?: number | undefined, avatar?: string | undefined, createdAt: number, email: string, firstName: string, fullName: string, id: string, lastName: string, pending: boolean, phone?: string | undefined, updatedAt: number, additionalSchoolUserData?: { plan: ApolloSchoolAccountPlan, roles: Array<ApolloSchoolAccountRole>, gradesTaught?: Array<ApolloGradeLevel> | undefined, jobTitle?: ApolloSchoolJobTitle | undefined, kajabiMemberId?: number | undefined, registrationSurveyCompleted: boolean, schoolCity?: string | undefined, schoolCountry?: string | undefined, schoolCustomerId?: string | undefined, schoolId?: string | undefined, schoolName?: string | undefined, schoolState?: string | undefined, userType?: ApolloSchoolUserType | undefined, watchedWelcomeVideo?: boolean | undefined } | undefined, additionalStudentUserData?: { schoolCustomerId: string, studentId: string } | undefined }>, location?: { street1?: string | undefined, street2?: string | undefined, street3?: string | undefined, city?: string | undefined, state?: string | undefined, postalCode?: string | undefined } | undefined } | undefined };

export type ApolloFindSchoolCustomerAndAccountsByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ApolloFindSchoolCustomerAndAccountsByIdQuery = { schoolCustomer?: { schoolType?: string | undefined, level?: string | undefined, isCharter?: boolean | undefined, gradesOffered: Array<ApolloGradeLevel>, createdAt: number, updatedAt: number, importedSchoolId?: string | undefined, hubspotCompanyId?: string | undefined, id: string, name: string, phone?: string | undefined, website?: string | undefined, accounts: Array<{ acknowledgedPrivacyPolicyAt?: number | undefined, active: boolean, agreedToTermsAndConditionsAt?: number | undefined, avatar?: string | undefined, createdAt: number, email: string, firstName: string, fullName: string, id: string, lastName: string, pending: boolean, phone?: string | undefined, updatedAt: number, additionalSchoolUserData?: { plan: ApolloSchoolAccountPlan, roles: Array<ApolloSchoolAccountRole>, gradesTaught?: Array<ApolloGradeLevel> | undefined, jobTitle?: ApolloSchoolJobTitle | undefined, kajabiMemberId?: number | undefined, registrationSurveyCompleted: boolean, schoolCity?: string | undefined, schoolCountry?: string | undefined, schoolCustomerId?: string | undefined, schoolId?: string | undefined, schoolName?: string | undefined, schoolState?: string | undefined, userType?: ApolloSchoolUserType | undefined, watchedWelcomeVideo?: boolean | undefined } | undefined, additionalStudentUserData?: { schoolCustomerId: string, studentId: string } | undefined }>, location?: { street1?: string | undefined, street2?: string | undefined, street3?: string | undefined, city?: string | undefined, state?: string | undefined, postalCode?: string | undefined } | undefined } | undefined };

export type ApolloAdminFindSchoolCustomerAndAccountsByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ApolloAdminFindSchoolCustomerAndAccountsByIdQuery = { adminSchoolCustomer: { id: string, gradesOffered: Array<ApolloGradeLevel>, name: string, createdAt: number, updatedAt: number, accounts: Array<{ id: string, active: boolean, email: string, firstName: string, fullName: string, lastName: string, pending: boolean, createdAt: number, updatedAt: number, additionalSchoolUserData?: { roles: Array<ApolloSchoolAccountRole>, schoolCustomerId?: string | undefined, schoolId?: string | undefined, plan: ApolloSchoolAccountPlan, gradesTaught?: Array<ApolloGradeLevel> | undefined, registrationSurveyCompleted: boolean } | undefined }> } };

export type ApolloBeginNcesSchoolImportMutationVariables = Exact<{ [key: string]: never; }>;


export type ApolloBeginNcesSchoolImportMutation = { beginNcesSchoolImport: boolean };

export type ApolloCreateSchoolCustomerMutationVariables = Exact<{
  input: ApolloNewSchoolCustomerInput;
}>;


export type ApolloCreateSchoolCustomerMutation = { createSchoolCustomer: { schoolType?: string | undefined, level?: string | undefined, isCharter?: boolean | undefined, gradesOffered: Array<ApolloGradeLevel>, createdAt: number, updatedAt: number, importedSchoolId?: string | undefined, hubspotCompanyId?: string | undefined, id: string, name: string, phone?: string | undefined, website?: string | undefined, location?: { street1?: string | undefined, street2?: string | undefined, street3?: string | undefined, city?: string | undefined, state?: string | undefined, postalCode?: string | undefined } | undefined } };

export type ApolloDeleteSchoolCustomerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ApolloDeleteSchoolCustomerMutation = { deleteSchoolCustomer: { id: string, typename: string } };

export type ApolloUpdateSchoolCustomerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  update: ApolloSchoolCustomerUpdateInput;
}>;


export type ApolloUpdateSchoolCustomerMutation = { updateSchoolCustomer: { schoolType?: string | undefined, level?: string | undefined, isCharter?: boolean | undefined, gradesOffered: Array<ApolloGradeLevel>, createdAt: number, updatedAt: number, importedSchoolId?: string | undefined, hubspotCompanyId?: string | undefined, id: string, name: string, phone?: string | undefined, website?: string | undefined, location?: { street1?: string | undefined, street2?: string | undefined, street3?: string | undefined, city?: string | undefined, state?: string | undefined, postalCode?: string | undefined } | undefined } };

export type ApolloImportedSchoolPropertiesFragment = { id: string, name: string, phone?: string | undefined, website?: string | undefined, schoolType?: string | undefined, level?: string | undefined, isCharter?: boolean | undefined, gradesOffered: Array<ApolloGradeLevel>, createdAt: number, updatedAt: number, schoolId?: string | undefined, importFormat?: string | undefined, updatedStatus?: string | undefined, location?: { street1?: string | undefined, street2?: string | undefined, street3?: string | undefined, city?: string | undefined, state?: string | undefined, postalCode?: string | undefined } | undefined };

export type ApolloSchoolCustomerPropertiesFragment = { schoolType?: string | undefined, level?: string | undefined, isCharter?: boolean | undefined, gradesOffered: Array<ApolloGradeLevel>, createdAt: number, updatedAt: number, importedSchoolId?: string | undefined, hubspotCompanyId?: string | undefined, id: string, name: string, phone?: string | undefined, website?: string | undefined, location?: { street1?: string | undefined, street2?: string | undefined, street3?: string | undefined, city?: string | undefined, state?: string | undefined, postalCode?: string | undefined } | undefined };

export type ApolloStudentSchoolPropertiesFragment = { id: string, name: string, phone?: string | undefined, website?: string | undefined, location?: { street1?: string | undefined, street2?: string | undefined, street3?: string | undefined, city?: string | undefined, state?: string | undefined, postalCode?: string | undefined } | undefined };

export type ApolloAddressPropertiesFragment = { street1?: string | undefined, street2?: string | undefined, street3?: string | undefined, city?: string | undefined, state?: string | undefined, postalCode?: string | undefined };

export type ApolloGetUnpopulatedSkillGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApolloGetUnpopulatedSkillGroupsQuery = { skillGroups: Array<{ id: string, title: string, color: string, sortOrder: number, skillGroupKey: string }> };

export type ApolloGetUnpopulatedSkillsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApolloGetUnpopulatedSkillsQuery = { getAllSkills: Array<{ id: string, title: string, description?: string | undefined, groupId: string, standards?: Array<{ id: string, description: string, code: string, shortCode: string, gradeLevel: ApolloGradeLevel, skillIds: Array<string>, standardSystemId: string }> | undefined }> };

export type ApolloGetSignedUploadUrlQueryVariables = Exact<{
  input: ApolloSignedUrlInput;
}>;


export type ApolloGetSignedUploadUrlQuery = { getSignedUploadUrl: { id: string, signedUrl: string } };

export type ApolloGetSignedDownloadUrlQueryVariables = Exact<{
  input: ApolloSignedUrlInput;
}>;


export type ApolloGetSignedDownloadUrlQuery = { getSignedDownloadUrl: { id: string, signedUrl: string } };

export type ApolloGetSurveyQueryVariables = Exact<{
  surveyTitle: Scalars['String']['input'];
}>;


export type ApolloGetSurveyQuery = { survey: Array<{ id: string, text: string, createdAt: number, updatedAt: number, options: { format: ApolloSurveyQuestionFormat, allowOther?: boolean | undefined, hint?: string | undefined, maxSelect?: number | undefined, minSelect?: number | undefined, answers: Array<{ id: string, text: string }> } | { format: ApolloSurveyQuestionFormat, allowOther?: boolean | undefined, hint?: string | undefined, answers: Array<{ id: string, text: string }> } }> };

export type ApolloSurveyMultipleSelectOptionsPropertiesFragment = { format: ApolloSurveyQuestionFormat, allowOther?: boolean | undefined, hint?: string | undefined, maxSelect?: number | undefined, minSelect?: number | undefined, answers: Array<{ id: string, text: string }> };

export type ApolloSurveySingleSelectOptionsPropertiesFragment = { format: ApolloSurveyQuestionFormat, allowOther?: boolean | undefined, hint?: string | undefined, answers: Array<{ id: string, text: string }> };

export type ApolloSurveyAnswerChoicePropertiesFragment = { id: string, text: string };

export type ApolloSurveyAnswerOtherChoicePropertiesFragment = { text: string };

export type ApolloSurveyMultipleSelectResponsePropertiesFragment = { format: ApolloSurveyQuestionFormat, answers: Array<{ id: string, text: string } | { text: string }> };

export type ApolloSurveySingleSelectResponsePropertiesFragment = { format: ApolloSurveyQuestionFormat, answer: { id: string, text: string } | { text: string } };

export const AccountPropertiesFragmentDoc = gql`
    fragment AccountProperties on Account {
  acknowledgedPrivacyPolicyAt
  active
  agreedToTermsAndConditionsAt
  avatar
  createdAt
  email
  firstName
  fullName
  id
  lastName
  pending
  phone
  updatedAt
  additionalSchoolUserData {
    plan
    roles
    gradesTaught
    jobTitle
    kajabiMemberId
    registrationSurveyCompleted
    schoolCity
    schoolCountry
    schoolCustomerId
    schoolId
    schoolName
    schoolState
    userType
    watchedWelcomeVideo
  }
  additionalStudentUserData {
    schoolCustomerId
    studentId
  }
}
    `;
export const AssessmentSummaryFragmentDoc = gql`
    fragment AssessmentSummary on Assessment {
  id
  intendedGradeLevel
  title
  type
}
    `;
export const QuestionPropertiesFragmentDoc = gql`
    fragment QuestionProperties on AssessmentQuestion {
  id
  format
  intendedSkillId
  intendedGradeLevel
  answerOptions {
    text
  }
  text
}
    `;
export const QuestionGroupPropertiesFragmentDoc = gql`
    fragment QuestionGroupProperties on QuestionGroup {
  instructions
  title
  content
  questionIds
  questions {
    ...QuestionProperties
  }
}
    ${QuestionPropertiesFragmentDoc}`;
export const AssessmentPropertiesFragmentDoc = gql`
    fragment AssessmentProperties on Assessment {
  ...AssessmentSummary
  instructions
  assessmentComponents {
    ... on QuestionGroupAssessmentComponent {
      type
      questionGroup {
        ...QuestionGroupProperties
      }
    }
    ... on QuestionAssessmentComponent {
      type
      question {
        ...QuestionProperties
      }
    }
  }
}
    ${AssessmentSummaryFragmentDoc}
${QuestionGroupPropertiesFragmentDoc}
${QuestionPropertiesFragmentDoc}`;
export const QuestionKeyPropertiesFragmentDoc = gql`
    fragment QuestionKeyProperties on AssessmentQuestionKey {
  id
  format
  intendedSkillId
  intendedGradeLevel
  answerOptions {
    text
    correct
    explanation
  }
  text
}
    `;
export const QuestionGroupKeyPropertiesFragmentDoc = gql`
    fragment QuestionGroupKeyProperties on QuestionGroupKey {
  id
  instructions
  title
  content
  questionIds
  questions {
    ...QuestionKeyProperties
  }
}
    ${QuestionKeyPropertiesFragmentDoc}`;
export const AssessmentKeyPropertiesFragmentDoc = gql`
    fragment AssessmentKeyProperties on AssessmentKey {
  id
  intendedGradeLevel
  title
  type
  instructions
  publishAt
  assessmentComponents {
    ... on QuestionGroupAssessmentComponentKey {
      type
      questionGroup {
        ...QuestionGroupKeyProperties
      }
    }
    ... on QuestionAssessmentComponentKey {
      type
      question {
        ...QuestionKeyProperties
      }
    }
  }
}
    ${QuestionGroupKeyPropertiesFragmentDoc}
${QuestionKeyPropertiesFragmentDoc}`;
export const ClassAssessmentAssignmentPropertiesFragmentDoc = gql`
    fragment ClassAssessmentAssignmentProperties on ClassAssessmentAssignment {
  id
  assessmentId
  schoolClassId
  title
  type
  startDateTime
  endDateTime
  studentAssignments {
    ... on StudentAssignment {
      id
      studentId
      classAssignmentId
      startDateTimeOverride
      endDateTimeOverride
      submittedAt
      startedAt
    }
  }
}
    `;
export const ClassMiscellaneousAssignmentPropertiesFragmentDoc = gql`
    fragment ClassMiscellaneousAssignmentProperties on ClassMiscellaneousAssignment {
  id
  schoolClassId
  title
  type
  description
  startDateTime
  endDateTime
  studentAssignments {
    ... on StudentAssignment {
      id
      studentId
      classAssignmentId
      startDateTimeOverride
      endDateTimeOverride
      startedAt
      submittedAt
    }
  }
}
    `;
export const StudentAssignmentPropertiesFragmentDoc = gql`
    fragment StudentAssignmentProperties on StudentAssignment {
  id
  studentId
  classAssignmentId
  startDateTimeOverride
  endDateTimeOverride
  startedAt
  submittedAt
  classAssignment {
    ... on ClassAssessmentAssignment {
      id
      schoolClassId
      assessmentId
      title
      type
      startDateTime
      endDateTime
    }
    ... on ClassMiscellaneousAssignment {
      id
      schoolClassId
      title
      type
      description
      startDateTime
      endDateTime
    }
  }
}
    `;
export const StorageObjectPropertiesFragmentDoc = gql`
    fragment StorageObjectProperties on StorageObject {
  id
  name
  storageKey
  mimeType
  publiclyAccessible
}
    `;
export const TeacherSpotlightFragmentDoc = gql`
    fragment TeacherSpotlight on TeacherSpotlight {
  name
  photo {
    ...StorageObjectProperties
  }
  quote
}
    ${StorageObjectPropertiesFragmentDoc}`;
export const AnnouncementFragmentDoc = gql`
    fragment Announcement on Announcement {
  title
  text
  link
  target
  emoji
}
    `;
export const FullSchoolDashboardConfigurationFragmentDoc = gql`
    fragment FullSchoolDashboardConfiguration on DashboardConfiguration {
  id
  applicationType
  teacherSpotlight {
    ...TeacherSpotlight
  }
  announcements {
    ...Announcement
  }
  createdAt
  updatedAt
}
    ${TeacherSpotlightFragmentDoc}
${AnnouncementFragmentDoc}`;
export const MediaObjectFragmentDoc = gql`
    fragment MediaObject on Media {
  id
  title
  description
  accessibleByStudents
  accessibleByTeachers
  includeInStudentLibrary
  includeInTeacherPdLibrary
  createdAt
  updatedAt
}
    `;
export const FullMediaObjectFragmentDoc = gql`
    fragment FullMediaObject on Media {
  ...MediaObject
  includedObject {
    ...StorageObjectProperties
  }
  includedVttObject {
    ...StorageObjectProperties
  }
  includedPoster {
    ...StorageObjectProperties
  }
  programThumbnailPoster {
    ...StorageObjectProperties
  }
  thumbnailPoster {
    ...StorageObjectProperties
  }
  fullSizePoster {
    ...StorageObjectProperties
  }
}
    ${MediaObjectFragmentDoc}
${StorageObjectPropertiesFragmentDoc}`;
export const ListMediaObjectFragmentDoc = gql`
    fragment ListMediaObject on Media {
  ...MediaObject
  includedObject {
    ...StorageObjectProperties
  }
  includedVttObject {
    ...StorageObjectProperties
  }
  thumbnailPoster {
    ...StorageObjectProperties
  }
}
    ${MediaObjectFragmentDoc}
${StorageObjectPropertiesFragmentDoc}`;
export const ProgramMediaObjectFragmentDoc = gql`
    fragment ProgramMediaObject on Media {
  ...MediaObject
  includedObject {
    ...StorageObjectProperties
    signedDownloadUrl
  }
  includedVttObject {
    ...StorageObjectProperties
  }
  fullSizePoster {
    ...StorageObjectProperties
  }
  programThumbnailPoster {
    ...StorageObjectProperties
  }
}
    ${MediaObjectFragmentDoc}
${StorageObjectPropertiesFragmentDoc}`;
export const PlannerTopicFragmentFragmentDoc = gql`
    fragment PlannerTopicFragment on PlannerTopic {
  id
  schoolClassId
  title
  sortOrder
}
    `;
export const PlannerBlockFragmentFragmentDoc = gql`
    fragment PlannerBlockFragment on PlannerBlock {
  id
  title
  resourceId
  description
  color
  startingSchoolDay
  endingSchoolDay
  schoolClassId
  plannerTopicId
}
    `;
export const PlannerNoteFragmentFragmentDoc = gql`
    fragment PlannerNoteFragment on PlannerNote {
  id
  body
  schoolClassId
  plannerBlockId
  plannerBlockOffset
}
    `;
export const BaseResourcePropertiesFragmentDoc = gql`
    fragment BaseResourceProperties on Resource {
  id
  title
  types
  skillIds
  gradeLevels
  references
  minimumHoursEstimate
  maximumHoursEstimate
  description
  keywords
  popularity
  hideInSearchResults
  fullSizeImage {
    ...StorageObjectProperties
  }
  thumbnailImage {
    ...StorageObjectProperties
  }
  includedObjects {
    storageObject {
      ...StorageObjectProperties
    }
    path
  }
}
    ${StorageObjectPropertiesFragmentDoc}`;
export const SchoolAccountPropertiesFragmentDoc = gql`
    fragment SchoolAccountProperties on SchoolAccount {
  id
  fullName
  firstName
  lastName
  email
  active
  pending
  school {
    name
    location {
      city
      state
    }
  }
  gradesTaught
  isIndependent
  nClasses
  nStudents
  createdAt
}
    `;
export const SchoolUserDetailPropertiesFragmentDoc = gql`
    fragment SchoolUserDetailProperties on SchoolUserDetails {
  id
  active
  avatar
  email
  firstName
  fullName
  lastName
  pending
  phone
  additionalSchoolUserData {
    gradesTaught
    jobTitle
    kajabiMemberId
    plan
    roles
    schoolCustomerId
  }
  statusChangeEvents {
    type
    changedAt
    changedBy
  }
  school {
    gradesOffered
    id
    isCharter
    level
    name
    phone
    schoolType
    website
    location {
      street1
      street2
      street3
      city
      state
      postalCode
    }
  }
  surveyResults {
    question {
      text
      options {
        ... on SurveySingleSelectOptions {
          format
          hint
        }
        ... on SurveyMultipleSelectOptions {
          format
          hint
        }
      }
    }
    response {
      format
      answer {
        ... on SurveyAnswerChoice {
          text
        }
        ... on SurveyAnswerOtherChoice {
          text
        }
      }
      answers {
        ... on SurveyAnswerChoice {
          text
        }
        ... on SurveyAnswerOtherChoice {
          text
        }
      }
    }
  }
}
    `;
export const SchoolClassPropertiesFragmentDoc = gql`
    fragment SchoolClassProperties on SchoolClass {
  id
  title
  period
  gradeLevel
  firstDay
  lastDay
  color
  skillIds
  studentIds
  teacherId
}
    `;
export const SchoolClassDetailPropertiesFragmentDoc = gql`
    fragment SchoolClassDetailProperties on SchoolClass {
  id
  title
  period
  gradeLevel
  firstDay
  lastDay
  color
  skillIds
  teacherId
  studentIds
  students {
    fullName
    id
    active
    firstName
    lastName
    email
    createdAt
    updatedAt
    pending
    additionalStudentUserData {
      schoolCustomerId
      studentId
    }
  }
}
    `;
export const StudentAccessibleSchoolClassPropertiesFragmentDoc = gql`
    fragment StudentAccessibleSchoolClassProperties on StudentAccessibleSchoolClass {
  id
  title
  period
}
    `;
export const AddressPropertiesFragmentDoc = gql`
    fragment AddressProperties on Address {
  street1
  street2
  street3
  city
  state
  postalCode
}
    `;
export const ImportedSchoolPropertiesFragmentDoc = gql`
    fragment ImportedSchoolProperties on ImportedSchool {
  id
  name
  phone
  website
  schoolType
  level
  isCharter
  gradesOffered
  location {
    ...AddressProperties
  }
  createdAt
  updatedAt
  schoolId
  importFormat
  updatedStatus
}
    ${AddressPropertiesFragmentDoc}`;
export const StudentSchoolPropertiesFragmentDoc = gql`
    fragment StudentSchoolProperties on SchoolCustomer {
  id
  name
  phone
  website
  location {
    ...AddressProperties
  }
}
    ${AddressPropertiesFragmentDoc}`;
export const SchoolCustomerPropertiesFragmentDoc = gql`
    fragment SchoolCustomerProperties on SchoolCustomer {
  ...StudentSchoolProperties
  schoolType
  level
  isCharter
  gradesOffered
  createdAt
  updatedAt
  importedSchoolId
  hubspotCompanyId
}
    ${StudentSchoolPropertiesFragmentDoc}`;
export const SurveyMultipleSelectOptionsPropertiesFragmentDoc = gql`
    fragment SurveyMultipleSelectOptionsProperties on SurveyMultipleSelectOptions {
  format
  allowOther
  hint
  maxSelect
  minSelect
  answers {
    id
    text
  }
}
    `;
export const SurveySingleSelectOptionsPropertiesFragmentDoc = gql`
    fragment SurveySingleSelectOptionsProperties on SurveySingleSelectOptions {
  format
  allowOther
  hint
  answers {
    id
    text
  }
}
    `;
export const SurveyAnswerChoicePropertiesFragmentDoc = gql`
    fragment SurveyAnswerChoiceProperties on SurveyAnswerChoice {
  id
  text
}
    `;
export const SurveyAnswerOtherChoicePropertiesFragmentDoc = gql`
    fragment SurveyAnswerOtherChoiceProperties on SurveyAnswerOtherChoice {
  text
}
    `;
export const SurveyMultipleSelectResponsePropertiesFragmentDoc = gql`
    fragment SurveyMultipleSelectResponseProperties on SurveyMultipleSelectResponse {
  format
  answers {
    ...SurveyAnswerChoiceProperties
    ...SurveyAnswerOtherChoiceProperties
  }
}
    ${SurveyAnswerChoicePropertiesFragmentDoc}
${SurveyAnswerOtherChoicePropertiesFragmentDoc}`;
export const SurveySingleSelectResponsePropertiesFragmentDoc = gql`
    fragment SurveySingleSelectResponseProperties on SurveySingleSelectResponse {
  format
  answer {
    ...SurveyAnswerChoiceProperties
    ...SurveyAnswerOtherChoiceProperties
  }
}
    ${SurveyAnswerChoicePropertiesFragmentDoc}
${SurveyAnswerOtherChoicePropertiesFragmentDoc}`;
export const AuthenticatedAccountDocument = gql`
    query AuthenticatedAccount {
  authenticatedAccount {
    ...AccountProperties
  }
}
    ${AccountPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloAuthenticatedAccountGQL extends Apollo.Query<ApolloAuthenticatedAccountQuery, ApolloAuthenticatedAccountQueryVariables> {
    override document = AuthenticatedAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginAsDocument = gql`
    query LoginAs($email: String!) {
  loginAs(email: $email)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloLoginAsGQL extends Apollo.Query<ApolloLoginAsQuery, ApolloLoginAsQueryVariables> {
    override document = LoginAsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InternalAccountsDocument = gql`
    query InternalAccounts {
  internalAccounts {
    ...AccountProperties
  }
}
    ${AccountPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloInternalAccountsGQL extends Apollo.Query<ApolloInternalAccountsQuery, ApolloInternalAccountsQueryVariables> {
    override document = InternalAccountsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RandomStudentPasswordsDocument = gql`
    query RandomStudentPasswords($count: Int!) {
  randomStudentPasswords(count: $count)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloRandomStudentPasswordsGQL extends Apollo.Query<ApolloRandomStudentPasswordsQuery, ApolloRandomStudentPasswordsQueryVariables> {
    override document = RandomStudentPasswordsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StudentAccountDocument = gql`
    query StudentAccount($studentId: String!, $schoolCustomerId: ID!) {
  studentAccount(studentId: $studentId, schoolCustomerId: $schoolCustomerId) {
    id
    firstName
    lastName
    email
    additionalStudentUserData {
      studentId
      schoolCustomerId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloStudentAccountGQL extends Apollo.Query<ApolloStudentAccountQuery, ApolloStudentAccountQueryVariables> {
    override document = StudentAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StudentLoginEmailDocument = gql`
    query StudentLoginEmail($schoolCustomerId: ID!, $studentId: String!) {
  studentLoginEmail(schoolCustomerId: $schoolCustomerId, studentId: $studentId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloStudentLoginEmailGQL extends Apollo.Query<ApolloStudentLoginEmailQuery, ApolloStudentLoginEmailQueryVariables> {
    override document = StudentLoginEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CheckCanRegisterDocument = gql`
    query CheckCanRegister($email: String!) {
  canRegister(email: $email)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloCheckCanRegisterGQL extends Apollo.Query<ApolloCheckCanRegisterQuery, ApolloCheckCanRegisterQueryVariables> {
    override document = CheckCanRegisterDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountPlanWillUpgradeDocument = gql`
    query AccountPlanWillUpgrade($email: String!) {
  accountByEmail(email: $email) {
    planWouldChange(desiredPlan: FULL_ACCESS)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloAccountPlanWillUpgradeGQL extends Apollo.Query<ApolloAccountPlanWillUpgradeQuery, ApolloAccountPlanWillUpgradeQueryVariables> {
    override document = AccountPlanWillUpgradeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateLegalDocumentTimestampsDocument = gql`
    mutation UpdateLegalDocumentTimestamps {
  updateLegalDocumentTimestamps {
    ...AccountProperties
  }
}
    ${AccountPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloUpdateLegalDocumentTimestampsGQL extends Apollo.Mutation<ApolloUpdateLegalDocumentTimestampsMutation, ApolloUpdateLegalDocumentTimestampsMutationVariables> {
    override document = UpdateLegalDocumentTimestampsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InviteInternalAccountDocument = gql`
    mutation InviteInternalAccount($invitee: InternalAccountInviteeInput!) {
  inviteInternalAccount(invitee: $invitee) {
    ...AccountProperties
  }
}
    ${AccountPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloInviteInternalAccountGQL extends Apollo.Mutation<ApolloInviteInternalAccountMutation, ApolloInviteInternalAccountMutationVariables> {
    override document = InviteInternalAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InviteSchoolAccountDocument = gql`
    mutation InviteSchoolAccount($invitee: SchoolAccountInviteeInput!) {
  inviteSchoolAccount(invitee: $invitee) {
    ...AccountProperties
  }
}
    ${AccountPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloInviteSchoolAccountGQL extends Apollo.Mutation<ApolloInviteSchoolAccountMutation, ApolloInviteSchoolAccountMutationVariables> {
    override document = InviteSchoolAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InviteStudentAccountDocument = gql`
    mutation InviteStudentAccount($invitee: StudentAccountInviteeInput!) {
  inviteStudentAccount(invitee: $invitee) {
    ...AccountProperties
  }
}
    ${AccountPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloInviteStudentAccountGQL extends Apollo.Mutation<ApolloInviteStudentAccountMutation, ApolloInviteStudentAccountMutationVariables> {
    override document = InviteStudentAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResendInviteDocument = gql`
    mutation ResendInvite($id: ID!) {
  resendInvite(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloResendInviteGQL extends Apollo.Mutation<ApolloResendInviteMutation, ApolloResendInviteMutationVariables> {
    override document = ResendInviteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RegisterAccountDocument = gql`
    mutation RegisterAccount($registration: AccountRegistrationInput!) {
  registerAccount(registration: $registration) {
    ...AccountProperties
  }
}
    ${AccountPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloRegisterAccountGQL extends Apollo.Mutation<ApolloRegisterAccountMutation, ApolloRegisterAccountMutationVariables> {
    override document = RegisterAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetAccountPasswordDocument = gql`
    mutation SetAccountPassword($accountId: ID!, $password: String!) {
  setAccountPassword(accountId: $accountId, password: $password) {
    ...AccountProperties
  }
}
    ${AccountPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloSetAccountPasswordGQL extends Apollo.Mutation<ApolloSetAccountPasswordMutation, ApolloSetAccountPasswordMutationVariables> {
    override document = SetAccountPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAccountAccessDocument = gql`
    mutation UpdateAccountAccess($id: ID!, $canAccess: Boolean!) {
  updateAccountAccess(id: $id, canAccess: $canAccess) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloUpdateAccountAccessGQL extends Apollo.Mutation<ApolloUpdateAccountAccessMutation, ApolloUpdateAccountAccessMutationVariables> {
    override document = UpdateAccountAccessDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AdminUpdateInternalProfileDocument = gql`
    mutation AdminUpdateInternalProfile($id: ID!, $changes: AccountProfileAdminChangesInput!) {
  adminUpdateInternalProfile(id: $id, changes: $changes) {
    ...AccountProperties
  }
}
    ${AccountPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloAdminUpdateInternalProfileGQL extends Apollo.Mutation<ApolloAdminUpdateInternalProfileMutation, ApolloAdminUpdateInternalProfileMutationVariables> {
    override document = AdminUpdateInternalProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AdminUpdateSchoolProfileDocument = gql`
    mutation AdminUpdateSchoolProfile($id: ID!, $changes: SchoolProfileAdminChangesInput!) {
  adminUpdateSchoolProfile(id: $id, changes: $changes) {
    ...AccountProperties
  }
}
    ${AccountPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloAdminUpdateSchoolProfileGQL extends Apollo.Mutation<ApolloAdminUpdateSchoolProfileMutation, ApolloAdminUpdateSchoolProfileMutationVariables> {
    override document = AdminUpdateSchoolProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateStudentDataDocument = gql`
    mutation UpdateStudentData($input: UpdateStudentDataGqlInput!) {
  updateSchoolStudentData(updatedStudent: $input) {
    id
    firstName
    lastName
    email
    additionalStudentUserData {
      schoolCustomerId
      studentId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloUpdateStudentDataGQL extends Apollo.Mutation<ApolloUpdateStudentDataMutation, ApolloUpdateStudentDataMutationVariables> {
    override document = UpdateStudentDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSchoolUserDataDocument = gql`
    mutation UpdateSchoolUserData($update: SchoolUserDataUpdate!) {
  updateSchoolUserData(update: $update) {
    ...AccountProperties
  }
}
    ${AccountPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloUpdateSchoolUserDataGQL extends Apollo.Mutation<ApolloUpdateSchoolUserDataMutation, ApolloUpdateSchoolUserDataMutationVariables> {
    override document = UpdateSchoolUserDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserUpdateAccountProfileDocument = gql`
    mutation UserUpdateAccountProfile($id: ID!, $profile: AccountProfileChangesInput!, $teachingInfo: TeachingInfoInput) {
  userUpdateAccountProfile(
    id: $id
    profile: $profile
    teachingInfo: $teachingInfo
  ) {
    ...AccountProperties
  }
}
    ${AccountPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloUserUpdateAccountProfileGQL extends Apollo.Mutation<ApolloUserUpdateAccountProfileMutation, ApolloUserUpdateAccountProfileMutationVariables> {
    override document = UserUpdateAccountProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserUpdateAccountEmailDocument = gql`
    mutation UserUpdateAccountEmail {
  userUpdateAccountEmail {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloUserUpdateAccountEmailGQL extends Apollo.Mutation<ApolloUserUpdateAccountEmailMutation, ApolloUserUpdateAccountEmailMutationVariables> {
    override document = UserUpdateAccountEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAssessmentDocument = gql`
    mutation createAssessment($assessment: CreateAssessmentKeyInput!) {
  createAssessment(assessment: $assessment) {
    ...AssessmentKeyProperties
  }
}
    ${AssessmentKeyPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloCreateAssessmentGQL extends Apollo.Mutation<ApolloCreateAssessmentMutation, ApolloCreateAssessmentMutationVariables> {
    override document = CreateAssessmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAssessmentDocument = gql`
    mutation updateAssessment($assessment: AssessmentKeyInput!) {
  updateAssessment(assessment: $assessment) {
    ...AssessmentKeyProperties
  }
}
    ${AssessmentKeyPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloUpdateAssessmentGQL extends Apollo.Mutation<ApolloUpdateAssessmentMutation, ApolloUpdateAssessmentMutationVariables> {
    override document = UpdateAssessmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateQuestionGroupDocument = gql`
    mutation createQuestionGroup($questionGroup: CreateQuestionGroupKeyInput!) {
  createQuestionGroup(questionGroup: $questionGroup) {
    ...QuestionGroupKeyProperties
  }
}
    ${QuestionGroupKeyPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloCreateQuestionGroupGQL extends Apollo.Mutation<ApolloCreateQuestionGroupMutation, ApolloCreateQuestionGroupMutationVariables> {
    override document = CreateQuestionGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateQuestionGroupDocument = gql`
    mutation updateQuestionGroup($questionGroup: QuestionGroupKeyInput!) {
  updateQuestionGroup(questionGroup: $questionGroup) {
    ...QuestionGroupKeyProperties
  }
}
    ${QuestionGroupKeyPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloUpdateQuestionGroupGQL extends Apollo.Mutation<ApolloUpdateQuestionGroupMutation, ApolloUpdateQuestionGroupMutationVariables> {
    override document = UpdateQuestionGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteQuestionGroupDocument = gql`
    mutation deleteQuestionGroup($questionGroupId: ID!) {
  deleteQuestionGroup(questionGroupId: $questionGroupId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloDeleteQuestionGroupGQL extends Apollo.Mutation<ApolloDeleteQuestionGroupMutation, ApolloDeleteQuestionGroupMutationVariables> {
    override document = DeleteQuestionGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAssessmentQuestionDocument = gql`
    mutation createAssessmentQuestion($assessmentQuestion: CreateAssessmentQuestionKeyInput!) {
  createAssessmentQuestion(assessmentQuestion: $assessmentQuestion) {
    ...QuestionKeyProperties
  }
}
    ${QuestionKeyPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloCreateAssessmentQuestionGQL extends Apollo.Mutation<ApolloCreateAssessmentQuestionMutation, ApolloCreateAssessmentQuestionMutationVariables> {
    override document = CreateAssessmentQuestionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAssessmentQuestionDocument = gql`
    mutation updateAssessmentQuestion($assessmentQuestion: AssessmentQuestionKeyInput!) {
  updateAssessmentQuestion(assessmentQuestion: $assessmentQuestion) {
    ...QuestionKeyProperties
  }
}
    ${QuestionKeyPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloUpdateAssessmentQuestionGQL extends Apollo.Mutation<ApolloUpdateAssessmentQuestionMutation, ApolloUpdateAssessmentQuestionMutationVariables> {
    override document = UpdateAssessmentQuestionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAssessmentQuestionDocument = gql`
    mutation deleteAssessmentQuestion($assessmentQuestionId: ID!) {
  deleteAssessmentQuestion(assessmentQuestionId: $assessmentQuestionId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloDeleteAssessmentQuestionGQL extends Apollo.Mutation<ApolloDeleteAssessmentQuestionMutation, ApolloDeleteAssessmentQuestionMutationVariables> {
    override document = DeleteAssessmentQuestionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllAssessmentsDocument = gql`
    query allAssessments {
  allAssessments {
    ...AssessmentSummary
  }
}
    ${AssessmentSummaryFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloAllAssessmentsGQL extends Apollo.Query<ApolloAllAssessmentsQuery, ApolloAllAssessmentsQueryVariables> {
    override document = AllAssessmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssessmentsForGradeLevelDocument = gql`
    query assessmentsForGradeLevel($gradeLevel: GradeLevel!) {
  assessmentsForGradeLevel(gradeLevel: $gradeLevel) {
    ...AssessmentSummary
  }
}
    ${AssessmentSummaryFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloAssessmentsForGradeLevelGQL extends Apollo.Query<ApolloAssessmentsForGradeLevelQuery, ApolloAssessmentsForGradeLevelQueryVariables> {
    override document = AssessmentsForGradeLevelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssessmentForInternalUserDocument = gql`
    query assessmentForInternalUser($assessmentId: ID!) {
  assessmentForInternalUser(assessmentId: $assessmentId) {
    ...AssessmentKeyProperties
  }
}
    ${AssessmentKeyPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloAssessmentForInternalUserGQL extends Apollo.Query<ApolloAssessmentForInternalUserQuery, ApolloAssessmentForInternalUserQueryVariables> {
    override document = AssessmentForInternalUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoggedInAssessmentForStudentAssignmentDocument = gql`
    query loggedInAssessmentForStudentAssignment($studentAssignmentId: ID!) {
  loggedInAssessmentForStudentAssignment(
    studentAssignmentId: $studentAssignmentId
  ) {
    ...AssessmentProperties
  }
}
    ${AssessmentPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloLoggedInAssessmentForStudentAssignmentGQL extends Apollo.Query<ApolloLoggedInAssessmentForStudentAssignmentQuery, ApolloLoggedInAssessmentForStudentAssignmentQueryVariables> {
    override document = LoggedInAssessmentForStudentAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StartLoggedInStudentAssessmentAssignmentDocument = gql`
    mutation startLoggedInStudentAssessmentAssignment($studentAssignmentId: ID!) {
  startLoggedInStudentAssessmentAssignment(
    studentAssignmentId: $studentAssignmentId
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloStartLoggedInStudentAssessmentAssignmentGQL extends Apollo.Mutation<ApolloStartLoggedInStudentAssessmentAssignmentMutation, ApolloStartLoggedInStudentAssessmentAssignmentMutationVariables> {
    override document = StartLoggedInStudentAssessmentAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoggedInStudentAssessmentAnswersDocument = gql`
    query loggedInStudentAssessmentAnswers($studentAssignmentId: ID!) {
  loggedInStudentAssessmentAnswers(studentAssignmentId: $studentAssignmentId) {
    id
    questionId
    studentAssignmentId
    selectedAnswer {
      text
    }
    selectedAnswerIndex
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloLoggedInStudentAssessmentAnswersGQL extends Apollo.Query<ApolloLoggedInStudentAssessmentAnswersQuery, ApolloLoggedInStudentAssessmentAnswersQueryVariables> {
    override document = LoggedInStudentAssessmentAnswersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetStudentAssessmentResultsForClassAssignmentDocument = gql`
    query getStudentAssessmentResultsForClassAssignment($classAssignmentId: ID!) {
  getStudentAssessmentResultsForClassAssignment(
    classAssignmentId: $classAssignmentId
  ) {
    id
    studentId
    assessmentId
    studentAssignmentId
    questionsCount
    correctAnswers
    incorrectAnswers
    unanswered
    proficiencyBySkill {
      skillId
      questionsCount
      correctAnswers
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetStudentAssessmentResultsForClassAssignmentGQL extends Apollo.Query<ApolloGetStudentAssessmentResultsForClassAssignmentQuery, ApolloGetStudentAssessmentResultsForClassAssignmentQueryVariables> {
    override document = GetStudentAssessmentResultsForClassAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubmitStudentAssessmentAssignmentDocument = gql`
    mutation submitStudentAssessmentAssignment($studentAssignmentId: ID!) {
  submitStudentAssessmentAssignment(studentAssignmentId: $studentAssignmentId) {
    id
    studentId
    classAssignmentId
    startDateTimeOverride
    endDateTimeOverride
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloSubmitStudentAssessmentAssignmentGQL extends Apollo.Mutation<ApolloSubmitStudentAssessmentAssignmentMutation, ApolloSubmitStudentAssessmentAssignmentMutationVariables> {
    override document = SubmitStudentAssessmentAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AnswerAssessmentQuestionDocument = gql`
    mutation answerAssessmentQuestion($input: StudentAssessmentAnswerInput!) {
  submitAssessmentAnswer(input: $input) {
    id
    questionId
    studentAssignmentId
    selectedAnswer {
      text
    }
    selectedAnswerIndex
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloAnswerAssessmentQuestionGQL extends Apollo.Mutation<ApolloAnswerAssessmentQuestionMutation, ApolloAnswerAssessmentQuestionMutationVariables> {
    override document = AnswerAssessmentQuestionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAnyClassAssignmentDocument = gql`
    query getAnyClassAssignment($assignmentId: ID!) {
  getAnyClassAssignment(assignmentId: $assignmentId) {
    ... on ClassAssessmentAssignment {
      ...ClassAssessmentAssignmentProperties
    }
    ... on ClassMiscellaneousAssignment {
      ...ClassMiscellaneousAssignmentProperties
    }
  }
}
    ${ClassAssessmentAssignmentPropertiesFragmentDoc}
${ClassMiscellaneousAssignmentPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetAnyClassAssignmentGQL extends Apollo.Query<ApolloGetAnyClassAssignmentQuery, ApolloGetAnyClassAssignmentQueryVariables> {
    override document = GetAnyClassAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetStudentAssignmentsByClassDocument = gql`
    query getStudentAssignmentsByClass($input: GetStudentAssignmentsByClassIdGqlInput!) {
  getStudentAssignmentsByClassId(input: $input) {
    ...StudentAssignmentProperties
  }
}
    ${StudentAssignmentPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetStudentAssignmentsByClassGQL extends Apollo.Query<ApolloGetStudentAssignmentsByClassQuery, ApolloGetStudentAssignmentsByClassQueryVariables> {
    override document = GetStudentAssignmentsByClassDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClassMiscellaneousAssignmentsDocument = gql`
    query classMiscellaneousAssignments($classId: ID!) {
  ownClassMiscellaneousAssignments(classId: $classId) {
    id
    schoolClassId
    title
    type
    description
    startDateTime
    endDateTime
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloClassMiscellaneousAssignmentsGQL extends Apollo.Query<ApolloClassMiscellaneousAssignmentsQuery, ApolloClassMiscellaneousAssignmentsQueryVariables> {
    override document = ClassMiscellaneousAssignmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetStudentAssignmentsDocument = gql`
    query getStudentAssignments {
  getStudentAssignments {
    ...StudentAssignmentProperties
  }
}
    ${StudentAssignmentPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetStudentAssignmentsGQL extends Apollo.Query<ApolloGetStudentAssignmentsQuery, ApolloGetStudentAssignmentsQueryVariables> {
    override document = GetStudentAssignmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLoggedInStudentAssignmentDocument = gql`
    query getLoggedInStudentAssignment($studentAssignmentId: ID!) {
  getLoggedInStudentAssignment(studentAssignmentId: $studentAssignmentId) {
    ...StudentAssignmentProperties
  }
}
    ${StudentAssignmentPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetLoggedInStudentAssignmentGQL extends Apollo.Query<ApolloGetLoggedInStudentAssignmentQuery, ApolloGetLoggedInStudentAssignmentQueryVariables> {
    override document = GetLoggedInStudentAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClassAssessmentAssignmentsDocument = gql`
    query classAssessmentAssignments($classId: ID!) {
  classAssessmentAssignments(classId: $classId) {
    ...ClassAssessmentAssignmentProperties
  }
}
    ${ClassAssessmentAssignmentPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloClassAssessmentAssignmentsGQL extends Apollo.Query<ApolloClassAssessmentAssignmentsQuery, ApolloClassAssessmentAssignmentsQueryVariables> {
    override document = ClassAssessmentAssignmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateClassAssessmentAssignmentDocument = gql`
    mutation createClassAssessmentAssignment($input: CreateClassAssessmentAssignmentInput!) {
  createClassAssessmentAssignment(input: $input) {
    ...ClassAssessmentAssignmentProperties
  }
}
    ${ClassAssessmentAssignmentPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloCreateClassAssessmentAssignmentGQL extends Apollo.Mutation<ApolloCreateClassAssessmentAssignmentMutation, ApolloCreateClassAssessmentAssignmentMutationVariables> {
    override document = CreateClassAssessmentAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOwnClassMiscellaneousAssignmentDocument = gql`
    mutation createOwnClassMiscellaneousAssignment($input: CreateClassMiscellaneousAssignmentInput!) {
  createOwnClassMiscellaneousAssignment(input: $input) {
    ...ClassMiscellaneousAssignmentProperties
  }
}
    ${ClassMiscellaneousAssignmentPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloCreateOwnClassMiscellaneousAssignmentGQL extends Apollo.Mutation<ApolloCreateOwnClassMiscellaneousAssignmentMutation, ApolloCreateOwnClassMiscellaneousAssignmentMutationVariables> {
    override document = CreateOwnClassMiscellaneousAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StartLoggedInStudentMiscellaneousAssignmentDocument = gql`
    mutation startLoggedInStudentMiscellaneousAssignment($studentAssignmentId: ID!) {
  startLoggedInStudentMiscellaneousAssignment(
    studentAssignmentId: $studentAssignmentId
  ) {
    ...StudentAssignmentProperties
  }
}
    ${StudentAssignmentPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloStartLoggedInStudentMiscellaneousAssignmentGQL extends Apollo.Mutation<ApolloStartLoggedInStudentMiscellaneousAssignmentMutation, ApolloStartLoggedInStudentMiscellaneousAssignmentMutationVariables> {
    override document = StartLoggedInStudentMiscellaneousAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubmitLoggedInStudentAssignmentDocument = gql`
    mutation submitLoggedInStudentAssignment($studentAssignmentId: ID!) {
  submitLoggedInStudentAssignment(studentAssignmentId: $studentAssignmentId) {
    ...StudentAssignmentProperties
  }
}
    ${StudentAssignmentPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloSubmitLoggedInStudentAssignmentGQL extends Apollo.Mutation<ApolloSubmitLoggedInStudentAssignmentMutation, ApolloSubmitLoggedInStudentAssignmentMutationVariables> {
    override document = SubmitLoggedInStudentAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateClassAssessmentAssignmentDocument = gql`
    mutation updateClassAssessmentAssignment($input: UpdateClassAssessmentAssignmentInput!) {
  updateClassAssessmentAssignment(input: $input) {
    ...ClassAssessmentAssignmentProperties
  }
}
    ${ClassAssessmentAssignmentPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloUpdateClassAssessmentAssignmentGQL extends Apollo.Mutation<ApolloUpdateClassAssessmentAssignmentMutation, ApolloUpdateClassAssessmentAssignmentMutationVariables> {
    override document = UpdateClassAssessmentAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOwnClassMiscellaneousAssignmentDocument = gql`
    mutation updateOwnClassMiscellaneousAssignment($input: UpdateClassMiscellaneousAssignmentInput!) {
  updateOwnClassMiscellaneousAssignment(input: $input) {
    ...ClassMiscellaneousAssignmentProperties
  }
}
    ${ClassMiscellaneousAssignmentPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloUpdateOwnClassMiscellaneousAssignmentGQL extends Apollo.Mutation<ApolloUpdateOwnClassMiscellaneousAssignmentMutation, ApolloUpdateOwnClassMiscellaneousAssignmentMutationVariables> {
    override document = UpdateOwnClassMiscellaneousAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteClassAssessmentAssignmentDocument = gql`
    mutation deleteClassAssessmentAssignment($classAssessmentAssignmentId: ID!) {
  deleteClassAssessmentAssignment(
    classAssessmentAssignmentId: $classAssessmentAssignmentId
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloDeleteClassAssessmentAssignmentGQL extends Apollo.Mutation<ApolloDeleteClassAssessmentAssignmentMutation, ApolloDeleteClassAssessmentAssignmentMutationVariables> {
    override document = DeleteClassAssessmentAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DashboardConfigurationDocument = gql`
    query DashboardConfiguration($applicationType: UserApplication!) {
  dashboardConfiguration(applicationType: $applicationType) {
    ...FullSchoolDashboardConfiguration
  }
}
    ${FullSchoolDashboardConfigurationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloDashboardConfigurationGQL extends Apollo.Query<ApolloDashboardConfigurationQuery, ApolloDashboardConfigurationQueryVariables> {
    override document = DashboardConfigurationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDashboardConfigurationDocument = gql`
    mutation UpdateDashboardConfiguration($input: DashboardConfigurationInput!) {
  updateDashboardConfiguration(input: $input) {
    ...FullSchoolDashboardConfiguration
  }
}
    ${FullSchoolDashboardConfigurationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloUpdateDashboardConfigurationGQL extends Apollo.Mutation<ApolloUpdateDashboardConfigurationMutation, ApolloUpdateDashboardConfigurationMutationVariables> {
    override document = UpdateDashboardConfigurationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteMediaResourceDocument = gql`
    mutation DeleteMediaResource($id: ID!) {
  deleteMediaResource(id: $id) {
    id
    typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloDeleteMediaResourceGQL extends Apollo.Mutation<ApolloDeleteMediaResourceMutation, ApolloDeleteMediaResourceMutationVariables> {
    override document = DeleteMediaResourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateMediaResourceDocument = gql`
    mutation CreateMediaResource($input: MediaInput!) {
  createMediaResource(input: $input) {
    ...FullMediaObject
  }
}
    ${FullMediaObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloCreateMediaResourceGQL extends Apollo.Mutation<ApolloCreateMediaResourceMutation, ApolloCreateMediaResourceMutationVariables> {
    override document = CreateMediaResourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMediaResourceDocument = gql`
    mutation UpdateMediaResource($input: MediaInput!) {
  updateMediaResource(input: $input) {
    ...FullMediaObject
  }
}
    ${FullMediaObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloUpdateMediaResourceGQL extends Apollo.Mutation<ApolloUpdateMediaResourceMutation, ApolloUpdateMediaResourceMutationVariables> {
    override document = UpdateMediaResourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTeachersLibraryListViewDocument = gql`
    query GetTeachersLibraryListView {
  getMediaResources(library: TEACHER) {
    ...ListMediaObject
  }
}
    ${ListMediaObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetTeachersLibraryListViewGQL extends Apollo.Query<ApolloGetTeachersLibraryListViewQuery, ApolloGetTeachersLibraryListViewQueryVariables> {
    override document = GetTeachersLibraryListViewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetStudentsLibraryListViewDocument = gql`
    query GetStudentsLibraryListView {
  getMediaResources(library: STUDENT) {
    ...ListMediaObject
  }
}
    ${ListMediaObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetStudentsLibraryListViewGQL extends Apollo.Query<ApolloGetStudentsLibraryListViewQuery, ApolloGetStudentsLibraryListViewQueryVariables> {
    override document = GetStudentsLibraryListViewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetOtherLibraryListViewDocument = gql`
    query GetOtherLibraryListView {
  getMediaResources(library: OTHER) {
    ...ListMediaObject
  }
}
    ${ListMediaObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetOtherLibraryListViewGQL extends Apollo.Query<ApolloGetOtherLibraryListViewQuery, ApolloGetOtherLibraryListViewQueryVariables> {
    override document = GetOtherLibraryListViewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMediaResourceDocument = gql`
    query GetMediaResource($id: String!) {
  getMediaResource(id: $id) {
    ...FullMediaObject
  }
}
    ${FullMediaObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetMediaResourceGQL extends Apollo.Query<ApolloGetMediaResourceQuery, ApolloGetMediaResourceQueryVariables> {
    override document = GetMediaResourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMediaResourceDetailsViewDocument = gql`
    query GetMediaResourceDetailsView($id: String!) {
  getMediaResource(id: $id) {
    ...MediaObject
    includedObject {
      ...StorageObjectProperties
      signedDownloadUrl
    }
    includedVttObject {
      ...StorageObjectProperties
    }
    fullSizePoster {
      ...StorageObjectProperties
    }
  }
}
    ${MediaObjectFragmentDoc}
${StorageObjectPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetMediaResourceDetailsViewGQL extends Apollo.Query<ApolloGetMediaResourceDetailsViewQuery, ApolloGetMediaResourceDetailsViewQueryVariables> {
    override document = GetMediaResourceDetailsViewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMediaResourcesByIdsDocument = gql`
    query GetMediaResourcesByIds($ids: [ID!]!) {
  getMediaResourcesByIds(ids: $ids) {
    ...ProgramMediaObject
  }
}
    ${ProgramMediaObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetMediaResourcesByIdsGQL extends Apollo.Query<ApolloGetMediaResourcesByIdsQuery, ApolloGetMediaResourcesByIdsQueryVariables> {
    override document = GetMediaResourcesByIdsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProgramMediaResourceDocument = gql`
    query GetProgramMediaResource($id: String!) {
  getMediaResource(id: $id) {
    ...ProgramMediaObject
  }
}
    ${ProgramMediaObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetProgramMediaResourceGQL extends Apollo.Query<ApolloGetProgramMediaResourceQuery, ApolloGetProgramMediaResourceQueryVariables> {
    override document = GetProgramMediaResourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetWelcomeVideoUrlDocument = gql`
    query GetWelcomeVideoUrl {
  getMediaResource(id: "78db1dda-4b8b-48cd-b17c-e1c719a8d158") {
    includedObject {
      name
      signedDownloadUrl
    }
    includedVttObject {
      ...StorageObjectProperties
    }
    fullSizePoster {
      storageKey
    }
  }
}
    ${StorageObjectPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetWelcomeVideoUrlGQL extends Apollo.Query<ApolloGetWelcomeVideoUrlQuery, ApolloGetWelcomeVideoUrlQueryVariables> {
    override document = GetWelcomeVideoUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePlannerTopicDocument = gql`
    mutation createPlannerTopic($plannerTopic: CreatePlannerTopicInput!) {
  createPlannerTopic(plannerTopic: $plannerTopic) {
    ...PlannerTopicFragment
  }
}
    ${PlannerTopicFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloCreatePlannerTopicGQL extends Apollo.Mutation<ApolloCreatePlannerTopicMutation, ApolloCreatePlannerTopicMutationVariables> {
    override document = CreatePlannerTopicDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePlannerTopicDocument = gql`
    mutation updatePlannerTopic($plannerTopic: PlannerTopicInput!) {
  updatePlannerTopic(plannerTopic: $plannerTopic) {
    ...PlannerTopicFragment
  }
}
    ${PlannerTopicFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloUpdatePlannerTopicGQL extends Apollo.Mutation<ApolloUpdatePlannerTopicMutation, ApolloUpdatePlannerTopicMutationVariables> {
    override document = UpdatePlannerTopicDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePlannerTopicDocument = gql`
    mutation deletePlannerTopic($plannerTopicId: String!) {
  deletePlannerTopic(plannerTopicId: $plannerTopicId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloDeletePlannerTopicGQL extends Apollo.Mutation<ApolloDeletePlannerTopicMutation, ApolloDeletePlannerTopicMutationVariables> {
    override document = DeletePlannerTopicDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePlannerBlockDocument = gql`
    mutation createPlannerBlock($plannerBlock: CreatePlannerBlockInput!) {
  createPlannerBlock(plannerBlock: $plannerBlock) {
    ...PlannerBlockFragment
  }
}
    ${PlannerBlockFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloCreatePlannerBlockGQL extends Apollo.Mutation<ApolloCreatePlannerBlockMutation, ApolloCreatePlannerBlockMutationVariables> {
    override document = CreatePlannerBlockDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePlannerBlockDocument = gql`
    mutation updatePlannerBlock($plannerBlock: PlannerBlockInput!) {
  updatePlannerBlock(plannerBlock: $plannerBlock) {
    ...PlannerBlockFragment
  }
}
    ${PlannerBlockFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloUpdatePlannerBlockGQL extends Apollo.Mutation<ApolloUpdatePlannerBlockMutation, ApolloUpdatePlannerBlockMutationVariables> {
    override document = UpdatePlannerBlockDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePlannerBlockDocument = gql`
    mutation deletePlannerBlock($plannerBlockId: String!) {
  deletePlannerBlock(plannerBlockId: $plannerBlockId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloDeletePlannerBlockGQL extends Apollo.Mutation<ApolloDeletePlannerBlockMutation, ApolloDeletePlannerBlockMutationVariables> {
    override document = DeletePlannerBlockDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePlannerNoteDocument = gql`
    mutation createPlannerNote($input: CreatePlannerNoteInput!) {
  createPlannerNote(input: $input) {
    ...PlannerNoteFragment
  }
}
    ${PlannerNoteFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloCreatePlannerNoteGQL extends Apollo.Mutation<ApolloCreatePlannerNoteMutation, ApolloCreatePlannerNoteMutationVariables> {
    override document = CreatePlannerNoteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePlannerNoteDocument = gql`
    mutation updatePlannerNote($input: UpdatePlannerNoteInput!) {
  updatePlannerNote(input: $input) {
    ...PlannerNoteFragment
  }
}
    ${PlannerNoteFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloUpdatePlannerNoteGQL extends Apollo.Mutation<ApolloUpdatePlannerNoteMutation, ApolloUpdatePlannerNoteMutationVariables> {
    override document = UpdatePlannerNoteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePlannerNoteDocument = gql`
    mutation deletePlannerNote($plannerNoteId: String!) {
  deletePlannerNote(plannerNoteId: $plannerNoteId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloDeletePlannerNoteGQL extends Apollo.Mutation<ApolloDeletePlannerNoteMutation, ApolloDeletePlannerNoteMutationVariables> {
    override document = DeletePlannerNoteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetResourceDocument = gql`
    query getResource($resourceId: String!) {
  getResource(resourceId: $resourceId) {
    ...BaseResourceProperties
    originalImage {
      ...StorageObjectProperties
    }
  }
}
    ${BaseResourcePropertiesFragmentDoc}
${StorageObjectPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetResourceGQL extends Apollo.Query<ApolloGetResourceQuery, ApolloGetResourceQueryVariables> {
    override document = GetResourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchResourcesDocument = gql`
    query searchResources($input: ResourceSearchInput!) {
  searchResources(input: $input) {
    numberOfResults
    resources {
      id
      title
      types
      skillIds
      gradeLevels
      references
      minimumHoursEstimate
      maximumHoursEstimate
      description
      keywords
      hideInSearchResults
      thumbnailImage {
        ...StorageObjectProperties
      }
    }
    availableFilters {
      property
      filters {
        value
        count
      }
    }
  }
}
    ${StorageObjectPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloSearchResourcesGQL extends Apollo.Query<ApolloSearchResourcesQuery, ApolloSearchResourcesQueryVariables> {
    override document = SearchResourcesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateResourceDocument = gql`
    mutation createResource($input: ResourceInput!) {
  createResource(input: $input) {
    ...BaseResourceProperties
    originalImage {
      ...StorageObjectProperties
    }
  }
}
    ${BaseResourcePropertiesFragmentDoc}
${StorageObjectPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloCreateResourceGQL extends Apollo.Mutation<ApolloCreateResourceMutation, ApolloCreateResourceMutationVariables> {
    override document = CreateResourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateResourceDocument = gql`
    mutation updateResource($input: ResourceInput!) {
  updateResource(input: $input) {
    ...BaseResourceProperties
    originalImage {
      ...StorageObjectProperties
    }
  }
}
    ${BaseResourcePropertiesFragmentDoc}
${StorageObjectPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloUpdateResourceGQL extends Apollo.Mutation<ApolloUpdateResourceMutation, ApolloUpdateResourceMutationVariables> {
    override document = UpdateResourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteResourceDocument = gql`
    mutation deleteResource($resourceId: String!) {
  deleteResource(resourceId: $resourceId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloDeleteResourceGQL extends Apollo.Mutation<ApolloDeleteResourceMutation, ApolloDeleteResourceMutationVariables> {
    override document = DeleteResourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddFilesToResourceDocument = gql`
    mutation addFilesToResource($input: ResourceFilesInput!) {
  addFilesToResource(input: $input) {
    ...BaseResourceProperties
    includedObjects {
      storageObject {
        ...StorageObjectProperties
      }
      path
    }
  }
}
    ${BaseResourcePropertiesFragmentDoc}
${StorageObjectPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloAddFilesToResourceGQL extends Apollo.Mutation<ApolloAddFilesToResourceMutation, ApolloAddFilesToResourceMutationVariables> {
    override document = AddFilesToResourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteFilesFromResourceDocument = gql`
    mutation deleteFilesFromResource($resourceId: String!) {
  deleteFilesFromResource(resourceId: $resourceId) {
    ...BaseResourceProperties
    includedObjects {
      storageObject {
        ...StorageObjectProperties
      }
      path
    }
  }
}
    ${BaseResourcePropertiesFragmentDoc}
${StorageObjectPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloDeleteFilesFromResourceGQL extends Apollo.Mutation<ApolloDeleteFilesFromResourceMutation, ApolloDeleteFilesFromResourceMutationVariables> {
    override document = DeleteFilesFromResourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexResourceDocument = gql`
    mutation indexResource($resourceId: String!) {
  indexResource(resourceId: $resourceId) {
    ...BaseResourceProperties
  }
}
    ${BaseResourcePropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloIndexResourceGQL extends Apollo.Mutation<ApolloIndexResourceMutation, ApolloIndexResourceMutationVariables> {
    override document = IndexResourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReindexAllResourcesDocument = gql`
    mutation reindexAllResources {
  reindexAllResources
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloReindexAllResourcesGQL extends Apollo.Mutation<ApolloReindexAllResourcesMutation, ApolloReindexAllResourcesMutationVariables> {
    override document = ReindexAllResourcesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchSchoolAccountsDocument = gql`
    query SearchSchoolAccounts($searchCriteria: PaginatedSearchCriteria!) {
  schoolAccounts(searchCriteria: $searchCriteria) {
    items {
      ...SchoolAccountProperties
    }
    pageIndex
    pageSize
    searchTerm
    totalCount
  }
}
    ${SchoolAccountPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloSearchSchoolAccountsGQL extends Apollo.Query<ApolloSearchSchoolAccountsQuery, ApolloSearchSchoolAccountsQueryVariables> {
    override document = SearchSchoolAccountsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSchoolUserDetailsDocument = gql`
    query GetSchoolUserDetails($id: ID!) {
  schoolUserDetails(id: $id) {
    ...SchoolUserDetailProperties
  }
}
    ${SchoolUserDetailPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetSchoolUserDetailsGQL extends Apollo.Query<ApolloGetSchoolUserDetailsQuery, ApolloGetSchoolUserDetailsQueryVariables> {
    override document = GetSchoolUserDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SchoolClassesByTeacherIdDocument = gql`
    query SchoolClassesByTeacherId($id: ID!) {
  schoolClassesByTeacherId(id: $id) {
    ...SchoolClassProperties
  }
}
    ${SchoolClassPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloSchoolClassesByTeacherIdGQL extends Apollo.Query<ApolloSchoolClassesByTeacherIdQuery, ApolloSchoolClassesByTeacherIdQueryVariables> {
    override document = SchoolClassesByTeacherIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TeacherSchoolClassesDocument = gql`
    query TeacherSchoolClasses {
  teacherSchoolClasses {
    ...SchoolClassProperties
  }
}
    ${SchoolClassPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloTeacherSchoolClassesGQL extends Apollo.Query<ApolloTeacherSchoolClassesQuery, ApolloTeacherSchoolClassesQueryVariables> {
    override document = TeacherSchoolClassesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TeacherSchoolClassesWithDayPlansDocument = gql`
    query TeacherSchoolClassesWithDayPlans($dateRangeFilter: TeacherSchoolClassesFilterInput!) {
  teacherSchoolClasses(input: $dateRangeFilter) {
    ...SchoolClassProperties
    plannerTopics {
      ...PlannerTopicFragment
    }
    plannerBlocks {
      ...PlannerBlockFragment
    }
    plannerNotes {
      ...PlannerNoteFragment
    }
  }
}
    ${SchoolClassPropertiesFragmentDoc}
${PlannerTopicFragmentFragmentDoc}
${PlannerBlockFragmentFragmentDoc}
${PlannerNoteFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloTeacherSchoolClassesWithDayPlansGQL extends Apollo.Query<ApolloTeacherSchoolClassesWithDayPlansQuery, ApolloTeacherSchoolClassesWithDayPlansQueryVariables> {
    override document = TeacherSchoolClassesWithDayPlansDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SchoolClassWithoutSequenceDocument = gql`
    query SchoolClassWithoutSequence($schoolClassId: ID!) {
  schoolClass(schoolClassId: $schoolClassId) {
    ...SchoolClassProperties
  }
}
    ${SchoolClassPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloSchoolClassWithoutSequenceGQL extends Apollo.Query<ApolloSchoolClassWithoutSequenceQuery, ApolloSchoolClassWithoutSequenceQueryVariables> {
    override document = SchoolClassWithoutSequenceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SchoolClassWithSequenceDocument = gql`
    query SchoolClassWithSequence($schoolClassId: ID!) {
  schoolClass(schoolClassId: $schoolClassId) {
    ...SchoolClassProperties
    plannerTopics {
      ...PlannerTopicFragment
    }
    plannerBlocks {
      ...PlannerBlockFragment
    }
  }
}
    ${SchoolClassPropertiesFragmentDoc}
${PlannerTopicFragmentFragmentDoc}
${PlannerBlockFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloSchoolClassWithSequenceGQL extends Apollo.Query<ApolloSchoolClassWithSequenceQuery, ApolloSchoolClassWithSequenceQueryVariables> {
    override document = SchoolClassWithSequenceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SchoolClassWithRosterDocument = gql`
    query SchoolClassWithRoster($schoolClassId: ID!) {
  schoolClass(schoolClassId: $schoolClassId) {
    ...SchoolClassProperties
    students {
      ...AccountProperties
    }
  }
}
    ${SchoolClassPropertiesFragmentDoc}
${AccountPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloSchoolClassWithRosterGQL extends Apollo.Query<ApolloSchoolClassWithRosterQuery, ApolloSchoolClassWithRosterQueryVariables> {
    override document = SchoolClassWithRosterDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SchoolClassWithDetailsDocument = gql`
    query SchoolClassWithDetails($schoolClassId: ID!) {
  schoolClass(schoolClassId: $schoolClassId) {
    ...SchoolClassDetailProperties
  }
}
    ${SchoolClassDetailPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloSchoolClassWithDetailsGQL extends Apollo.Query<ApolloSchoolClassWithDetailsQuery, ApolloSchoolClassWithDetailsQueryVariables> {
    override document = SchoolClassWithDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StudentAccessibleSchoolClassesDocument = gql`
    query StudentAccessibleSchoolClasses {
  studentAccessibleSchoolClasses {
    ...StudentAccessibleSchoolClassProperties
  }
}
    ${StudentAccessibleSchoolClassPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloStudentAccessibleSchoolClassesGQL extends Apollo.Query<ApolloStudentAccessibleSchoolClassesQuery, ApolloStudentAccessibleSchoolClassesQueryVariables> {
    override document = StudentAccessibleSchoolClassesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SchoolClassStudentsDocument = gql`
    query SchoolClassStudents($schoolClassId: ID!) {
  schoolClass(schoolClassId: $schoolClassId) {
    students {
      ...AccountProperties
    }
  }
}
    ${AccountPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloSchoolClassStudentsGQL extends Apollo.Query<ApolloSchoolClassStudentsQuery, ApolloSchoolClassStudentsQueryVariables> {
    override document = SchoolClassStudentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSchoolClassDocument = gql`
    mutation CreateSchoolClass($input: CreateSchoolClassInput!) {
  createSchoolClass(input: $input) {
    ...SchoolClassProperties
  }
}
    ${SchoolClassPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloCreateSchoolClassGQL extends Apollo.Mutation<ApolloCreateSchoolClassMutation, ApolloCreateSchoolClassMutationVariables> {
    override document = CreateSchoolClassDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteSchoolClassDocument = gql`
    mutation DeleteSchoolClass($schoolClassId: ID!) {
  deleteSchoolClass(schoolClassId: $schoolClassId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloDeleteSchoolClassGQL extends Apollo.Mutation<ApolloDeleteSchoolClassMutation, ApolloDeleteSchoolClassMutationVariables> {
    override document = DeleteSchoolClassDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveStudentFromClassDocument = gql`
    mutation RemoveStudentFromClass($input: RemoveStudentFromClassGqlInput!) {
  removeStudentFromClass(input: $input) {
    ...SchoolClassProperties
  }
}
    ${SchoolClassPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloRemoveStudentFromClassGQL extends Apollo.Mutation<ApolloRemoveStudentFromClassMutation, ApolloRemoveStudentFromClassMutationVariables> {
    override document = RemoveStudentFromClassDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EnrollSchoolClassStudentDocument = gql`
    mutation EnrollSchoolClassStudent($schoolClassId: ID!, $studentId: ID!) {
  enrollSchoolClassStudent(schoolClassId: $schoolClassId, studentId: $studentId) {
    ...SchoolClassProperties
  }
}
    ${SchoolClassPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloEnrollSchoolClassStudentGQL extends Apollo.Mutation<ApolloEnrollSchoolClassStudentMutation, ApolloEnrollSchoolClassStudentMutationVariables> {
    override document = EnrollSchoolClassStudentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSchoolClassDocument = gql`
    mutation UpdateSchoolClass($input: UpdateSchoolClassInput!) {
  updateSchoolClass(input: $input) {
    ...SchoolClassProperties
  }
}
    ${SchoolClassPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloUpdateSchoolClassGQL extends Apollo.Mutation<ApolloUpdateSchoolClassMutation, ApolloUpdateSchoolClassMutationVariables> {
    override document = UpdateSchoolClassDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadSchoolClassStudentsDocument = gql`
    mutation UploadSchoolClassStudents($schoolCustomerId: ID!, $classId: ID!, $csvContents: String!) {
  uploadSchoolClassStudents(
    schoolCustomerId: $schoolCustomerId
    classId: $classId
    csvContents: $csvContents
  ) {
    schoolClass {
      ...SchoolClassProperties
      students {
        ...AccountProperties
      }
    }
    errors {
      errorMessage
      rowIndex
      row {
        studentId
        firstName
        lastName
      }
    }
  }
}
    ${SchoolClassPropertiesFragmentDoc}
${AccountPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloUploadSchoolClassStudentsGQL extends Apollo.Mutation<ApolloUploadSchoolClassStudentsMutation, ApolloUploadSchoolClassStudentsMutationVariables> {
    override document = UploadSchoolClassStudentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetNcesSchoolUploadUrlDocument = gql`
    query GetNcesSchoolUploadUrl {
  getNcesSchoolUploadUrl
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetNcesSchoolUploadUrlGQL extends Apollo.Query<ApolloGetNcesSchoolUploadUrlQuery, ApolloGetNcesSchoolUploadUrlQueryVariables> {
    override document = GetNcesSchoolUploadUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetImportedSchoolsCountDocument = gql`
    query GetImportedSchoolsCount {
  importedSchoolsCount
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetImportedSchoolsCountGQL extends Apollo.Query<ApolloGetImportedSchoolsCountQuery, ApolloGetImportedSchoolsCountQueryVariables> {
    override document = GetImportedSchoolsCountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetImportedSchoolDocument = gql`
    query GetImportedSchool($schoolId: String!) {
  importedSchool(schoolId: $schoolId) {
    ...ImportedSchoolProperties
  }
}
    ${ImportedSchoolPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetImportedSchoolGQL extends Apollo.Query<ApolloGetImportedSchoolQuery, ApolloGetImportedSchoolQueryVariables> {
    override document = GetImportedSchoolDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchImportedSchoolsDocument = gql`
    query SearchImportedSchools($searchCriteria: SchoolSearchCriteria!, $skipExistingCustomers: Boolean) {
  importedSchools(
    searchCriteria: $searchCriteria
    skipExistingCustomers: $skipExistingCustomers
  ) {
    ...ImportedSchoolProperties
  }
}
    ${ImportedSchoolPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloSearchImportedSchoolsGQL extends Apollo.Query<ApolloSearchImportedSchoolsQuery, ApolloSearchImportedSchoolsQueryVariables> {
    override document = SearchImportedSchoolsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchSchoolCustomersDocument = gql`
    query SearchSchoolCustomers($searchCriteria: SchoolSearchCriteria!) {
  schoolCustomers(searchCriteria: $searchCriteria) {
    ...SchoolCustomerProperties
  }
}
    ${SchoolCustomerPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloSearchSchoolCustomersGQL extends Apollo.Query<ApolloSearchSchoolCustomersQuery, ApolloSearchSchoolCustomersQueryVariables> {
    override document = SearchSchoolCustomersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchStudentSchoolsDocument = gql`
    query SearchStudentSchools($searchCriteria: SchoolSearchCriteria!) {
  studentSchools(searchCriteria: $searchCriteria) {
    ...StudentSchoolProperties
  }
}
    ${StudentSchoolPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloSearchStudentSchoolsGQL extends Apollo.Query<ApolloSearchStudentSchoolsQuery, ApolloSearchStudentSchoolsQueryVariables> {
    override document = SearchStudentSchoolsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindSchoolCustomerByIdDocument = gql`
    query FindSchoolCustomerById($id: ID!) {
  schoolCustomer(id: $id) {
    ...SchoolCustomerProperties
  }
}
    ${SchoolCustomerPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloFindSchoolCustomerByIdGQL extends Apollo.Query<ApolloFindSchoolCustomerByIdQuery, ApolloFindSchoolCustomerByIdQueryVariables> {
    override document = FindSchoolCustomerByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindStudentSchoolByIdDocument = gql`
    query FindStudentSchoolById($id: ID!) {
  studentSchool(id: $id) {
    ...StudentSchoolProperties
  }
}
    ${StudentSchoolPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloFindStudentSchoolByIdGQL extends Apollo.Query<ApolloFindStudentSchoolByIdQuery, ApolloFindStudentSchoolByIdQueryVariables> {
    override document = FindStudentSchoolByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserSchoolAndAccountsDocument = gql`
    query GetUserSchoolAndAccounts {
  userSchool {
    ...SchoolCustomerProperties
    accounts {
      ...AccountProperties
    }
  }
}
    ${SchoolCustomerPropertiesFragmentDoc}
${AccountPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetUserSchoolAndAccountsGQL extends Apollo.Query<ApolloGetUserSchoolAndAccountsQuery, ApolloGetUserSchoolAndAccountsQueryVariables> {
    override document = GetUserSchoolAndAccountsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindSchoolCustomerAndAccountsByIdDocument = gql`
    query FindSchoolCustomerAndAccountsById($id: ID!) {
  schoolCustomer(id: $id) {
    ...SchoolCustomerProperties
    accounts {
      ...AccountProperties
    }
  }
}
    ${SchoolCustomerPropertiesFragmentDoc}
${AccountPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloFindSchoolCustomerAndAccountsByIdGQL extends Apollo.Query<ApolloFindSchoolCustomerAndAccountsByIdQuery, ApolloFindSchoolCustomerAndAccountsByIdQueryVariables> {
    override document = FindSchoolCustomerAndAccountsByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AdminFindSchoolCustomerAndAccountsByIdDocument = gql`
    query AdminFindSchoolCustomerAndAccountsById($id: ID!) {
  adminSchoolCustomer(id: $id) {
    id
    gradesOffered
    name
    accounts {
      id
      active
      additionalSchoolUserData {
        roles
        schoolCustomerId
        schoolId
        plan
        gradesTaught
        registrationSurveyCompleted
      }
      email
      firstName
      fullName
      lastName
      pending
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloAdminFindSchoolCustomerAndAccountsByIdGQL extends Apollo.Query<ApolloAdminFindSchoolCustomerAndAccountsByIdQuery, ApolloAdminFindSchoolCustomerAndAccountsByIdQueryVariables> {
    override document = AdminFindSchoolCustomerAndAccountsByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BeginNcesSchoolImportDocument = gql`
    mutation BeginNcesSchoolImport {
  beginNcesSchoolImport
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloBeginNcesSchoolImportGQL extends Apollo.Mutation<ApolloBeginNcesSchoolImportMutation, ApolloBeginNcesSchoolImportMutationVariables> {
    override document = BeginNcesSchoolImportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSchoolCustomerDocument = gql`
    mutation CreateSchoolCustomer($input: NewSchoolCustomerInput!) {
  createSchoolCustomer(input: $input) {
    ...SchoolCustomerProperties
  }
}
    ${SchoolCustomerPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloCreateSchoolCustomerGQL extends Apollo.Mutation<ApolloCreateSchoolCustomerMutation, ApolloCreateSchoolCustomerMutationVariables> {
    override document = CreateSchoolCustomerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteSchoolCustomerDocument = gql`
    mutation DeleteSchoolCustomer($id: ID!) {
  deleteSchoolCustomer(id: $id) {
    id
    typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloDeleteSchoolCustomerGQL extends Apollo.Mutation<ApolloDeleteSchoolCustomerMutation, ApolloDeleteSchoolCustomerMutationVariables> {
    override document = DeleteSchoolCustomerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSchoolCustomerDocument = gql`
    mutation UpdateSchoolCustomer($id: ID!, $update: SchoolCustomerUpdateInput!) {
  updateSchoolCustomer(id: $id, update: $update) {
    ...SchoolCustomerProperties
  }
}
    ${SchoolCustomerPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloUpdateSchoolCustomerGQL extends Apollo.Mutation<ApolloUpdateSchoolCustomerMutation, ApolloUpdateSchoolCustomerMutationVariables> {
    override document = UpdateSchoolCustomerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUnpopulatedSkillGroupsDocument = gql`
    query GetUnpopulatedSkillGroups {
  skillGroups {
    id
    title
    color
    sortOrder
    skillGroupKey
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetUnpopulatedSkillGroupsGQL extends Apollo.Query<ApolloGetUnpopulatedSkillGroupsQuery, ApolloGetUnpopulatedSkillGroupsQueryVariables> {
    override document = GetUnpopulatedSkillGroupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUnpopulatedSkillsDocument = gql`
    query GetUnpopulatedSkills {
  getAllSkills {
    id
    title
    description
    groupId
    standards {
      id
      description
      code
      shortCode
      gradeLevel
      skillIds
      standardSystemId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetUnpopulatedSkillsGQL extends Apollo.Query<ApolloGetUnpopulatedSkillsQuery, ApolloGetUnpopulatedSkillsQueryVariables> {
    override document = GetUnpopulatedSkillsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSignedUploadUrlDocument = gql`
    query GetSignedUploadUrl($input: SignedUrlInput!) {
  getSignedUploadUrl(input: $input) {
    id
    signedUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetSignedUploadUrlGQL extends Apollo.Query<ApolloGetSignedUploadUrlQuery, ApolloGetSignedUploadUrlQueryVariables> {
    override document = GetSignedUploadUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSignedDownloadUrlDocument = gql`
    query GetSignedDownloadUrl($input: SignedUrlInput!) {
  getSignedDownloadUrl(input: $input) {
    id
    signedUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetSignedDownloadUrlGQL extends Apollo.Query<ApolloGetSignedDownloadUrlQuery, ApolloGetSignedDownloadUrlQueryVariables> {
    override document = GetSignedDownloadUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSurveyDocument = gql`
    query GetSurvey($surveyTitle: String!) {
  survey(surveyTitle: $surveyTitle) {
    id
    text
    createdAt
    updatedAt
    options {
      ...SurveyMultipleSelectOptionsProperties
      ...SurveySingleSelectOptionsProperties
    }
  }
}
    ${SurveyMultipleSelectOptionsPropertiesFragmentDoc}
${SurveySingleSelectOptionsPropertiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApolloGetSurveyGQL extends Apollo.Query<ApolloGetSurveyQuery, ApolloGetSurveyQueryVariables> {
    override document = GetSurveyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }