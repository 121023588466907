import { createStringEnum, Uuid } from '@lib/shared-interface-utility-types';
import { GradeLevel } from '@lib/shared-util-global-constants';
import { Params } from '@angular/router';

export interface DigitalFileConfig {
    value: string;
    heading: string;
}

export type LinkContent = {
    text: string;
    url: string;
    backgroundColor?: string;
    params?: Params;
    download?: boolean;
};

export interface AccordionConfig extends DigitalFileConfig {
    bold?: boolean;
    content: LinkContent[];
}

export type SpecialFormat = {
    italic?: boolean;
    strong?: boolean;
    indent?: boolean;
    innerHtml?: boolean;
    text: string;
};

export type ProgramContent = {
    mediaId?: Uuid;
    downloadUrl?: string;
    title: string;
    buttonLinks?: [string, string, string, string];
    posterUrl?: string;
    vttUrl?: string;
    programPosterUrl?: string;
    signedDownloadUrl?: string;
    content?: LinkContent[];
};

export type SupportingContent = {
    mediaId: Uuid;
    vttUrl?: string;
    downloadUrl: string;
    title: string;
    buttonLinks: [string, string, string];
    posterUrl?: string;
    programPosterUrl?: string;
    signedDownloadUrl?: string;
};

export type SupportingContentPageConfig = {
    heading: string;
    content: SupportingContent[];
};

export type VideoConfig = {
    mediaId?: Uuid;
    vttUrl?: string;
    videoUrl?: string;
    posterId?: Uuid;
    posterUrl?: string;
    programPosterUrl?: string;
    title?: string;
    content?: (string | SpecialFormat)[];
    downloadUrl?: string;
    buttonLink?: ButtonLinkConfig;
    resourceLink?: string;
};

export type PdVideoConfig = {
    posterUrl?: string;
    programPosterUrl?: string;
    mediaId: Uuid;
    content: (string | SpecialFormat)[];
    footer?: (string | SpecialFormat)[];
    downloadUrl?: string;
};

export type ButtonLinkConfig = {
    links: string[];
    text: string;
    queryParams: Params;
};

export type HeadingConfig = {
    letter: string;
    level: string;
    grade: string;
    color: string;
};

export type DownloadsConfig = {
    downloadAllUrl?: string | LinkContent[];
    resourceLink?: string | LinkContent[];
    headingConfig: HeadingConfig;
    slideDeckHeading: string;
    slideDeckUrl: string | LinkContent[];
    digitFilesConfig: AccordionConfig[];
    certificateUrl: string;
};

export const EbwContentAccessors = createStringEnum([
    'module0Videos',
    'module1Videos',
    'module2Videos',
    'module3Videos',
    'module4Videos',
    'module5Videos',
    'bonusContentLessons',
    'subsequentYearsVideos',
]);
export type EbwContentAccessors = keyof typeof EbwContentAccessors;

export const NarrativeContentAccessors = createStringEnum([
    'narrativeMiniLessonVideos',
    'narrativeTeacherGuideVideos',
]);
export type NarrativeContentAccessors = keyof typeof NarrativeContentAccessors;

export const ExpositoryContentAccessors = createStringEnum([
    'expositoryTeacherGuideVideos',
    'expositoryProblemSolutionVideos',
    'expositoryCompareAndContrastVideos',
    'expositoryCauseAndEffectVideos',
    'expositoryDescriptiveVideos',
]);
export type ExpositoryContentAccessors = keyof typeof ExpositoryContentAccessors;

export const PersuasiveContentAccessors = createStringEnum([
    'teacherGuideVideos',
    'persuasiveVideos',
    'argumentativeVideos',
]);
export type PersuasiveContentAccessors = keyof typeof PersuasiveContentAccessors;

export const GrammarContentAccessors = createStringEnum(['grammarPdVideos']);
export type GrammarContentAccessors = keyof typeof GrammarContentAccessors;

export type GradeConfiguration = {
    instructionsForUseUrl: string;
    content: ProgramContent[];
};

export type GradeContentConfiguration = {
    [GradeLevel.GRADE_5]: GradeConfiguration;
    [GradeLevel.GRADE_6]: GradeConfiguration;
    [GradeLevel.GRADE_7]: GradeConfiguration;
    [GradeLevel.GRADE_8]: GradeConfiguration;
};

export type GradesConstantFileAccessor = keyof GradeContentConfiguration;

export type ProgramGradeLevel = 'Grade 5' | 'Grade 6' | 'Grade 7' | 'Grade 8';
