import { DownloadsConfig } from '../../interfaces/programs.interfaces';

export const EXPOSITORY_5TH_GRADE_DOWNLOAD_CONFIG: DownloadsConfig = {
    resourceLink: [
        {
            text: 'Problem-Solution 5th Grade',
            url: '/resources/374f372c-5e2e-4150-9f77-8db312526980',
        },
        {
            text: 'Compare + Contrast 5th Grade',
            url: '/resources/f0bb9e29-1f9c-4171-9d76-3d357b604b2b',
        },
        {
            text: 'Cause + Effect 5th Grade',
            url: '/resources/8760eeab-d939-4e93-8cc8-b71137c79b53',
        },
        {
            text: 'Descriptive Writing 5th Grade',
            url: '/resources/e40adf2e-f534-4886-b85c-2b7e1ca123e6',
        },
    ],
    headingConfig: {
        letter: 'D',
        level: 'Level D',
        grade: 'Grade 5',
        color: 'var(--program-grade-level-d)',
    },
    slideDeckHeading: 'Expository Slide Decks',
    slideDeckUrl: [
        {
            text: 'Cause_and_Effect_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level D/Cause_and_Effect_Slide_Decks (2).zip',
        },
        {
            text: 'Compare_and_Contrast_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level D/Compare_and_Contrast_Slide_Decks (2).zip',
        },
        {
            text: 'Descriptive_Writing_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level D/Descriptive_Writing_Slide_Decks (2).zip',
        },
        {
            text: 'Problem-Solution_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level D/Problem-Solution_Slide_Decks (2).zip',
        },
    ],
    digitFilesConfig: [
        {
            value: 'causeAndEffect',
            heading: 'Cause + Effect',
            content: [
                {
                    text: 'Cause + Effect Graphic Organizer 1',
                    url: 'https://docs.google.com/presentation/d/1m9lAJXzwwYIy99vNvi7MZAveAOGhCArboHixdx0JoLE/copy',
                },
                {
                    text: 'Cause + Effect Graphic Organizer 2',
                    url: 'https://docs.google.com/presentation/d/1YCs5YXhjjg4StN0--G5m-_gkbZ4de2T70cWvIbNRnLM/copy',
                },
                {
                    text: 'Investigation Cards',
                    url: 'https://docs.google.com/presentation/d/10phz1NwCHYEvJHuJ1orHeEfSPjFSInU8H9SK3yUUFUQ/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/128uUV98TbmeoUn93_Z_kpCUj-QxvXJn9az0uF1XdCyE/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1urjChQ5XBvW1ufci1s9ZmMNyl8Vp7mnql1BjlnambZE/copy',
                },
                {
                    text: 'Practice Writing a Summary',
                    url: 'https://docs.google.com/presentation/d/1_E_m5Buwpt-ugL1kDTq5hyihNWRcwT64-I24iPFsz20/copy',
                },
                {
                    text: 'Claim Sorting Activity',
                    url: 'https://docs.google.com/presentation/d/1zqI9P0Yzv92ykelAfUacN-BgYzr4YRa49QlGtfGHABU/copy',
                },
                {
                    text: 'Identifying Parts of an Intro Paragraph',
                    url: 'https://docs.google.com/presentation/d/1UgrCVja_kQSO1XHH4VdwKzjKDbYY0FaCxvvV6S8QIrg/copy',
                },
                {
                    text: 'Premises in Writing',
                    url: 'https://docs.google.com/presentation/d/1Z5YD8Uv-Mpa5YNuXxktA8gGt-XwtR1VPd1M9Py0idCA/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1pVK0uKEGKABDvOV0betI0NY5DssJ_7P9mMEaYjPNVNE/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1X9KBhMtDFPnQBROJRC1s36k6uFjgGtfJvNJC-Nwy8mo/copy',
                },
                {
                    text: 'Justification Practice',
                    url: 'https://docs.google.com/presentation/d/1viXbEZO5JPnLrA5O7PpFcgTiTGd07Jt-VymoQbyh1D0/copy',
                },
                {
                    text: 'Practice with the Intro and Body Paragraphs',
                    url: 'https://docs.google.com/presentation/d/1v-z3StULbMtb2JKLwWo5JjgI-l_Yntqn_X69j3BAWBE/copy',
                },
                {
                    text: 'Restating the Claim',
                    url: 'https://docs.google.com/presentation/d/12wEc_0sLhbKwCJo1xVVLhiYnUw86J53L-NYF2XCPvRc/copy',
                },
                {
                    text: 'Summarizing Evidence',
                    url: 'https://docs.google.com/presentation/d/1tUvn15CqPnaHFqNrl8fLJxwPr4JMHrtp8Kix8tqT1Zo/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/14D9H71AWBINRIK49RvjQI-qkOhIaX2_SUhmqz0tNGGM/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1omfULa7UuD1JuNuNyB0ZynwO1aRg2dAxeCf25Dv_hNc/copy',
                },
                {
                    text: 'Cause and Effect Rubric',
                    url: 'https://docs.google.com/presentation/d/14np9GZcgGKKNoHALgc_qPdlgoyw5BUMW9WomOOfLod4/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1xIb6lBe9yOncFC9ygTJwknYPbs8GkumOy3uvaV-gbJE/copy',
                },
            ],
        },
        {
            value: 'compareAndContrast',
            heading: 'Compare + Contrast',
            content: [
                {
                    text: 'Compare + Contrast Graphic Organizer TWO TOPICS',
                    url: 'https://docs.google.com/presentation/d/1JNGvHajZ09dCzyw7ciWz09-fiaCD3aIVbcoHyEqYbig/copy',
                },
                {
                    text: 'Compare + Contrast Graphic Organizer TWO TEXTS',
                    url: 'https://docs.google.com/presentation/d/1RJdMDrWLevDWfNaf4f2Rq4Y_PtrHwQ5p5i-GWyAR7eM/copy',
                },
                {
                    text: 'Game Cards',
                    url: 'https://docs.google.com/presentation/d/1O7b8ODK3ptFtkFuBTlg7rMuwEt1IWqzHPIShwecBbC4/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1IrIRzvmsoEjndRtI2sE7xqka83RhB7sz8rma2guHJuA/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1KPy-HVNPxYLmNPw7aBHGEILb7DgjEHvXI85xo69Jqhs/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1f359C6Cjzc_wkN-MMeeS2CU6SkBFFwRUELtko_tNyo4/copy',
                },
                {
                    text: 'Matching Prompts + Claims',
                    url: 'https://docs.google.com/presentation/d/1WgQOZlekP6qNrEdaa1lX21gnV-zR3i_SMHjASqx00DQ/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1KsiLHtnvBNbG9R20CZTyjGgBLCyq4LfuhwDtT4D59O0/copy',
                },
                {
                    text: 'Premises in Writing Activity',
                    url: 'https://docs.google.com/presentation/d/1kuTdBFVyN5C0y19B2A3CoSRmE3QGw6SZ0NPXzJTnJo8/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1qtJuhSpRIlD68z1KAZSxG3HSOzMOYDuS8NNCeDoCnns/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1mlf0EbMe_7OzLJ0SWsBM0UZnO7n7-CntfUt2Ek6CDgA/copy',
                },
                {
                    text: 'Justification Activity',
                    url: 'https://docs.google.com/presentation/d/1dhBI2zjORZlxtZDE3uzo1let3tu7ZzaOCzCcANK3KdA/copy',
                },
                {
                    text: 'Intro and Body Paragraphs Practice',
                    url: 'https://docs.google.com/presentation/d/1GNV2j0c1HY5c_Lr9ZXlFoiBHCrcU2t5oy4WwTvTcV6c/copy',
                },
                {
                    text: 'Restating Claim',
                    url: 'https://docs.google.com/presentation/d/1mV51dRwt_YFY4YtiQk_5jiR3cMYhboudBQxGJx5G4o8/copy',
                },
                {
                    text: 'Summarizing Evidence',
                    url: 'https://docs.google.com/presentation/d/1zCl-OC4mXonDCVJm1IrXGZ0RyQjp4NFX2d7BGIu4y78/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/10BPQXYVFbLKcZivreNqB3CxwSQchzhlRBVB04X-nR4M/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1JP-yEnN_4hNT35WJrpTplKMgEOUF575LMyx0D9H2lP8/copy',
                },
                {
                    text: 'Compare and Contrast Rubric',
                    url: 'https://docs.google.com/presentation/d/1bEzcfrtpooY3b0Dow4MX0CbWsy00A86b9efXTDMWPOA/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1F2ePYH3lIu87vrHVeJJJrPiZtd_mxbCO-aAVu4GpIcY/copy',
                },
            ],
        },
        {
            value: 'descriptiveWriting',
            heading: 'Descriptive Writing',
            bold: true,
            content: [
                {
                    text: 'Descriptive Writing Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1ff402bVplNJkpZIeS99_fagAREpkUBJ_yMnKGp_z4XY/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1zmbBQKl_77HXMAmMGqCTBpfLtT5rSpTCW1qVmAxGVlw/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1SCQLw9vrOLp8NfThDyfm8EUuBZz95V6uoZ4a2ewHTx4/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1iv8OMQHjFRyafLHtGkGxVAP2dizklPUMV2-356JeWlU/copy',
                },
                {
                    text: 'Practicing Thesis Statements',
                    url: 'https://docs.google.com/presentation/d/1GfoxzFnikqFJF2_MNrnvtDrdhAV5nG77a26PaN3T7aI/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1t3vBJLLi2iFjNY3TEsHOKKKV7vFqGPHs90ROCHd3n7Y/copy',
                },
                {
                    text: 'Understanding the Body Paragraph',
                    url: 'https://docs.google.com/presentation/d/1MSL79dt3QJxRRuZbVPiEaGCb2EUi2eLp7_9Yk6j8IrA/copy',
                },
                {
                    text: 'Practicing the Topic Sentences',
                    url: 'https://docs.google.com/presentation/d/1_84yqtP3ves3bpRy00i_EDlrg8wrksl3z9l6_g8i8ow/copy',
                },
                {
                    text: 'Concluding Statement Practice',
                    url: 'https://docs.google.com/presentation/d/1SdI9iXpCiWYMMUqXiHRlo71RJncpxFQPvYipvvUqrCw/copy',
                },
                {
                    text: 'Writing a Body Paragraph',
                    url: 'https://docs.google.com/presentation/d/1djbqZifxdC9Sm8bE7zea5myhq-Hrr-BZsMVVvCsyzyo/copy',
                },
                {
                    text: 'Restating the Thesis Statement',
                    url: 'https://docs.google.com/presentation/d/1SvLHeQXVUSsbFIu-1Gudh36tca0_73QlhNDi89EAP9M/copy',
                },
                {
                    text: 'Summarizing the Topic Sentence',
                    url: 'https://docs.google.com/presentation/d/13ZU1Ersi9ajYe0GDIhWLYRieVCUtUD1fpmaGjl8-eN4/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1XEyRjg1mZ06jri8cr-FigF84P8O6wChrYRe6DXYeW_Y/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1glAjtuzEidBogGXZ6oOh_sSxoKlVcr8A__ADG7i-dG4/copy',
                },
                {
                    text: 'Descriptive Writing Rubric',
                    url: 'https://docs.google.com/presentation/d/1DOKyQ6YtxVHcOyiBO8KQ-1sjYYB2Z-Xn1sqqLB8A1AM/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1HL5UgW3UZrpizuW12m7VKMZ5PxbBElj9WxmChcHGa7c/copy',
                },
            ],
        },
        {
            value: 'problemSolution',
            heading: 'Problem-Solution',
            content: [
                {
                    text: 'Problem-Solution Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1Djq0D51sI0NGNRbZeYov7foKgXs2ttrunB6-rt6EHXc/copy',
                },
                {
                    text: 'Sorting Cards',
                    url: 'https://docs.google.com/presentation/d/1dLJ4375vmDdzbl5ecqNIjEPLxz5pGgWskgNwg1Pieuw/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1WVTDlTsfpwkVAOzcAB4sxvOjLhDCSi8aaVd_oGQC39o/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1iYy4ZtCc1qrHPfP1DKQ1wlyc8_ZcIbzdiJ5Mwu1k0jE/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1UMiiuuiQtnJ-JmnFyljY4-VsJVGFFTmofVESLuWYiC4/copy',
                },
                {
                    text: 'Investigation Cards',
                    url: 'https://docs.google.com/presentation/d/1SvCYvKXDaSpD5KOfGsBgqMGq4fHWvwm7te2RZW9hCKE/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1h5x9bJozUCbI1IQgB2GQrAW8LUdfox90bXJNuPYVc7M/copy',
                },
                {
                    text: 'Writing Premises Activity',
                    url: 'https://docs.google.com/presentation/d/141osXnxSybURAjtqFU1s2D29aK3t4HxLXfPie2ou6Sc/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1qiI6dVVcqZD9Tp3kGSMLCQ6tn2faKMmwCCEngbpKKP8/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1J_yG1UT-58KpNoDfc1W7tSt_5VOKwTQebCyZI51Y9nc/copy',
                },
                {
                    text: 'Justification Activity',
                    url: 'https://docs.google.com/presentation/d/1JG3qufxOnCfM8xjofxNXNLnJyjfxWK3MiHo5AIUKfXQ/copy',
                },
                {
                    text: 'Intro and Body Paragraph Practice',
                    url: 'https://docs.google.com/presentation/d/1rClyVPdJPZQFFHuiOfsmRbA6sV8Nxp4DqKql5M0TZPU/copy',
                },
                {
                    text: 'Restating the Thesis ',
                    url: 'https://docs.google.com/presentation/d/14z9FNBZzeszB7u_Lnb98EyQcDz8KCue-5Eg5aj5Myhk/copy',
                },
                {
                    text: 'Summarizing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1M5kUnVX6Yd8Qxby1wFxg8j8ApPqLGGc1SRgReQzY3Ys/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1gDrWy8cDTyUpnzTh7c397-PDdKDvYncX8I9B9Dzb-Kc/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/152PkBUg6hWjwtvhE3qOF15BV7ixf4M-5JL1Ect-vgew/copy',
                },
                {
                    text: 'Problem-Solution Rubric',
                    url: 'https://docs.google.com/presentation/d/15reKTayCgDJun6NChv_ooYPKWfIvKIv5UUX1X8jx9S0/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1o0SwzEWPFqZVmi4j0_EE91z0_dufIxVeE9o7XRuzk2g/copy',
                },
            ],
        },
    ],
    certificateUrl:
        '/resource-assets/program-content/EB Writing Program/Level D/Certificate_of_Completion_Expository_Writing (2).pdf',
};
