import { DownloadsConfig } from '../../interfaces/programs.interfaces';

export const EBW_5TH_GRADE_DOWNLOAD_CONFIG: DownloadsConfig = {
    resourceLink: '/resources/3681c85d-6278-4dc2-8fb1-db2847c3d397',
    headingConfig: {
        letter: 'D',
        level: 'Level D',
        grade: 'Grade 5',
        color: 'var(--program-grade-level-d)',
    },
    slideDeckHeading: 'EBW Approach Slide Decks',
    slideDeckUrl:
        '/resource-assets/program-content/EB Writing Program/Level D/Level_D_Slide_Decks.zip',
    digitFilesConfig: [
        {
            value: 'overview',
            heading: 'The EBW Approach',
            content: [
                {
                    text: 'Essential Questions Generator',
                    url: 'https://docs.google.com/presentation/d/11rnLjfEHNFR5gBmXmFE6Lsf7H60HpPUwHkybLQvg-YQ/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1VyXl96ap6IY6sp9zM85UNL6qTaSb7jTCdfgxiKbqU8k/copy',
                },
                {
                    text: 'Pre-Assessment',
                    url: 'https://docs.google.com/presentation/d/13pV0eIlS38IE-T0T1R0Mf7VZwAYxD1HPA-8Bm3opRos/copy',
                },
                {
                    text: 'Post-Assessment',
                    url: 'https://docs.google.com/presentation/d/1IyDT5nmUfZ87qFBvcAgOXhQueAotAgNnfhsXWr7eSFM/copy',
                },
                {
                    text: 'Assessment Trackers',
                    url: 'https://docs.google.com/presentation/d/1CqIDhfgsY6DMnoZAst4J75BTOwoVjrB3BKd3UItwFnk/copy',
                },
            ],
        },
        {
            value: 'introductoryParagraph',
            heading: 'The Introductory Paragraph',
            content: [
                {
                    text: 'Introducing EBW Handout',
                    url: 'https://docs.google.com/presentation/d/1-p-zp7SUTqJcsPgjvO2cEyTkk74dx3rPTHYuYILJ2RM/copy',
                },
                {
                    text: 'TAG Practice',
                    url: 'https://docs.google.com/presentation/d/19pRPKrJOOxLJIstK6aCdlKymbLhE5x92RY7AHgCYdmw/copy',
                },
                {
                    text: 'Practice Writing a Summary',
                    url: 'https://docs.google.com/presentation/d/12N40rxOI1TJYIEGDkO5RLgsxbRxhRtVDcJNzrqn-0Ks/copy',
                },
                {
                    text: 'Claim vs Statement Practice',
                    url: 'https://docs.google.com/presentation/d/1ZVS1GjaPFW5a75Q3fLIh7gU6JSQs4DmHVZPcrtgvB2M/copy',
                },
                {
                    text: 'Claim vs Statement Sort',
                    url: 'https://docs.google.com/presentation/d/1xe83-STj-rz6YQY-RJnyUAcSKvZam1zmht5RbYxSwkU/copy',
                },
                {
                    text: 'Introductory Paragraph Practice',
                    url: 'https://docs.google.com/presentation/d/1W2j9YAmVPzqNjs1d_0VhHdNk36-YBP7eIQvo1cVLitU/copy',
                },
            ],
        },
        {
            value: 'bodyParagraphs',
            heading: 'The Body Paragraphs',
            content: [
                {
                    text: 'Premise Practice',
                    url: 'https://docs.google.com/presentation/d/10nDhag_Mm-uZ8gG3iuASVAAe3sTcvNTXwVnLxdFI-mE/copy',
                },
                {
                    text: 'Evidence Practice',
                    url: 'https://docs.google.com/presentation/d/17YIvSYeQGUkfGSjH2oaElMk2SlRdtSeocdYp0z-KANo/copy',
                },
                {
                    text: 'Justification Practice',
                    url: 'https://docs.google.com/presentation/d/1F3mSUjYJmTNCPMKkD0PJc4-4UVgwcI18dGdYN4kUtzM/copy',
                },
                {
                    text: 'Body Paragraph Practice',
                    url: 'https://docs.google.com/presentation/d/1eBK9C6SMMab0EsnT9-AfWsbWaIEk_odOY_YWQaLgPVc/copy',
                },
            ],
        },
        {
            value: 'conclusionParagraph',
            heading: 'The Conclusion Paragraph',
            content: [
                {
                    text: 'Restating the Claim Practice',
                    url: 'https://docs.google.com/presentation/d/1h7fzaeFsWaLN2H13ekigK1VtM-h7359m-VhqD9idGvE/copy',
                },
                {
                    text: 'Summarizing Evidence Practice',
                    url: 'https://docs.google.com/presentation/d/1DGHBGW6O4iYRlLzIqaT55k30MH3qb22ulKcxEpGelhw/copy',
                },
                {
                    text: 'Conclusion Paragraph Practice',
                    url: 'https://docs.google.com/presentation/d/1RW53DJrJ7JnP-LUZ54gViWgIKoW4sA4AbpLL5QZDnJw/copy',
                },
                {
                    text: 'Additional RTL Practice',
                    url: 'https://docs.google.com/presentation/d/18UqW28yVIQHe_muUgBSjSIizlBC5rqzvYUxQf_gEBzk/copy',
                },
            ],
        },
        {
            value: 'miniLessons',
            heading: 'Mini Lessons',
            content: [
                {
                    text: 'Transitions Practice',
                    url: 'https://docs.google.com/presentation/d/1JpzdH75K_CR_LxqgJnOelwYzGv7wVwv-AYRHGWlUheI/copy',
                },
                {
                    text: 'Student Thesaurus',
                    url: 'https://docs.google.com/presentation/d/1FZ9uybc242FaYULynOO-GHkKc9cB-C7P7s6uaOqyYPk/copy',
                },
                {
                    text: 'MLA Formatting Handout',
                    url: 'https://docs.google.com/presentation/d/1eugefIQuVdZqIWY6oi7n-0Vaxsgd8Uy13FIuB2m9nLU/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1fvDOc1lqN89XzE2G-7pE3Wcp_j17CDngFC8MOENXqOc/copy',
                },
                {
                    text: 'Socratic Seminar Checklist + Rubric',
                    url: 'https://docs.google.com/presentation/d/1IOvNFx9VEAAr6rHLpUgihGz8CsnOTIyMCr_9oucQZkM/copy',
                },
            ],
        },
        {
            value: 'bonusContent',
            heading: 'Bonus Content',
            content: [
                {
                    text: 'Student Reflection',
                    url: 'https://docs.google.com/presentation/d/1Ru_sbPZScHrSpnHnUtRbf0vG3oK5BlChoIO2qW5b8nA/copy',
                },
                {
                    text: 'Response to Literature Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1-rF5yMci34ZuOq7QWrtodP-6ypwHoLHP4fhGshJnzV4/copy',
                },
                {
                    text: 'Response to Literature Rubric',
                    url: 'https://docs.google.com/presentation/d/1L5SgBhKXl6-IrWbAhpoOqdSZ6dC-7DhUHEkdkpxkHSc/copy',
                },
                {
                    text: 'Response to Informational Text Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1bkmoZnieWCsT4roalWm-xhRlCg9hwexgKLaHG4T4bc4/copy',
                },
                {
                    text: 'Compare & Contrast Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1A6zZYoG0WuYpv91Go1R2IP9VuLfA968izEeS9-o6ImI/copy',
                },
                {
                    text: 'Multiple Texts Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1VM47ttSasIOjzuK26NXk_1l-PzjuiQtAH32s0MmV62E/copy',
                },
            ],
        },
    ],
    certificateUrl:
        '/resource-assets/program-content/EB Writing Program/EBW_Certificate_of_Completion (2).pdf',
};
