import { DownloadsConfig } from '../../interfaces/programs.interfaces';

export const PERSUASIVE_AND_ARGUMENTATIVE_6TH_GRADE_DOWNLOAD_CONFIG: DownloadsConfig = {
    resourceLink: [
        {
            text: 'Persuasive Writing 6th Grade',
            url: '/resources/90a71c96-4a8b-4294-8333-68933bb288fb',
        },
        {
            text: 'Argumentative Writing 6th Grade',
            url: '/resources/973d769a-cb10-4abc-951b-d150cb327186',
        },
    ],
    headingConfig: {
        letter: 'E',
        level: 'Level E',
        grade: 'Grade 6',
        color: 'var(--program-grade-level-e)',
    },
    slideDeckHeading: 'Persuasive and Argumentative Slide Decks',
    slideDeckUrl: [
        {
            text: 'Argumentative_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level E/Argumentative_Slide_Decks.zip',
        },
        {
            text: 'Persuasive_Writing_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level E/Persuasive_Writing_Slide_Decks.zip',
        },
    ],
    digitFilesConfig: [
        {
            value: 'argumentativeWriting',
            heading: 'Argumentative Writing',
            bold: true,
            content: [
                {
                    text: 'Argumentative Writing Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1hryMJYsdajpUaNCVJWwAttI57yu9KY5ALX729ATOPH8/copy',
                },
                {
                    text: 'Case Report',
                    url: 'https://docs.google.com/presentation/d/1oKbtsBmlVNFfpy1RExp29bnWNh-eKV2oi1PIKqAmdXc/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1LTfqK6ZCSBORmp-nR0fDqyjTk_GzLKjXKPWMcS2XGng/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/125rKJ_jDPYVmb_y-36v87QM0nvsgeIEv8WiHmSGEc5Q/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1z12RwHpCggnIB_X4VPhDdrjJCj-EkZrhjYuIu5ojsHI/copy',
                },
                {
                    text: 'Claim Sorting Activity',
                    url: 'https://docs.google.com/presentation/d/1mcq7gVxpph7J2Rx_FODGwdt-2fOOvWsV8njtMdKMpic/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1d0850a-BWtVpCzkc0ctEmAqcK1PundXlEiS8Q6RFdNA/copy',
                },
                {
                    text: 'Premises Writing Activity',
                    url: 'https://docs.google.com/presentation/d/1jqmXGLcGh5ckfhCogyQ6hV26tT2fKwzvR-ZDAfrATb0/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1TURKtgcg8e4hZfpWlorbJZniofrJCkC5214eK6st-9o/copy',
                },

                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1-wQ08bH_VjpE54TtNBECvna_iLxjKOhTH1Gkliakwio/copy',
                },
                {
                    text: 'Justification Activity',
                    url: 'https://docs.google.com/presentation/d/1DIZ_4Ja0sOSWTdewMaDfdk7tUT0O9nHi4hJm-x6GYcQ/copy',
                },
                {
                    text: 'Claims and Counterclaims Activity',
                    url: 'https://docs.google.com/presentation/d/1DmZMBvYjqWydu3R3eRZsVYbhXWMiaKlZeH5dOT7dNes/copy',
                },
                {
                    text: 'Practice with the Body Paragraph',
                    url: 'https://docs.google.com/presentation/d/1rb89UbLXCuWafjhmt69OaPABFFsKddu6Q3f0kJfIi0o/copy',
                },
                {
                    text: 'Restating Claim',
                    url: 'https://docs.google.com/presentation/d/1rCl6SQc8vx7gb7uKnv8QL4tksoUejqe7Lkf0WLmHzrQ/copy',
                },
                {
                    text: 'Summarizing Evidence',
                    url: 'https://docs.google.com/presentation/d/1Hftwm4-TFVdVG2h7On5RU6jsNNQLuyhLVab0a43NSHA/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1qJGpED6arorZoLW3B9gO2Kvd47R9o4ymKihMroOswhA/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1if3bDe_eRGVFbABiwWO000Noq8XnM2OvJYRLrFfu6mA/copy',
                },
                {
                    text: 'Argumentative Writing Rubric',
                    url: 'https://docs.google.com/presentation/d/1LbMYLgLcvVrG1pC-1U5CAttZis7hoUPmv1UYQ9N1Do0/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1xxTCyMNssBIkgrk1cqmEHj09H0UUpiUzFMD--4P59Ao/copy',
                },
            ],
        },
        {
            value: 'persuasiveWriting',
            heading: 'Persuasive Writing',
            bold: true,
            content: [
                {
                    text: 'Persuasive Writing Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1e0ohIlmUQEuOab5BkVM7mZTi7qZZNeT8ytoeVVo87t8/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1YY6v72WMNCVFsK6N-R7jKrE9KJIx0X4_I9ldkcTiUYg/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1TZNqIm87FJfgVIvU6FpO5EXFSLaFPW-HEOpE7OKNT78/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/19GMCcYX0SpnpUQT1bDAWoOVg2oXz8lAaxIR7t6Ol6hM/copy',
                },
                {
                    text: 'Claim Sorting Activity',
                    url: 'https://docs.google.com/presentation/d/1M1SL1GaiOAKQmY2i8ygejzEC8T8-v1izNAnMZFEwRS4/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1gFmCnE3hNUn9s1cftbtxI0YBqNc-xUaMzG-kNkQ4A5U/copy',
                },
                {
                    text: 'Premises Writing Activity',
                    url: 'https://docs.google.com/presentation/d/1ifNuUuAPJ-RMAFfVoaVI2Ln3-NWtAidav-UqTD-91wE/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1jlgC372ET4-6-KLHtu70AnBhaU0ZJPY229gYoovsFx8/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1HNVQW-cdJP5_x9y2q1eU5cOKPtmpmVXs0D978-gpWOE/copy',
                },
                {
                    text: 'Justification Activity',
                    url: 'https://docs.google.com/presentation/d/1Q92-GFP8UT0IHzGbLCcPqL_qVzi9xfjsFwQZSQv7Pl8/copy',
                },
                {
                    text: 'Practice with the Body Paragraph',
                    url: 'https://docs.google.com/presentation/d/1lJizHjJYrVsRNMnMwT-Iv7OJn9XxrISK6Om2hxXBmtU/copy',
                },
                {
                    text: 'Restating Claim',
                    url: 'https://docs.google.com/presentation/d/1SyGirKCbst4a2Vq2oVgIYFo-D1L69poJYHJ84ipN5aY/copy',
                },
                {
                    text: 'Summarizing Evidence',
                    url: 'https://docs.google.com/presentation/d/19eha6tu6EMZGGWV0aha7BYxxdeLJvuqEsEGDJ-2w80A/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1ozIeRU35HWoiD4g1CKneTx-IvYnz013diyjyLkcCG-g/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1wzM7m8t5D2-S5AfT7CH-DafSdgZH-ca3TFKffqp_qDo/copy',
                },
                {
                    text: 'Persuasive Writing Rubric',
                    url: 'https://docs.google.com/presentation/d/1zydeJ_FOKchzgqt5AldKaVX7aOk0kiMF4PiXwHln4k4/copy',
                },
                {
                    text: 'Helpful Hints',
                    url: 'https://docs.google.com/presentation/d/14X5lAgKbqDCgGva-GgkSsDFNLBU7OhFduq3rnB58Sg8/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1C5_Rnig31exCauDEIT2zYgMYkqtj9Q8OBnHyewLcCoI/copy',
                },
            ],
        },
    ],
    certificateUrl:
        '/resource-assets/program-content/EB Writing Program/Level E/Certificate_of_Completion_Argumentative_and_Persuasive_Writing.pdf',
};
