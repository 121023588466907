import { DownloadsConfig } from '../../interfaces/programs.interfaces';

export const PERSUASIVE_AND_ARGUMENTATIVE_8TH_GRADE_DOWNLOAD_CONFIG: DownloadsConfig = {
    resourceLink: [
        {
            text: 'Persuasive Writing 8th Grade',
            url: '/resources/1dfdf7ca-7949-455e-8130-ccab96842a50',
        },
        {
            text: 'Argumentative Writing 8th Grade',
            url: '/resources/5f96dce2-a3a1-4f8f-9e36-585c56839d59',
        },
    ],
    headingConfig: {
        letter: 'G',
        level: 'Level G',
        grade: 'Grade 8',
        color: 'var(--program-grade-level-g)',
    },
    slideDeckHeading: 'Persuasive and Argumentative Slide Decks',
    slideDeckUrl: [
        {
            text: 'Argumentative_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level G/Argumentative_Slide_Decks.zip',
        },
        {
            text: 'Persuasive_Writing_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level G/Persuasive_Writing_Slide_Decks.zip',
        },
    ],
    digitFilesConfig: [
        {
            value: 'argumentativeWriting',
            heading: 'Argumentative Writing',
            bold: true,
            content: [
                {
                    text: 'Argumentative Writing Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1zBVBfncmFghuVu333OlhvRbXxTwToys6s2NJHet-jQA/copy',
                },
                {
                    text: 'Case Report',
                    url: 'https://docs.google.com/presentation/d/1SiNCX78yKDTLFnMhNWiXFzRCThDMlUT2XDkjWuNKUeM/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1lR43e4Co7_h7sXkhJpt0P7ss33WAqq07mp6RoUKlgfI/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1n9g3XN587yYWRZhUTIgRAwgwNglF6NyEj36UjH3DHvQ/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/11Qk_eXaw_x4TqtJLQMEhK3AFekQj_vjyAUPgZyUKWGs/copy',
                },
                {
                    text: 'Claim Sorting Activity',
                    url: 'https://docs.google.com/presentation/d/1BHQvSuRoR-_5uYDqIl43HGol-oTSWA1wdRMlj5z6PiU/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1JHCogJO7askDkWITVHldmsevBJNuK2wPUGymcNT58Vw/copy',
                },
                {
                    text: 'Premises Writing Activity',
                    url: 'https://docs.google.com/presentation/d/1-Y9kAaE25sGc4cel_PmXVh2sPhxKAACJFu3eVbNcmgY/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1rcBBN9M0FI_x2VEMM9BOOsFzIPla1A7FbDXTN_vzkjc/copy',
                },

                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1rEbuz4csone1LEXibZWw8gNgdatQciM2v20O4Z6xjUw/copy',
                },
                {
                    text: 'Justification Activity',
                    url: 'https://docs.google.com/presentation/d/1zotLw03h8ofxdoYcZ8KWtzY0dA5ekKlF22s5FdJ4ijo/copy',
                },
                {
                    text: 'Claims and Counterclaims Activity',
                    url: 'https://docs.google.com/presentation/d/1aIpKue5h1Y2hGUXs3lh7Jp33Nnii8kRAsEWqdgeqZFA/copy',
                },
                {
                    text: 'Practice with the Body Paragraph',
                    url: 'https://docs.google.com/presentation/d/1oHDeTNzeWIgdgAbkD7KBHEZa7CBbhwj3lDKZZeZ48Ko/copy',
                },
                {
                    text: 'Restating Claim',
                    url: 'https://docs.google.com/presentation/d/1CiLIVXeHg7mIkx8nhOfGFzw8qDucDsIw9sfOAkNtgfs/copy',
                },
                {
                    text: 'Summarizing Evidence',
                    url: 'https://docs.google.com/presentation/d/1vdcGF-tEdP6_9O8mcg2fwtti45Se1mB0zvTwpZToem8/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1EZy8jUvl-o-r-GmW5DRoSTfuT5-AYmNNbHCEYmHTvCI/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1uo4KUFwUJw5DKH4mIiwsZLpH04t0qoBxrrDeAzXWQzA/copy',
                },
                {
                    text: 'Argumentative Writing Rubric',
                    url: 'https://docs.google.com/presentation/d/11hyhpaTZ4_LNMdITcOAMp1sFFFZEDmFi27OY0lsgVDI/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/158SaQpIvEqd0qgpTWHwch5dN-bR-rDXxe_WJg3dZboY/copy',
                },
            ],
        },
        {
            value: 'persuasiveWriting',
            heading: 'Persuasive Writing',
            bold: true,
            content: [
                {
                    text: 'Persuasive Writing Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1m9UsrcpFxCJ85oXVDeACtJpeqHQwDXezMGeGOCDPiP0/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/12JPWLbmePIE57YAH_q4WLin7RHvzLxfZVlS8YUVVq4Q/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1NUqLV0rr10DZTcNjvqZJuOEgw4ZW8jtt4sSwCdHwSlM/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1DkffUxuZhjfDrn9k6FlYMhRN2znzZkwL45YHyadtMuI/copy',
                },
                {
                    text: 'Claim Sorting Activity',
                    url: 'https://docs.google.com/presentation/d/1iyb-6Ruw8JM02NywHM-I3YBGslsbUcl9lgEQc5idPJc/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1jLrkxkFLyrQNXsuu7gJuSzQgmYbyfdJB29wfA8mxxNs/copy',
                },
                {
                    text: 'Premises Writing Activity',
                    url: 'https://docs.google.com/presentation/d/1ifNuUuAPJ-RMAFfVoaVI2Ln3-NWtAidav-UqTD-91wE/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/116Gb3OUreiWunL_d-KNH2P92GquUCDIYRoWcFS6VVF4/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1grtzVD2c5tk-ZK151a25K46LoW-HWb_HUFl9aITu2yw/copy',
                },
                {
                    text: 'Justification Activity',
                    url: 'https://docs.google.com/presentation/d/1N26Eau0CYVXKBbxzhH_V6dT75eZbqc8E1k_ONARkf_c/copy',
                },
                {
                    text: 'Practice with the Body Paragraph',
                    url: 'https://docs.google.com/presentation/d/1bdeWtIHTr7Kz8U5cAJosnhr8bXSPYwlSRsXHK3YzGMI/copy',
                },
                {
                    text: 'Restating Claim',
                    url: 'https://docs.google.com/presentation/d/1hs9S5O83vZeqRozlZovTZYenA8WHzM7i5nqDwpvXxQM/copy',
                },
                {
                    text: 'Summarizing Evidence',
                    url: 'https://docs.google.com/presentation/d/1FYnAEQBKMJAJEURKLHKMe7gtbV3YntVzMg-LE9nmZUk/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1g4t_qRYIKVKOSmOh3FeCzpba3fUCNOXlufi8Zb5F6OM/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1u3kNa6nMIHv8fPQaGeaK2RBlYnmdVfCwPRFEthuXEBo/copy',
                },
                {
                    text: 'Persuasive Writing Rubric',
                    url: 'https://docs.google.com/presentation/d/1rQpF-DVCpUWaQZaAdFQvak8oB2GZpa86DMi8Mpqcrcs/copy',
                },
                {
                    text: 'Helpful Hints',
                    url: 'https://docs.google.com/presentation/d/12LFbowO3V4bpT0WEJdTBqWom-_804mr9jaPMvb5nDg8/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1lPsjI7jBfd9PYbQGgxTU756I6rs4690x9kWRToEV60A/copy',
                },
            ],
        },
    ],
    certificateUrl:
        '/resource-assets/program-content/EB Writing Program/Level G/Certificate_of_Completion_Argumentative_and_Persuasive_Writing.pdf',
};
