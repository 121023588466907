import { EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
    CommonMissingTranslationHandler,
    httpLoaderFactory,
    I18N_APP_INITIALIZER,
} from '@lib/frontend-shared-data-i18n-service';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
    provideRouter,
    withComponentInputBinding,
    withEnabledBlockingInitialNavigation,
} from '@angular/router';
import { APP_ROUTES } from './app.routes';
import { provideServiceWorker } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { provideGraphqlClient } from '@lib/frontend-shared-data-graphql-providers';
import { Apollo } from 'apollo-angular';
import { provideAuthenticationService } from '@lib/frontend-shared-accounts-data-authentication-service';
import { provideCountryFlagIconPath } from '@lib/frontend-shared-ui-phone-number-control';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    provideApiDomain,
    provideImageBlobReduce,
    provideSchoolAppPath,
    provideStudentAppPath,
} from '@lib/frontend-shared-util-miscellaneous-providers';
import { provideFeatureAccessConfiguration } from '@lib/frontend-shared-data-feature-access-service';
import { provideAppUpdateInitializer } from '@lib/frontend-shared-util-update-service';
import { provideCustomTitleStrategy } from '@lib/frontend-shared-util-route-title';

const mobilePattern = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;
const isMobile = (window: Window) => mobilePattern.test(window.navigator.userAgent);
const isDesktop = (window: Window) => !isMobile(window);

export const APP_PROVIDERS: Array<Provider | EnvironmentProviders> = [
    // Updated way to provide BrowserAnimationsModule
    provideAnimations(),
    // This is how we use traditional service configurations.
    // https://angular.io/guide/standalone-components#configuring-dependency-injection
    importProvidersFrom(
        // https://ionic.zendesk.com/hc/en-us/articles/10386373742231-Angular-Standalone-Components-with-Ionic
        IonicModule.forRoot({
            platform: {
                /**
                 * For devices with a touchscreen the default `desktop` function returns false
                 * and the default `mobile` function return true.
                 * This is not always wanted, so we've overwritten these to use the user agent.
                 * https://ionicframework.com/docs/angular/platform#customizing-platform-detection-functions.
                 **/
                desktop: isDesktop,
                mobile: isMobile,
            },
        }),
        TranslateModule.forRoot({
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: CommonMissingTranslationHandler,
            },
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ),
    provideApiDomain(environment.api.domain),
    provideAppUpdateInitializer(),
    provideAuthenticationService(environment.aws.poolData),
    provideCountryFlagIconPath('/assets/icons/flags'),
    provideCustomTitleStrategy('EBA'),
    provideGraphqlClient(environment.api.domain),
    provideHttpClient(withInterceptorsFromDi()),
    provideImageBlobReduce(),
    provideFeatureAccessConfiguration(environment.featureAccess),
    provideRouter(APP_ROUTES, withComponentInputBinding(), withEnabledBlockingInitialNavigation()),
    provideServiceWorker('ngsw-worker.js', {
        enabled: environment.serviceWorkerEnabled,
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
    }),
    provideStudentAppPath(environment.studentApplication.domain),
    provideSchoolAppPath(environment.schoolApplication.domain),
    I18N_APP_INITIALIZER,
    Apollo,
];
