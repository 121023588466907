import { DownloadsConfig } from '../../interfaces/programs.interfaces';

export const EXPOSITORY_8TH_GRADE_DOWNLOAD_CONFIG: DownloadsConfig = {
    resourceLink: [
        {
            text: 'Problem-Solution 8th Grade',
            url: '/resources/91772040-f2e0-4142-988d-481b9c193c44',
        },
        {
            text: 'Compare + Contrast 8th Grade',
            url: '/resources/91f30531-5581-4772-a9aa-c6352b5b247a',
        },
        {
            text: 'Cause + Effect 8th Grade',
            url: '/resources/bff0b9fd-7100-4575-93ed-6cdc551a73f1',
        },
        {
            text: 'Descriptive Writing 8th Grade',
            url: '/resources/d8b95f8e-cff9-4d35-acb8-1b3edc5c93f2',
        },
    ],
    headingConfig: {
        letter: 'G',
        level: 'Level G',
        grade: 'Grade 8',
        color: 'var(--program-grade-level-g)',
    },
    slideDeckHeading: 'Expository Slide Decks',
    slideDeckUrl: [
        {
            text: 'Cause_and_Effect_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level G/Cause_and_Effect_Slide_Decks.zip',
        },
        {
            text: 'Compare_and_Contrast_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level G/Compare_and_Contrast_Slide_Decks.zip',
        },
        {
            text: 'Descriptive_Writing_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level G/Descriptive_Writing_Slide_Decks.zip',
        },
        {
            text: 'Problem-Solution_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level G/Problem-Solution_Slide_Decks.zip',
        },
    ],
    digitFilesConfig: [
        {
            value: 'causeAndEffect',
            heading: 'Cause + Effect',
            content: [
                {
                    text: 'Cause + Effect Graphic Organizer 1',
                    url: 'https://docs.google.com/presentation/d/1Dd81Rz7wGNvL7Ho5r80cXWVp7OjFEb1JfDBtGfXxK7g/copy',
                },
                {
                    text: 'Cause + Effect Graphic Organizer 2',
                    url: 'https://docs.google.com/presentation/d/1A5r1w36VWBOY5uvEJfjk2YN3hRm0uWOQKtpD8d1h3UA/copy',
                },
                {
                    text: 'Investigation Cards',
                    url: 'https://docs.google.com/presentation/d/1p38zOTLSrTjJKSC9-6G0Royv6suPPfmGpGUNGadCXrI/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1sVE_iifh6CNE9c6WtguNgIHThuvkIFrXhDXzJNQlyOw/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1xZ0HV--izznpQInxoYCgIctftutfQx8YoFRGOBZjyIk/copy',
                },
                {
                    text: 'Practice Writing a Summary',
                    url: 'https://docs.google.com/presentation/d/1auc04uWYb4nxJw4Jl1TpWY2fyYne55OR8UMsbF_-yII/copy',
                },
                {
                    text: 'Claim Sorting Activity',
                    url: 'https://docs.google.com/presentation/d/1CrPrYXDp_lOe91gw-KW2XRXANbmyTbWiIMJ2gGC6StE/copy',
                },
                {
                    text: 'Identifying Parts of an Intro Paragraph',
                    url: 'https://docs.google.com/presentation/d/1vnim5plzvSTQdgU5qS7uQL_tqzm7ZKROpy8l2OaQNvo/copy',
                },
                {
                    text: 'Premises in Writing',
                    url: 'https://docs.google.com/presentation/d/1M_0Dm3MpuLP-_oW3nr9A1VGIQjdVL7SqFWq8Tn_pMRo/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1sp15JSkr_KGQ0zJzi1-Nd1ba_CI5RTzpcOoWEjs8p2c/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1P5a9dzzcsopDQyPew13MvoX_Blust1_d4ciF6unye94/copy',
                },
                {
                    text: 'Justification Practice',
                    url: 'https://docs.google.com/presentation/d/1z_u6sylr4zmYVPHXQYGs4Rv9MtbkaYwlPpznKRtYSDA/copy',
                },
                {
                    text: 'Practice with the Intro and Body Paragraphs',
                    url: 'https://docs.google.com/presentation/d/1MazwEXg76ZE6c8ALfvOgw1rmdmWeggLYP-38AJgNpsQ/copy',
                },
                {
                    text: 'Restating the Claim',
                    url: 'https://docs.google.com/presentation/d/1QzA2SqRK-PUYRYkVXbgbwbk2Ny2piuGobGACZCjNe5c/copy',
                },
                {
                    text: 'Summarizing Evidence',
                    url: 'https://docs.google.com/presentation/d/11qfhlIFwqzajn-MmEmOaqBE2Tl71lMSSMMNdO32cbNs/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1NIxH0nYtVmzzV8jnMmdn5OjB7mNOjCkIy38_GVuc6lA/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1Ho3wNGlcc5J7HWq9XUJpR0-YkqzSHYk7Qz1479EcvGI/copy',
                },
                {
                    text: 'Cause and Effect Rubric',
                    url: 'https://docs.google.com/presentation/d/1uzPq2PKsoga4A5ofLijea6KvJ31M_QQzi-krWd3tEEA/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1Bfd9W5xVW9Sj68q_rSTQBOf_EuaGbugGOYz4a5PA6IA/copy',
                },
            ],
        },
        {
            value: 'compareAndContrast',
            heading: 'Compare + Contrast',
            content: [
                {
                    text: 'Compare + Contrast Graphic Organizer TWO TOPICS',
                    url: 'https://docs.google.com/presentation/d/1MvdPYsemGSCYkLAmzn8Uz59NNkfzE2GbLNEKxh0CwJs/copy',
                },
                {
                    text: 'Compare + Contrast Graphic Organizer TWO TEXTS',
                    url: 'https://docs.google.com/presentation/d/1V87UhFQJ2HOglTahRBy8lix5fjrSJ8TfGrt_qEd87u8/copy',
                },
                {
                    text: 'Game Cards',
                    url: 'https://docs.google.com/presentation/d/1G5fNv4Ft1eQp5nC7rL5LETDCo9qTMQVuGDa1V5X3VwI/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1FKNCh0d_D8aX4NXaZurBJ4eeHVsEoOevzRkYAriE53U/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/173TqZBH0v4XCoMF0hBoKfJvhClCX0L2KAGSRXQ-T0e0/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1Kzx0toluPMFPpfr8t0GKUbg8YEkrDBIroDp-wlHlx24/copy',
                },
                {
                    text: 'Matching Prompts + Claims',
                    url: 'https://docs.google.com/presentation/d/1So1pbQTi2cYwePv4r-nswF_-8F9N64wwF3aBxZQ1PZc/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1h1P7zWPDSGAxiUUni7YL3jb1E2lB1GWP22s3scc9I0w/copy',
                },
                {
                    text: 'Premises in Writing Activity',
                    url: 'https://docs.google.com/presentation/d/1pNH4kaq0mCjyi5ucdm7Sf5DN6c71heoD-g8DydclxpY/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1bvqSRRrO0X3lZfmJvwBc6ITmFTVvS58xhk6VjcFc2Tc/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1Sus4LrFI5sb77wrd_AQPEWjXJnKvNHjHkVUq-gXSBbA/copy',
                },
                {
                    text: 'Justification Activity',
                    url: 'https://docs.google.com/presentation/d/1ixjreDOnsvgYgDfj8HryRVpgBKXx9PusYdTuv8CRvC0/copy',
                },
                {
                    text: 'Intro and Body Paragraphs Practice',
                    url: 'https://docs.google.com/presentation/d/19G-EFd9p4HUBMOQe8IWAK2u6_lzE-WOmurm5GOLFbNI/copy',
                },
                {
                    text: 'Restating Claim',
                    url: 'https://docs.google.com/presentation/d/19B9jZDW2o2uzxDm44F2pWQZHByIEWFyVQo-Ubi-r0TA/copy',
                },
                {
                    text: 'Summarizing Evidence',
                    url: 'https://docs.google.com/presentation/d/1WHMonMTvw8lgjRXlOVc8XZpFL2gWP62wFGrC1HDq90k/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1_6taInVWBEEboARg5ObsKKiHwOSuvXhDu1plGJ7m6UY/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1PR2NNZA8Z37qBABf0uG_uw3H-74kS6LH2UhCC1526OQ/copy',
                },
                {
                    text: 'Compare and Contrast Rubric',
                    url: 'https://docs.google.com/presentation/d/1CP1WU4RQQosglbMXozL3AZyoSyWzSir4stiZpFl6UHU/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1eoYH6nKTj3nafyajXnmsdMjqdQg0Xni3uPfl-Ll_Nzg/copy',
                },
            ],
        },
        {
            value: 'descriptiveWriting',
            heading: 'Descriptive Writing',
            content: [
                {
                    text: 'Descriptive Writing Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/14jLaN5yWXb98E5LjQHhpC68KYTYEsQyBCruDRr4Dyj4/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1PMRbGlM4qi95yw7zBwXzdiqzNTeHF-muL8oQCgLiJ2E/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/11cRq9i68Nysja2p9GPAAffC9rj8YR-ayYlq0isuMsd0/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1yijOP0qpy-EjsDsJv-osyieKx_FOzK2qWmeV7FDEbBY/copy',
                },
                {
                    text: 'Practicing Thesis Statements',
                    url: 'https://docs.google.com/presentation/d/1MxCQoHTLKdL7ygqADX7kl1WgTYbU1Jn4EFjXKXYZGeY/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1brJELU9cn2ZkHNxyG-lTqnMnk-TmLuiu8Z_tGWK-UmI/copy',
                },
                {
                    text: 'Understanding the Body Paragraph',
                    url: 'https://docs.google.com/presentation/d/1B251nyDuOtmM5Rd3XSoet-yWCGjR8ZGTEEoD2bgM3ZM/copy',
                },
                {
                    text: 'Practicing the Topic Sentences',
                    url: 'https://docs.google.com/presentation/d/1D4KNmtlytpA7M3iVhM6PhDJsQMab4P77JP5ySMh5afc/copy',
                },
                {
                    text: 'Concluding Statement Practice',
                    url: 'https://docs.google.com/presentation/d/1G_iOxlkNloWew2tEeb9WUYHJ7qIAuhQZa0c6R_x4ENI/copy',
                },
                {
                    text: 'Writing a Body Paragraph',
                    url: 'https://docs.google.com/presentation/d/1pI2GFjACF4bk8qmHe7Ek_F_VQJPCtpH1za5Ykj89leE/copy',
                },
                {
                    text: 'Restating the Thesis Statement',
                    url: 'https://docs.google.com/presentation/d/1Jk8WFVkpbbeWjNITzmyY3m2NklYWcwDLajUQ53Pnsrc/copy',
                },
                {
                    text: 'Summarizing the Topic Sentence',
                    url: 'https://docs.google.com/presentation/d/1XvS07yfB2uXg_jthK9O2Xk1RuQqoz10fqOvqm4uwLOw/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1LRN6qqRIaDH5a-RhIh7Se5h_bym1VmK5tKKUJvZqZ94/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1K0e725zQGudcihuy1_4Jr8XBHj43umjtpPuGjcjbsFE/copy',
                },
                {
                    text: 'Descriptive Writing Rubric',
                    url: 'https://docs.google.com/presentation/d/1PS0hgdNdefVqwdyJxPQQ1nHoxo_vPBneYjZj3jA4vF8/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1UqyVHYuXHhynFHXIxFNfurHnrc3U7xdwhojLSHrfV0Q/copy',
                },
            ],
        },
        {
            value: 'problemSolution',
            heading: 'Problem-Solution',
            content: [
                {
                    text: 'Problem-Solution Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1fB6pZAkNR-04qu8GTdqDkd4e768ZvnP2ti8f9KrTzco/copy',
                },
                {
                    text: 'Sorting Cards',
                    url: 'https://docs.google.com/presentation/d/1abT3k4QgXmt-auTYsdXY4kzhB5OyjbTHd9LcB_qx6fM/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1HKq1DfQuFN1kem9oN8ilf3xQiYmMgYGJo1cy_C9uh1g/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1s-hPYygflEeO3RHGXpkALKMrV1cS10by10MbiRcoldM/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1iTBEQ92kym8Qv2JAoOzi56ltWxrqhEn_j13T9Z9jvhU/copy',
                },
                {
                    text: 'Investigation Cards',
                    url: 'https://docs.google.com/presentation/d/1b5v_6vS4W4hz8PHwmn5ll70Jzw3pGS4ZsstFN4iQJew/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1b5v_6vS4W4hz8PHwmn5ll70Jzw3pGS4ZsstFN4iQJew/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1sILPV0dhhqSOGRxCuXJUAQvhY0oNGLk5yghm6mTeBHM/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1fUAbcxgfA-RDx73zLIfl3ZBfosjavj86OzvROGOe3Ig/copy',
                },
                {
                    text: 'Justification Activity',
                    url: 'https://docs.google.com/presentation/d/1t5FJwZSvGYEfWnkWPaHtRUNpx0-Omi1a1J6im0JtQdg/copy',
                },
                {
                    text: 'Intro and Body Paragraph Practice',
                    url: 'https://docs.google.com/presentation/d/1X1BUXx4Coneq20lUfP6XUOqKV0botSR-jU5VYe5lN8Y/copy',
                },
                {
                    text: 'Restating the Thesis',
                    url: 'https://docs.google.com/presentation/d/134JIgdAm5-HGqbVGyO9qCOMFNwrbu2oZ3Re0i2Rqv6k/copy',
                },
                {
                    text: 'Summarizing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1wD4y0wfw2UpiWvbLyjfT4yEVNOzDgcQV0atCeOgKVaA/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1BrYfTFagAj5jGbg8b6-Z0eA_3BqbgAvwkjMjwtXkIes/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1QwAm7ZbTgjV6EzXatqfuFzc9az9qLdYM3fMCqjyNvfs/copy',
                },
                {
                    text: 'Problem-Solution Rubric',
                    url: 'https://docs.google.com/presentation/d/15vtkWr3ZEmasEw7FJdCFg8J-Vv-XMogy1MVmf-mdbdQ/copy',
                },
                {
                    text: 'Premises Writing Activity',
                    url: 'https://docs.google.com/presentation/d/1xzjod3_HtMicYM5jIboS9Fii5zPEvaygMid-t-s2BFc/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1T7E-juWV4-CUZXbViiykhCtUhity_-lHZ6XJmsL_-Xo/copy',
                },
            ],
        },
    ],
    certificateUrl:
        '/resource-assets/program-content/EB Writing Program/Level G/Certificate_of_Completion_Expository_Writing.pdf',
};
