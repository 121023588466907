import { DownloadsConfig } from '../../interfaces/programs.interfaces';

export const PERSUASIVE_AND_ARGUMENTATIVE_5TH_GRADE_DOWNLOAD_CONFIG: DownloadsConfig = {
    resourceLink: [
        {
            text: 'Persuasive Writing 5th Grade',
            url: '/resources/264baeb7-4da6-462c-9f2f-6f9f80284d5a',
        },
        {
            text: 'Argumentative Writing 5th Grade',
            url: '/resources/ee24e8f9-9554-4089-a23e-79e70f4f454e',
        },
    ],
    headingConfig: {
        letter: 'D',
        level: 'Level D',
        grade: 'Grade 5',
        color: 'var(--program-grade-level-d)',
    },
    slideDeckHeading: 'Persuasive and Argumentative Slide Decks',
    slideDeckUrl: [
        {
            text: 'Argumentative_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level D/Argumentative_Slide_Decks (2).zip',
        },
        {
            text: 'Persuasive_Writing_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level D/Persuasive_Writing_Slide_Decks (2).zip',
        },
    ],
    digitFilesConfig: [
        {
            value: 'argumentativeWriting',
            heading: 'Argumentative Writing',
            content: [
                {
                    text: 'Argumentative Writing Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1j8ieAjhEgz3t4CgapgrjHI7Ow-XnZi_Aa4g1svieF_A/copy',
                },
                {
                    text: 'Case Report',
                    url: 'https://docs.google.com/presentation/d/1F6mN8ZcmNOT7Wh0sKiGBIuCbheKZmGT0Ygnw6ywFU7E/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1tBqj6z_pEwFlf49HzEao5OHsMgGOdhWv1_kK8EQetqw/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1Iodr1eFy60f4to0ch131y_g1Zq7aYpmglNg6Gje2j2w/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1B5kxBZ4UNrtGzsRZ-OenExQGOuVH2Jd2shDprwgXwh4/copy',
                },
                {
                    text: 'Claim Sorting Activity',
                    url: 'https://docs.google.com/presentation/d/1Dp27fgpXLqPxtnKtFkffI6i5gH7HouN2ScSyGrb_E0o/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1CEh3R243snAjwe2pplU66F0ELWCD59FIBiVH2bUk7ls/copy',
                },
                {
                    text: 'Premises Writing Activity',
                    url: 'https://docs.google.com/presentation/d/1dHijLLmHTLaSfL9Q2chKkXzkVYq6r3kczwOF2HxR6xs/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1R_w5yvZNtGEwARR4yo8mWZ2F09Iw8av4i4uXef3feRM/copy',
                },

                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1YB5cT6XbDPqKfIWwEsR6YQ1Mp1kgj9_lK9yUSim1cXo/copy',
                },
                {
                    text: 'Justification Activity',
                    url: 'https://docs.google.com/presentation/d/1XRT6nek8TwVIk79hE5kx9nInymsW3ydZBVkBU829IQQ/copy',
                },
                {
                    text: 'Claims and Counterclaims Activity',
                    url: 'https://docs.google.com/presentation/d/12va--3vzejM-nIJW8M9bwb5y1ElXpijY2o-t-NC50uc/copy',
                },
                {
                    text: 'Practice with the Body Paragraph',
                    url: 'https://docs.google.com/presentation/d/1aSfszwYdcovkmz4D6-jBwrH2GRlFNwuMNLsNUtAkDWg/copy',
                },
                {
                    text: 'Restating Claim',
                    url: 'https://docs.google.com/presentation/d/1NsfYIpDV6hKweKD6G-wBKzxTrw47nB4wr1NXQ1YTeYk/copy',
                },
                {
                    text: 'Summarizing Evidence',
                    url: 'https://docs.google.com/presentation/d/1NMRi1xg-8lxm06z53nXtKA0qBem94uim4vYejeRixcc/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1FKe174eb426-VQN0AwVIeOlU_4fi3fA6KbKLLtw51xM/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1lzffHarWIKs3rd3OxOpb1PjC-hRQPmgpmJeGMDo1MlU/copy',
                },
                {
                    text: 'Argumentative Writing Rubric',
                    url: 'https://docs.google.com/presentation/d/1j8ieAjhEgz3t4CgapgrjHI7Ow-XnZi_Aa4g1svieF_A/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/12tg3Ks65dyeZ5f59Eiz8_TE3VnZJ3uVKoAF-wNygZvQ/copy',
                },
            ],
        },
        {
            value: 'persuasiveWriting',
            heading: 'Persuasive Writing',
            content: [
                {
                    text: 'Persuasive Writing Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1ZDNzLJ-ra0etv6iheq6VT5eDBFiJpQ9tMKbHt7RY6Bo/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1nbDLQ2zyTuRlopf-R1PfxgfQxf4KGda2gQq9xw0tghA/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1WUHMf7dwRIGUr2qRNlCoRgoKOYmmkRmlWge8tPn9RQg/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1I0FWyi7rCPiVLmJhnPnrYlJ_w3PVbXrduktZXWhklPE/copy',
                },
                {
                    text: 'Claim Sorting Activity',
                    url: 'https://docs.google.com/presentation/d/1v-N6dvtB3EGhnZBX4Nj-qiLpyr6jGv105pFnb3UiBQ0/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1T-wlFvVNg4soThCCGaKh8CT-boiALxwKf0-CrQ5AhMQ/copy',
                },
                {
                    text: 'Premises Writing Activity',
                    url: 'https://docs.google.com/presentation/d/1q-p_Bis7QrEVlwjP6fIlYIkxmxkg8YceMubPeMMft4k/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1tBs2vIkGYFbx-4id1-3h7kjKiDuw95DfGEm_0Q2M5q8/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1tvx8D9tgdClNNLXBuGoNPTrt3TIkxHJwMKsDmMHaqL4/copy',
                },
                {
                    text: 'Justification Activity',
                    url: 'https://docs.google.com/presentation/d/1YB8jvNi910FHTPA_EstUpQUzGnlBytjhageBta7vYiw/copy',
                },
                {
                    text: 'Practice with the Body Paragraph',
                    url: 'https://docs.google.com/presentation/d/1_1of9w9EAiHUw-8tVrvvlXDhplgwtYPQ5-Wtf4jewRA/copy',
                },
                {
                    text: 'Restating Claim',
                    url: 'https://docs.google.com/presentation/d/1EGsH9P5tIDOTiB7SJ5-i9J5vBPfaHaJkzWFL7WCWZZw/copy',
                },
                {
                    text: 'Summarizing Evidence',
                    url: 'https://docs.google.com/presentation/d/1Kfav-ASEN_gS_CDu7HqVB9BUG5Jc4WAd0usawCfxFPg/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1OXMG1oIy3E7R0ZoyNOsaiTCMcT09-0cJvjOTYqlBj9Y/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1p-thgNmyqKq3w21aS9k6la9J2cBGxIAZ2GQoCgHmiCQ/copy',
                },
                {
                    text: 'Persuasive Writing Rubric',
                    url: 'https://docs.google.com/presentation/d/1GIUFH9OTVZDdVctMH8NnKlQVGDsUU7hfOrzqriYm-c4/copy',
                },
                {
                    text: 'Helpful Hints',
                    url: 'https://docs.google.com/presentation/d/1CeUoU8LNcX8UHDFUHp9AYZJnF4uobhxzCRZetlfI2WA/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1i2xRONiDkQSlgAvDE2ztmVplFUBV5mVedG0uTdSNIBI/copy',
                },
            ],
        },
    ],
    certificateUrl:
        '/resource-assets/program-content/EB Writing Program/Level D/Certificate_of_Completion_Argumentative_and_Persuasive_Writing (2).pdf',
};
