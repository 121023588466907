import { DownloadsConfig } from '../../interfaces/programs.interfaces';

export const EBW_8TH_GRADE_DOWNLOAD_CONFIG: DownloadsConfig = {
    resourceLink: '/resources/fcdd1bd2-1d23-4091-aebc-2f5c88c55f4e',
    headingConfig: {
        letter: 'G',
        level: 'Level G',
        grade: 'Grade 8',
        color: 'var(--program-grade-level-g)',
    },
    slideDeckHeading: 'EBW Approach Slide Decks',
    slideDeckUrl:
        '/resource-assets/program-content/EB Writing Program/Level G/Level_G_Slide_Decks_.zip',
    digitFilesConfig: [
        {
            value: 'overview',
            heading: 'The EBW Approach',
            content: [
                {
                    text: 'Essential Questions Generator',
                    url: 'https://docs.google.com/presentation/d/14ne1rGimkrwuQaIchZOdOFrsZGbyCPddl0T9mPadqfc/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1Zq4mLM9648QVAR6ChFF4ODkCS1D9cPwUMMtzU661Bvo/copy',
                },
                {
                    text: 'Pre-Assessment',
                    url: 'https://docs.google.com/presentation/d/1NQQ10EcSN7GqXHIcUxczlBVv5jtktMsTykABJeM7jfk/copy',
                },
                {
                    text: 'Post-Assessment',
                    url: 'https://docs.google.com/presentation/d/15qFBN_tej_kQ8FjnaYx1ORSwjx2cHNNeOlO9jEgIkN4/copy',
                },
                {
                    text: 'Assessment Trackers',
                    url: 'https://docs.google.com/presentation/d/1jqwS_Ph6GOgrHY1WJrQG3TxcEqHo2WYTqljp_jqMaWM/copy',
                },
            ],
        },
        {
            value: 'introductoryParagraph',
            heading: 'The Introductory Paragraph',
            content: [
                {
                    text: 'Introducing EBW Handout',
                    url: 'https://docs.google.com/presentation/d/1hc2zhCK1bvskyT9UgYkTp7sak7ZO58L6pV0YDtgGFSc/copy',
                },
                {
                    text: 'TAG Practice',
                    url: 'https://docs.google.com/presentation/d/1ZrA2S3WUmiRFD-WrR7QHlfrSkDhD5mi9PKZAvDmeduo/copy',
                },
                {
                    text: 'Practice Writing a Summary',
                    url: 'https://docs.google.com/presentation/d/1k9OJORDqOInsPTxI5UiU-g30wOQQ0KlbzNWBVD42tK4/copy',
                },
                {
                    text: 'Claim vs Statement Practice',
                    url: 'https://docs.google.com/presentation/d/1SUhenFKBH4HnlaPaTzNS5yjL9cKUYSM19_kci0u0ZW8/copy',
                },
                {
                    text: 'Claim vs Statement Sort',
                    url: 'https://docs.google.com/presentation/d/1i4M3FAZ5P7aYU27JEEgCFtzeu1TCRfygIvHVajOQruw/copy',
                },
                {
                    text: 'Introductory Paragraph Practice',
                    url: 'https://docs.google.com/presentation/d/1vt3SRq07ltSi9HLviiGFGGz5lXb1Pk4IiwMP2sUp37E/copy',
                },
            ],
        },
        {
            value: 'bodyParagraphs',
            heading: 'The Body Paragraphs',
            content: [
                {
                    text: 'Premise Practice',
                    url: 'https://docs.google.com/presentation/d/1fNht_jrp9XCtM4neAhn-a8TLqk1LX326slGQcdXrqes/copy',
                },
                {
                    text: 'Evidence Practice',
                    url: 'https://docs.google.com/presentation/d/1SwfQEdlAHh9yqRSAArqh-W-IQ9W0t4hWLMruiGi2cbc/copy',
                },
                {
                    text: 'Justification Practice',
                    url: 'https://docs.google.com/presentation/d/10dTZpk35bLZ37fy390h1dlPhQxOPg-ruBA8BCpkJS_I/copy',
                },
                {
                    text: 'Body Paragraph Practice',
                    url: 'https://docs.google.com/presentation/d/1YmvAwxLsFjrX_OhvPg6DG_D0MFcf6U1e51sD-_ERcvo/copy',
                },
            ],
        },
        {
            value: 'conclusionParagraph',
            heading: 'The Conclusion Paragraph',
            content: [
                {
                    text: 'Restating the Claim Practice',
                    url: 'https://docs.google.com/presentation/d/1qADYG_yr2FtpevHbIj1BNjVBUjtkfgLForTOgJbVzQ4/copy',
                },
                {
                    text: 'Summarizing Evidence Practice',
                    url: 'https://docs.google.com/presentation/d/1Uhs03ADgqbjEV2brUq-Nek1PPdrEOC3gwEA_-WwdxwA/copy',
                },
                {
                    text: 'Conclusion Paragraph Practice',
                    url: 'https://docs.google.com/presentation/d/1LUTQMDkaLrjMt4hzU5t63nmWEndFBomPVbf8QYof90k/copy',
                },
                {
                    text: 'Additional RTL Practice',
                    url: 'https://docs.google.com/presentation/d/1OpYL1oOPFASumL__uZkrbkg0JlawOFgzQyZptoFDb1g/copy',
                },
            ],
        },
        {
            value: 'miniLessons',
            heading: 'Mini Lessons',
            content: [
                {
                    text: 'Transitions Practice',
                    url: 'https://docs.google.com/presentation/d/1C2vAUBZXetnbTgK30YAje4n9LV6SWa8DRmSz1rN-oyc/copy',
                },
                {
                    text: 'Student Thesaurus',
                    url: 'https://docs.google.com/presentation/d/1mE_-Dw3F1xH6FJne-ojyLvDbxnaNZ9w8jQ3zC-fqO2A/copy',
                },
                {
                    text: 'MLA Formatting Handout',
                    url: 'https://docs.google.com/presentation/d/1XUH8p2n74oUZt9I-IAtYPvFcOKnugqaFpK81Cls7l7Q/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/14KOW153VUxj-rlVTv7OVTYYxw3V6Gax_QuFd8j-QlWo/copy',
                },
                {
                    text: 'Socratic Seminar Checklist + Rubric',
                    url: 'https://docs.google.com/presentation/d/1zqGoFJVS_VhxE_V1OhitGXy8GlaESZodUCyFVFcARms/copy',
                },
            ],
        },
        {
            value: 'bonusContent',
            heading: 'Bonus Content',
            content: [
                {
                    text: 'Student Reflection',
                    url: 'https://docs.google.com/presentation/d/1vEdnk7_npZtB79mlBYnB-AdtlBOzO0IlL6B7ZVFqdGY/copy',
                },
                {
                    text: 'Response to Literature Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1ojCLPSaDdCoQ1nshdvHsOrTFXy1_C6H1GBIUGMxkM54/copy',
                },
                {
                    text: 'Response to Literature Rubric',
                    url: 'https://docs.google.com/presentation/d/1t_FvHXdC-CdIh4v4hfNt8uJP5gWGnNy4HOcN_NMKJ70/copy',
                },
                {
                    text: 'Response to Informational Text Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1p7Y_Avdum9PyGpynoqL233nPYkUyXvSCdipxoCOYdQ0/copy',
                },
                {
                    text: 'Compare & Contrast Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1xKxe7Nv9L6v3ETxrnA47x-Wux_uec4NdxU92aBhAI-8/copy',
                },
                {
                    text: 'Multiple Texts Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1afmccLmTNnXd_I1JzlpeQrv-cg6Nf0Ri5kr4XnlC8eQ/copy',
                },
            ],
        },
    ],
    certificateUrl:
        '/resource-assets/program-content/EB Writing Program/Level G/EBW_Certificate_of_Completion.pdf',
};
