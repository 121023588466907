/* eslint-disable @typescript-eslint/naming-convention */

import { EnumValues } from './enum-types';

/*
    These colors were *kindly* lent to us by Google Calendar.
    If you go to change the color of a calendar,
    you'll see the following 24 colors in 4 rows of 6 colors each.
    Each "section" below corresponds to a row of those colors
*/
export const SchoolClassColor = {
    '#ad1357ff': '#ad1357ff',
    '#f4511eff': '#f4511eff',
    '#e4c442ff': '#e4c442ff',
    '#0b8043ff': '#0b8043ff',
    '#4050b5ff': '#4050b5ff',
    '#8e24aaff': '#8e24aaff',

    '#d81b60ff': '#d81b60ff',
    '#ef6d00ff': '#ef6d00ff',
    '#c0ca33ff': '#c0ca33ff',
    '#029688ff': '#029688ff',
    '#7986cbff': '#7986cbff',
    '#795648ff': '#795648ff',

    '#d50201ff': '#d50201ff',
    '#f09300ff': '#f09300ff',
    '#7cb442ff': '#7cb442ff',
    '#039be5ff': '#039be5ff',
    '#b39ddbff': '#b39ddbff',
    '#616161ff': '#616161ff',

    '#e67c73ff': '#e67c73ff',
    '#f5bf25ff': '#f5bf25ff',
    '#33b679ff': '#33b679ff',
    '#4284f4ff': '#4284f4ff',
    '#9e69afff': '#9e69afff',
    '#a79b8eff': '#a79b8eff',
} as const;

export type SchoolClassColor = EnumValues<typeof SchoolClassColor>;

export const ALL_SCHOOL_CLASS_COLORS = Object.values<SchoolClassColor>(SchoolClassColor);

export const DEFAULT_COLOR = SchoolClassColor['#ad1357ff'];

export function assertSchoolClassColor(color: string): asserts color is SchoolClassColor {
    if (SchoolClassColor[color as SchoolClassColor] == undefined) {
        throw new Error(`Invalid color: ${color}`);
    }
}

export const PlannerColor = {
    ...SchoolClassColor,
    '#cb6ce6ff': '#cb6ce6ff', // Reading Literature
    '#ffde59ff': '#ffde59ff', // Reading Informational Text
    '#38b6ffff': '#38b6ffff', // Vocabulary
    '#ff5757ff': '#ff5757ff', // Speaking + Listening
    '#ffbd59ff': '#ffbd59ff', // Grammar
    '#7ed957ff': '#7ed957ff', // Writing
};
export type PlannerColor = EnumValues<typeof PlannerColor>;
export const ALL_PLANNER_COLORS = Object.values<PlannerColor>(PlannerColor);
