import { Injectable } from '@angular/core';
import {
    catchError,
    distinctUntilChanged,
    firstValueFrom,
    map,
    Observable,
    of,
    shareReplay,
} from 'rxjs';
import {
    Account,
    AccountProfileAdminChanges,
    AccountProfileChanges,
    InternalInvitee,
    MinimalStudentData,
    RawSchoolUserDataUpdate,
    SchoolCustomerInvitee,
    SchoolProfileAdminChanges,
    SchoolUserDataUpdate,
    StudentInvitee,
    TeachingInfo,
} from '@lib/shared-interface-account';
import { Email, Uuid } from '@lib/shared-interface-utility-types';
import {
    ApolloAccountPlanWillUpgradeGQL,
    ApolloAdminUpdateInternalProfileGQL,
    ApolloAdminUpdateSchoolProfileGQL,
    ApolloAuthenticatedAccountGQL,
    ApolloInternalAccountsGQL,
    ApolloInviteInternalAccountGQL,
    ApolloInviteSchoolAccountGQL,
    ApolloInviteStudentAccountGQL,
    ApolloLoginAsGQL,
    ApolloRandomStudentPasswordsGQL,
    ApolloResendInviteGQL,
    ApolloSetAccountPasswordGQL,
    ApolloStudentAccountGQL,
    ApolloStudentLoginEmailGQL,
    ApolloUpdateAccountAccessGQL,
    ApolloUpdateLegalDocumentTimestampsGQL,
    ApolloUpdateSchoolUserDataGQL,
    ApolloUpdateStudentDataGQL,
    ApolloUpdateStudentDataGqlInput,
    ApolloUserUpdateAccountProfileGQL,
    handleMutationResult,
    handleQueryResult,
} from '@lib/frontend-shared-data-gql-operations';
import { switchMapIfDefined } from '@lib/shared-util-rxjs';
import { AuthenticationService } from '@lib/frontend-shared-accounts-data-authentication-service';
import {
    isSurveyMultipleSelectResultUpdate,
    isSurveySingleSelectResultUpdate,
    RawSurveyResultUpdate,
    SurveyResultUpdate,
} from '@lib/shared-interface-survey-question';

@Injectable({ providedIn: 'root' })
export class AccountsService {
    public readonly staffAccounts$: Observable<Account[]> = this.internalAccountsGql
        .watch()
        .valueChanges.pipe(
            map((result) => result.data.internalAccounts),
            shareReplay(1),
        );
    public readonly userAccount$: Observable<Account | undefined> =
        this.authenticationService.authenticationState$.pipe(
            distinctUntilChanged(),
            switchMapIfDefined((state) => {
                if (!state?.isLoggedIn) return of(undefined);

                const result$ = this.authenticatedAccountGql.watch().valueChanges;
                return result$.pipe(handleQueryResult());
            }),
            catchError((error) => {
                // Apparently calling authenticatedAccountGql.watch() can also result in throwing an error.
                // We don't want errors in this observable, just the account or undefined.
                console.error(error);
                return of(undefined);
            }),
            map((result) => result?.authenticatedAccount),
            shareReplay(1),
        );

    public constructor(
        private readonly accountPlanWillUpgradeGql: ApolloAccountPlanWillUpgradeGQL,
        private readonly adminUpdateInternalProfileGql: ApolloAdminUpdateInternalProfileGQL,
        private readonly adminUpdateSchoolProfileGql: ApolloAdminUpdateSchoolProfileGQL,
        private readonly authenticatedAccountGql: ApolloAuthenticatedAccountGQL,
        private readonly authenticationService: AuthenticationService,
        private readonly internalAccountsGql: ApolloInternalAccountsGQL,
        private readonly inviteInternalAccountGql: ApolloInviteInternalAccountGQL,
        private readonly inviteSchoolAccountGql: ApolloInviteSchoolAccountGQL,
        private readonly inviteStudentAccountGql: ApolloInviteStudentAccountGQL,
        private readonly loginAsGql: ApolloLoginAsGQL,
        private readonly randomStudentPasswordsGql: ApolloRandomStudentPasswordsGQL,
        private readonly resendInviteGql: ApolloResendInviteGQL,
        private readonly setAccountPasswordGql: ApolloSetAccountPasswordGQL,
        private readonly studentAccountGql: ApolloStudentAccountGQL,
        private readonly studentLoginEmailGql: ApolloStudentLoginEmailGQL,
        private readonly updateAccountAccessGql: ApolloUpdateAccountAccessGQL,
        private readonly updateLegalDocumentTimestampsGql: ApolloUpdateLegalDocumentTimestampsGQL,
        private readonly updateSchoolUserDataGql: ApolloUpdateSchoolUserDataGQL,
        private readonly updateStudentDataGql: ApolloUpdateStudentDataGQL,
        private readonly userUpdateAccountProfileGql: ApolloUserUpdateAccountProfileGQL,
    ) {}

    public adminUpdateInternalProfile(
        id: Uuid,
        changes: AccountProfileAdminChanges,
    ): Promise<Account> {
        const result$ = this.adminUpdateInternalProfileGql.mutate({ id, changes });
        const account$ = result$.pipe(
            handleMutationResult(),
            map((data) => data.adminUpdateInternalProfile),
        );

        return firstValueFrom(account$);
    }

    public adminUpdateSchoolProfile(
        id: Uuid,
        changes: SchoolProfileAdminChanges,
    ): Promise<Account> {
        const result$ = this.adminUpdateSchoolProfileGql.mutate(
            { id, changes },
            { refetchQueries: 'active' },
        );
        const account$ = result$.pipe(
            handleMutationResult(),
            map((data) => data.adminUpdateSchoolProfile),
        );

        return firstValueFrom(account$);
    }

    public findStudent(
        schoolCustomerId: Uuid,
        studentId: string,
    ): Promise<MinimalStudentData | undefined> {
        const result$ = this.studentAccountGql.fetch({ studentId, schoolCustomerId });
        const studentAccount$ = result$.pipe(
            handleQueryResult(),
            map((result) => result.studentAccount),
        );

        return firstValueFrom(studentAccount$);
    }

    public findStudentLoginEmail(
        schoolCustomerId: Uuid,
        studentId: string,
    ): Promise<string | undefined> {
        const result$ = this.studentLoginEmailGql.fetch({ schoolCustomerId, studentId });
        const email$ = result$.pipe(
            handleQueryResult(),
            map((result) => result.studentLoginEmail),
        );

        return firstValueFrom(email$);
    }

    public generateStudentPasswords(count: number): Promise<string[]> {
        const result$ = this.randomStudentPasswordsGql.fetch(
            { count },
            { fetchPolicy: 'network-only' },
        );
        const passwords$ = result$.pipe(
            handleQueryResult(),
            map((data) => data.randomStudentPasswords),
        );

        return firstValueFrom(passwords$);
    }

    public inviteInternalUser(invitee: InternalInvitee): Promise<Account> {
        const refetchQueries = [{ query: this.authenticatedAccountGql.document }];
        const result$ = this.inviteInternalAccountGql.mutate({ invitee }, { refetchQueries });
        const account$ = result$.pipe(
            handleMutationResult(),
            map((data) => data.inviteInternalAccount),
        );

        return firstValueFrom(account$);
    }

    public inviteSchoolUser(invitee: SchoolCustomerInvitee): Promise<Account> {
        const result$ = this.inviteSchoolAccountGql.mutate(
            { invitee },
            { refetchQueries: 'active' },
        );
        const account$ = result$.pipe(
            handleMutationResult(),
            map((data) => data.inviteSchoolAccount),
        );

        return firstValueFrom(account$);
    }

    public inviteStudent(invitee: StudentInvitee): Promise<Account> {
        const result$ = this.inviteStudentAccountGql.mutate({ invitee });
        const account$ = result$.pipe(
            handleMutationResult(),
            map((data) => data.inviteStudentAccount),
        );

        return firstValueFrom(account$);
    }

    public loginAs(email: string): Observable<boolean> {
        return this.loginAsGql.fetch({ email }).pipe(map((result) => !!result.data.loginAs));
    }

    public resendInvite(id: Uuid): Promise<boolean> {
        const result$ = this.resendInviteGql.mutate({ id });
        const emailResent$ = result$.pipe(
            handleMutationResult(),
            map((data) => data.resendInvite),
        );

        return firstValueFrom(emailResent$);
    }

    public async refreshUser(): Promise<Account | undefined> {
        // This will get the most up-to-date account data from the server
        // and cache it so that the watch userAccount$ will also see the changes.
        const result$ = this.authenticatedAccountGql.fetch({}, { fetchPolicy: 'network-only' });
        const account$ = result$.pipe(
            handleQueryResult(),
            map((result) => result.authenticatedAccount),
        );

        return firstValueFrom(account$);
    }

    public schoolPlanWillUpgrade(email: Email): Promise<boolean> {
        const result$ = this.accountPlanWillUpgradeGql.fetch({ email });
        const willUpgrade$ = result$.pipe(
            handleQueryResult(),
            map((result) => result.accountByEmail?.planWouldChange ?? false),
        );

        return firstValueFrom(willUpgrade$);
    }

    public setPassword(accountId: Uuid, password: string): Promise<Account> {
        const result$ = this.setAccountPasswordGql.mutate({ accountId, password });
        const account$ = result$.pipe(
            handleMutationResult(),
            map((data) => data.setAccountPassword),
        );

        return firstValueFrom(account$);
    }

    public updateAccess(id: Uuid, canAccess: boolean): Promise<Uuid> {
        const result$ = this.updateAccountAccessGql.mutate(
            { id, canAccess },
            { refetchQueries: 'active' },
        );
        const id$ = result$.pipe(
            handleMutationResult(),
            map((data) => data.updateAccountAccess.id),
        );

        return firstValueFrom(id$);
    }

    public async updateLegalDocumentTimestamps(): Promise<Account> {
        const result$ = this.updateLegalDocumentTimestampsGql.mutate();
        const account$ = result$.pipe(
            handleMutationResult(),
            map((data) => data.updateLegalDocumentTimestamps),
        );

        return firstValueFrom(account$);
    }

    public async userUpdateProfile(
        profile: AccountProfileChanges,
        teachingInfo?: TeachingInfo,
    ): Promise<Account> {
        const account = await firstValueFrom(this.userAccount$);
        if (!account) throw new Error('Cannot update account.');

        const { id } = account;
        const result$ = this.userUpdateAccountProfileGql.mutate({ id, profile, teachingInfo });
        const account$ = result$.pipe(
            handleMutationResult(),
            map((data) => data.userUpdateAccountProfile),
        );

        return firstValueFrom(account$);
    }

    public updateSchoolUserData(schoolUserDataUpdate: SchoolUserDataUpdate): Promise<Account> {
        // Doing this transformation here keeps it out of component logic.
        const { registrationSurveyResults, ...remainder } = schoolUserDataUpdate;
        const update: RawSchoolUserDataUpdate = {
            ...remainder,
            registrationSurveyResults: registrationSurveyResults
                ? [...getRawSurveyResultUpdate(registrationSurveyResults)]
                : undefined,
        };

        const result$ = this.updateSchoolUserDataGql.mutate(
            { update },
            { refetchQueries: 'active' },
        );
        const account$ = result$.pipe(
            handleMutationResult(),
            map((data) => data.updateSchoolUserData),
        );

        return firstValueFrom(account$);
    }

    public updateStudent(input: ApolloUpdateStudentDataGqlInput): Promise<MinimalStudentData> {
        const results$ = this.updateStudentDataGql.mutate({ input });
        const account$ = results$.pipe(
            handleMutationResult(),
            map((data) => data.updateSchoolStudentData),
        );
        return firstValueFrom(account$);
    }
}

function* getRawSurveyResultUpdate(
    surveyResults: Iterable<SurveyResultUpdate>,
): Generator<RawSurveyResultUpdate> {
    for (const surveyResult of surveyResults) {
        if (!surveyResult) continue;
        if (isSurveyMultipleSelectResultUpdate(surveyResult)) {
            yield { multipleSelect: surveyResult };
            continue;
        }
        if (isSurveySingleSelectResultUpdate(surveyResult)) {
            yield { singleSelect: surveyResult };
            continue;
        }
        // If we reached this point,
        // then we've only partially or incorrectly implemented a new type of survey question.
        throw new Error('Unknown or mismatched survey result.');
    }
}
