// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from './environment.interface';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const environment: Environment = {
    serviceWorkerEnabled: true,
    api: {
        domain: 'https://api.dev.ebacademics.com',
    },
    aws: {
        poolData: {
            /* eslint-disable @typescript-eslint/naming-convention */
            UserPoolId: 'us-west-2_KQtBjaKsu',
            ClientId: '3ceu9do59sss9mcfus4ikh4urk',
            /* eslint-enable @typescript-eslint/naming-convention */
        },
    },
    featureAccess: {
        disabledFeatures: [],
    },
    schoolApplication: {
        domain: 'https://school.dev.ebacademics.com/',
    },
    studentApplication: {
        domain: 'https://student.dev.ebacademics.com/',
    },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
